import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { getAxios } from "../../../../service/apiservice";
import { intrestlistEndPoint, paymentModedropDownEndPoint } from "../../../../service/api";

export default function LoanDistributionDetails({
  totalamount,
  handleCreatePurchase,
  setSelectedPaymentMode,
  selectedPaymentMode,
  currentList,
  onValueUpdate,
  errors,
  setErrors,
  cash,
  setCash,
  bank,
  setBank,
  paymentValues,
  setPaymentValues,
  setPayment,
  payment,
}) {
  const [loanAmount, setLoanAmount] = useState(totalamount);
  const [documentCharges, setDocumentCharges] = useState(10); // Default document charges
  const [interestPercentage, setInterestPercentage] = useState(
   // currentList.interest_percentage
  );                                                    

  console.log("interestPercentage",interestPercentage)

  const [interestAddAmount, setInterestAddAmount] = useState("");
  const [distributionAmount, setDistributionAmount] = useState("");
  const [paymentlable, setPaymentLable] = useState();
  const [paymentModeDropdown, setPaymentModeDropdown] = useState([]);

  console.log("selectedPaymentMode",selectedPaymentMode)

  const [error, setError] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [maturityDate, setMaturityDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const nextYear = new Date(today.setFullYear(today.getFullYear() + 1));
    const formattedDate = nextYear.toISOString().split("T")[0];
    setMaturityDate(formattedDate);
  }, []);

  const handleChange = (event) => {
    setMaturityDate(event.target.value);
    onValueUpdate(event);
  };



  useEffect(() => {
    getPaymentModeDropDown();
    getInterstPercentageData();
  }, []);

  useEffect(() => {
    if (totalamount < loanAmount) {
      setError("value amount greater than loan amount");
    } else {
      setError("");
    }
  }, [loanAmount]);

  useEffect(() => {
    if (selectedPaymentMode == 3) {
      setCash(0);
      setBank(0);
    } else if (selectedPaymentMode == 1) {
      setCash(distributionAmount);
    } else if (selectedPaymentMode == 2) {
      setBank(distributionAmount);
    }
  }, [selectedPaymentMode, distributionAmount]);

  useEffect(() => {
    let data = [...paymentValues];
    Object.entries(payment).map((item) => {
      if (item[0].includes("payment")) {
        for (var i in data) {
          if (data[i].id.toString() == item[0].slice(7, item[0].length)) {
            data[i]["value"] = item[1];
          }
        }
      }
    });

    console.log("data", data);

    setPaymentValues(data);
  }, [payment]);

  useEffect(() => {
    calculateAndAutofill();
  }, [loanAmount, documentCharges, interestPercentage, interestAddAmount]);

  const calculateAndAutofill = () => {
    let loanAmountValue = parseFloat(loanAmount || 0);
    let documentChargesValue = parseFloat(documentCharges || 0);
    let interestPercentageValue = parseFloat(interestPercentage || 0);

    let calculatedDistributionAmount = loanAmountValue + documentChargesValue;
    setDistributionAmount(parseFloat(calculatedDistributionAmount).toFixed(2));


    let interestWithAmountValue =
      (loanAmountValue * interestPercentageValue) / 100;
    setInterestAddAmount(Math.round(interestWithAmountValue));

    let distributionvalue = loanAmountValue - interestWithAmountValue;

    if (loanAmountValue >= documentCharges) {
      distributionvalue -= documentChargesValue;
    }
    setDistributionAmount(Math.round(distributionvalue));
  };

  const getPaymentModeDropDown = async () => {
    var respose = await getAxios({
      url: paymentModedropDownEndPoint,
    });

    if (respose !== null) {
      setPaymentModeDropdown(respose.data.list);
      var tempList = [];

      var values = [];
      Object.keys(payment).map((item) => {
        if (item.includes("payment")) {
          values.push(item);
        }
      });

      if (values.length > 0) {
        Object.entries(payment).map((item) => {
          if (item[0].includes("payment")) {
            for (var i of respose.data.list) {
              if (i.id.toString() == item[0].slice(7, item[0].length)) {
                var dictData = i;
                dictData["error"] = "";
                dictData["value"] = item[1];
                tempList.push(dictData);
              }
            }
          }
        });
      } else {
        for (var i of respose.data.list) {
          var dictData = i;
          dictData["error"] = "";
          dictData["value"] = "";
          tempList.push(dictData);
        }
      }
      setPaymentValues(tempList);
    }
  };

  // const handleCashChange = (e) => {
  //   const value = parseFloat(e.target.value || 0);
  //   const remainingAmount = distributionAmount - value;
  //   setCash(e.target.value);
  //   setBank(remainingAmount);

  // };

  // const handleBankChange = (e) => {
  //   const value = parseFloat(e.target.value || 0);
  //   const remainingAmount = distributionAmount - value;
  //   setBank(e.target.value);
  //   setCash(remainingAmount);

  // };

  const handleCashChange = (e) => {
    const value = parseFloat(e.target.value || 0);
    const remainingAmount = distributionAmount - value;
    setCash(e.target.value);
    setBank(remainingAmount);

    let newErrors = { ...errors };
    if (value === 0) {
      newErrors.cash = "Cash amount cannot be zero";
    } else {
      delete newErrors.cash;
    }
    setErrors(newErrors);
  };

  const handleBankChange = (e) => {
    const value = parseFloat(e.target.value || 0);
    const remainingAmount = distributionAmount - value;
    setBank(e.target.value);
    setCash(remainingAmount);

    let newErrors = { ...errors };
    if (value === 0) {
      newErrors.bank = "Bank amount cannot be zero";
    } else {
      delete newErrors.bank;
    }
    setErrors(newErrors);
  };

  // const handleIntersetChange = (event) => {
  //   const value = event.target.value;

  //   const invalidChars = ["+", "-", "*", "%", "/"];
  //   if (!invalidChars.some((char) => value.includes(char))) {
  //   // Update state and call onValueUpdate only if the value is valid
  //   if (!isNaN(value) && value > 0) {
  //     setInterestPercentage(value);
  //     onValueUpdate(event);
  //   } else {
  //     setInterestPercentage("");
  //   }}
  // };

  useEffect(() => {
    let totalvalue = 0;
    console.log("paymentValues", paymentValues);
    for (var i of paymentValues) {
      const value = parseFloat(i.value);
      if (!isNaN(value)) {
        totalvalue += value;
      }
    }
    setPaymentLable(totalvalue);
  }, [paymentValues]);

  const onPaymentValueUpdate = (e, index) => {
    const { name, value } = e.target;

    let data = [...paymentValues];
    data[index]["value"] = value;
    data[index]["error"] =
      value.length > 0 ? "" : data[index].mode_name + " field is required";
    setPaymentValues(data);
    setPayment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("&&&&&&&&&&&&&&", paymentValues);
    if (Number(paymentlable) !== Number(distributionAmount)) {
      toast.error("Payment and distribution amount must be equal");
      return;
    }
    // setPaymentError(""); // Clear any previous error
    handleCreatePurchase(e);
  };



  const getInterstPercentageData = async () => {
    var response = await getAxios({
      url: intrestlistEndPoint,
    });

    if (response !== null) {
      setInterestPercentage(response.data.interest_percent)
    }
  };




  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between items-center max-[480px]:flex-col">
          <p className="mb-0">
            Loan Amount <span className="text-red-500">*</span>
          </p>
          <div className="">
            <input
              type="text"
              placeholder="Loan Amount"
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-[280px] p-[5px] text-[17px] outline-none"
              name="loan_amt"
              value={loanAmount}
              onChange={(event) => {
                setLoanAmount(event.target.value);
                onValueUpdate(event);
              }}
            />
            <p className="text-red-500 font-[400] text-[13px]">{error}</p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-3 max-[480px]:flex-col">
          <p className="mb-0">Document Charges</p>
          <div className="">
            <input
              type="text"
              placeholder="Document Charges"
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-[280px] p-[5px] text-[17px] outline-none"
              name="document_charge"
              value={documentCharges}
              onChange={(e) => setDocumentCharges(e.target.value)}
            />
          </div>
        </div>

        <div className="flex justify-between items-center mt-3 max-[480px]:flex-col">
          <p className="mb-0">
            Interest Amount <span className="text-red-500">*</span>
          </p>

          <div className="flex gap-2">
            <div className="">
              <input
                type="text"
                placeholder="Interest Percentage"
                // step={0.001}
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[134px] p-[5px] text-[17px] outline-none"
                name="interest_percentage"
                value={interestPercentage}
                // onChange={handleIntersetChange}
              />
              <p className="text-red-500 font-[400] text-[13px]">
                {errors.interest_percentage}
              </p>
            </div>

            <div className="">
              <input
                type="text"
                placeholder="Interest Add Amount"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[139px] p-[5px] text-[17px] outline-none bg-[#dcdcdc]"
                name="interest_amount"
                value={Math.round(interestAddAmount)}
                onChange={(e) => setInterestAddAmount(Math.round(e.target.value))}
                readOnly
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-3 max-[480px]:flex-col">
          <p className="mb-0 text-green-600">Distribution Amount</p>
          <div className="">
            <input
              type="text"
              placeholder="Distribution Amount"
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-[280px] p-[5px] text-[17px] outline-none bg-[#61a37542]"
              name="distribute_amt"
              value={Math.round(distributionAmount)}
              onChange={(e) => setDistributionAmount(e.target.value)}
              readOnly
            />
          </div>
        </div>

        <div className="flex justify-between items-center mt-3 max-[480px]:flex-col">
          <p className="mb-0">Mature Date</p>
          <div className="">
            <input
              type="date"
              placeholder="Mature Date"
              readOnly
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-[280px] p-[5px] text-[17px] outline-none "
              name="maturity_date"
              value={maturityDate}
              onChange={handleChange}
            />
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.maturity_date}
            </p>
          </div>
        </div>

        <div className="flex justify-between items-center mt-3 max-[480px]:flex-col">
          <p className="mb-[5px] text-[15px]">
            Payment mode <span className="text-red-500">*</span>
          </p>
          <div className="w-[280px] my-[5px]">
          <select
              placeholder="metal name"
              className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
              name="payment_mode"
              value={selectedPaymentMode}
              onChange={(event) => {
                const newPaymentMode = event.target.value;
               
                // Reset the payment fields if a new payment mode is selected
                const updatedPayment = { ...payment };
                paymentValues.forEach((item) => {
                  if (newPaymentMode != item.id) {
                    updatedPayment["payment" + item.id] = ""; // Reset irrelevant fields
                  }
                });
 
                setPayment(updatedPayment);
                setSelectedPaymentMode(newPaymentMode);
                onValueUpdate(event); // You can keep this to handle other updates
              }}
            >
              <option value={""}>--Select Payment Mode--</option>
              {paymentModeDropdown.map((item, index) => (
                <option key={item.mode_name} value={item.id}>
                  {item.mode_name}
                </option>
              ))}
            </select>

            <p className="text-red-500 font-[400] text-[13px]">
              {errors.payment_mode}
            </p>
          </div>
        </div>

        <div>
          <div>
            {paymentValues.map(
              (item, index) =>
                (selectedPaymentMode == item.id ||
                  selectedPaymentMode.toString() == "1") &&
                item.id.toString() != "1" && (
                  <div
                    key={index}
                    className={`flex justify-between items-center mt-3`}
                  >
                    <p className="mb-0">{item.mode_name}</p>

                    <div>
                      <input
                        type="text"
                        // placeholder={item.mode_name}
                        className="sm:mb-[5px] border border-gray-300 rounded-lg w-[280px] p-[5px] text-[17px] outline-none"
                        name={"payment" + item.id}
                        value={payment["payment" + item.id]}
                        onChange={(event) => onPaymentValueUpdate(event, index)}
                      />

                      {item.error.length > 0 && (
                        <p className="text-red-500 font-[400] text-[13px]">
                          {item.error}
                        </p>
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        {/* <div className="flex justify-center">
          
        {paymentError && <p className="text-red-500 font-[400] text-[13px]">{paymentError}</p>}
        </div> */}

        <div className="mt-[50px] flex justify-end">
          <button
            type="submit"
            id="formsubmit"
            class="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto"
          >
            create Loan
          </button>
        </div>
      </form>
    </div>
  );
}
