import { useEffect, useRef, useState } from "react";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  customerReportsListEndPoint,
  deliveryEndPoint,
  deliveryLoanListEndPoint,
  loanTableListEndPoint,
} from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import CustomerReportsFilter from "./customerreportsfillter";
import { convertToTime } from "../../../utilities/time";

export default function CustomerReports() {
  const navigate = useNavigate();

  const searchRef = useRef();

  const [customerReportEntryList, setCutomerReportEntryList] = useState([]);
  const [currentPurchase, setCurrentPurchase] = useState({});
  const [customerLoan, setCustomerLoan] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [tableFilters, setTableFilters] = useState({
    search: "",
    from_date: null,
    to_date: null,
    branch: localStorage.getItem("branch") == "true" ? null : undefined,
    items_per_page: JSON.parse(localStorage.getItem("pagination")) ? 10 : null,
    page: 1,
    filterstatus:null
  });

  const { actions, pagination } = useSelector((state) => state.appConfig);


  

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === "f") {
      event.preventDefault();
      if (
        document.getElementById("filteroptions").className === "my-[25px] block"
      ) {
        document.getElementById("filteroptions").className = "my-[25px] ";
      } else {
        document.getElementById("filteroptions").className = "my-[25px] block";
        searchRef.current?.focus();
      }
    } else if (event.ctrlKey && event.key === "m") {
      event.preventDefault();
      localStorage.setItem("user_purchaseentry_mode", "create");
      navigate("loan-form");
    }
  };

  useKeyPress(["f", "m"], onKeyPress);

  useEffect(() => {
    getcustomerReportListData(tableFilters);
  }, [tableFilters]);

  const getcustomerReportListData = async (body) => {
    var response = await postAxiosWithToken({
      url: customerReportsListEndPoint,
      body: body,
    });

    if (response !== null) {
      setCustomerLoan(response)
      setCutomerReportEntryList(response.data.list);
      setIsLoading(false);
      setTotalPages(response.data.total_pages);
    }
  };

  const pageHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };



  return (
    <>
      <div className="mt-[60px]">

      <div className="flex gap-5 items-center justify-center max-[480px]:flex-col ">
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Total Loan</p>
            <p>{customerLoan.total_loan}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Total Open Loan</p>
            <p>{customerLoan.total_open}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Total Close Loan</p>
            <p>{customerLoan.total_close}</p>
          </div>
        </div>



        <div id="filteroptions" className="my-[5px] ">
          <p className="font-[600] text-[18px]">Filters</p>
          <CustomerReportsFilter
            filters={tableFilters}
            searchRef={searchRef}
            setFilters={setTableFilters}
          />
        </div>
        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
          <table className="w-full">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[200px]">Customer Name</td>

                <td className="p-[10px] min-w-[150px]">Mobile</td>
                <td className="p-[10px] min-w-[150px]">Address</td>
                <td className="p-[10px] min-w-[150px]">Village</td>
                <td className="p-[10px] min-w-[150px]">City</td>
                <td className="p-[10px] min-w-[150px]">Branch Name</td>
                <td className="p-[10px] min-w-[150px] text-center">Active Loan</td>
                <td className="p-[10px] min-w-[150px] text-center">Closed Loan</td>
                <td className="p-[10px] min-w-[150px] text-center">Total Loan</td>
                <td className="p-[10px] min-w-[150px]">Create Date</td>

                <td className="p-[10px] min-w-[150px] sticky right-0 bg-table-head">
                  Actions
                </td>
              </tr>
            </thead>
            <tbody className="text-primary-grey bg-[#fff]">
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={11}>
                    Loading...
                  </td>
                </tr>
              ) : customerReportEntryList.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                customerReportEntryList?.map((item, index) => (
                  <tr key={item.id} className="border">
                    <td className="p-[10px]">{index + 1}</td>
                    <td className="p-[10px] capitalize">{item.customer_name}</td>

                    <td className="p-[10px]">{item.mobile}</td>
                    <td className="p-[10px] capitalize">{item.address}</td>
                    <td className="p-[10px] capitalize">{item.village_name}</td>
                    <td className="p-[10px capitalize">{item.town_name}</td>
                    <td className="p-[10px capitalize">{item.branch_name}</td>
                    <td className="p-[10px capitalize text-center">{item.active_loan}</td>
                    <td className="p-[10px capitalize text-center">{item.closed_loan}</td>
                    <td className="p-[10px capitalize text-center">{item.total_loan}</td>
                    <td className="p-[10px] capitalize">
                      <p>{convertToDate(item.created_at)}</p>
                      <p className="text-[#BCBDBD]">
                        {convertToTime(item.created_at)}
                      </p>
                    </td>
                    <td className="p-[10px] sticky right-0 bg-white">
                      <div className="flex gap-3">
                        <div
                          onClick={() => {
                            if(item.total_loan ==0){
                              toast.error("No Record Data");
                            } else {

                            if (actions.viewPermit) {
                              setCurrentPurchase(item);
                              localStorage.setItem(
                                "cutomer_report_id",
                                item.id.toString()
                              );
                              navigate("view-customer-reports");
                            } else {
                              toast.error("You don't have permission for view");
                            }
                          }
                          }}
                          className="cursor-pointer hover:scale-125"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                          >
                            <circle cx="16" cy="16" r="4" fill="#61a375" />
                            <path
                              fill="#61a375"
                              d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {pagination === true ? (
          <div className="pb-5">
            <Pagination
              totalPages={totalPages}
              currentPage={tableFilters.page}
              setCurrentPage={pageHandler}
              entries={tableFilters.items_per_page}
              entriesHandler={entriesHandler}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
