import { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { convertToDate } from '../../../utilities/datetime';
import { addDays } from 'date-fns/esm';

export default function MetalRatesFilters({ filters, setFilters, }) {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

   const handleSearch = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            search: value
        }))
    }

    const handleDateFromFilter = (value) => {
            setFilters((prevState) => ({
                ...prevState,
                from_date: value === null ? null : convertToDate(value)
            }))
    }

    const handleDateToFilter = (value) => {
            setFilters((prevState) => ({
                ...prevState,
                to_date: value === null ? null : convertToDate(value),
            }))
    }

    const handleDateClearFilter = () => {
        setFilters((prevState) => ({
            ...prevState,
            from_date: null,
            to_date: null
        }))
    }

    const handleStatusFilter = (value) => {
        setFilters((prevState) => ({
            ...prevState,
            active_status: value === "all" ? null : JSON.parse(value)
        }))
    }

    return (
        <>
            <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] justify-end items-center w-full">
                
                <div className='min-[400px]:flex gap-5 w-full lg:w-[400px] 2xl:w-[25%] min-[400px]:justify-between border items-center py-[10px] px-[10px] rounded-xl'>
                    <div className=''>
                        <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateFromFilter} value={filters.from_date} />
                    </div>
                    <p className='text-center'>to</p>
                    <div>
                        <DatePicker className='w-full' dayPlaceholder={"dd"} monthPlaceholder={'mm'} yearPlaceholder={'yyyy'} format='dd/MM/yyyy' onChange={handleDateToFilter} value={filters.to_date} />
                    </div>
                    <div onClick={() => { setFromDate(null); setToDate(null); handleDateClearFilter() }} className='cursor-pointer'>
                        <svg className='min-[400px]:block hidden' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z" /></svg>
                        <p className='min-[400px]:hidden text-center block'>clear</p>
                    </div>
                </div>
            </div>
        </>
    );
}