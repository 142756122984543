import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Layout from "../layout";
import Login from "../auth/login";
import { useEffect, useState } from "react";
import { AuthenticatedComponents, AddedAuthenticatedRoutes } from "../routers/authenticatedroutes";
import { getAxios } from "../service/apiservice";
import { activeUserPermissionEndPoint } from "../service/api";
import { Errorpage404 } from "../session/404page";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "../redux/themeconfigslice";
import { Errorpage500 } from "../session/500page";
import { Maintanance } from "../session/maintanance";
import { setUnderMaintanance } from "../redux/appconfigslice";
import LoadingPage from "../session/loadingPage";

export default function Routers() {

    const navigate = useNavigate();

    const [AuthenticatedRoutes, setAuthenticatedRoutes] = useState([]);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const { isLoading } = useSelector((state) => state.themeConfig);
    const { underMaintanance } = useSelector((state) => state.appConfig);

    const dispatch = useDispatch();

    useEffect(() => {
        setIsAuthenticated(JSON.parse(localStorage.getItem('authenticated')))
        if (JSON.parse(localStorage.getItem('authenticated'))) {
            getMenuList()
        }

    }, [])

    const getMenuList = async () => {
        var response = await getAxios({
            url: activeUserPermissionEndPoint
        })
        
        if (response !== null) {
            if (response === 'ERR_NETWORK') {
                dispatch(setIsLoading(!isLoading))
                navigate('/500')
            } else {
                if (response.maintanance) {
                    dispatch(setUnderMaintanance(response.maintanance))
                } else {
                    dispatch(setUnderMaintanance(false))
                    var tempList = []
                    for (var item of response.data) {
                        for (var subItem of item.menu_list) {
                            for (var menuItem of AuthenticatedComponents) {
                                var lastPath = subItem.menu_path.split('/')[subItem.menu_path.split('/').length - 1]
                                if (lastPath.toString().toLowerCase() === menuItem.path.toString().toLowerCase()) {
                                    var dictData = {
                                        path: subItem.menu_path,
                                        component: menuItem.component
                                    }
                                    tempList.push(dictData)

                                }
                            }
                        }
                    }
                    var mergedTempList = tempList.concat(AddedAuthenticatedRoutes)
                    setAuthenticatedRoutes(mergedTempList)
                }
                
                dispatch(setIsLoading(false))
               
            }
        }
        
    }

    if (underMaintanance) {
        return <Maintanance />
    }

    return (

        <>
            <Routes>
                <Route path="/" element={JSON.parse(localStorage.getItem('authenticated')) ? isLoading ? <LoadingPage /> : <Layout id="main" setAuthenticatedRoutes={setAuthenticatedRoutes} /> : <Login />}>
                    {
                        AuthenticatedRoutes.map((item) => (
                            <Route key={item.path} path={item.path} element={item.component} />
                        ))
                    }
                </Route>
                <Route path="/500" element={!JSON.parse(localStorage.getItem('authenticated')) ? <Navigate to={'/'} /> : isLoading ? <LoadingPage /> : <Errorpage500 />} />
                <Route path="*" element={!JSON.parse(localStorage.getItem('authenticated')) ? <Navigate to={'/'} /> : isLoading ? <LoadingPage /> : <Errorpage404 />} />
            </Routes>
        </>

    );
} 