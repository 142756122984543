import { branchListEndPoint, cityDropdownListEndPoint, customerEndPoint, customerGroupListEndPoint, genterDropdownListEndPoint, newFirmEndPoint, relationshipDropdownListEndPoint, stateDropdownListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { isOptionalValidForm } from "../../../utilities/formvalidation";
import { Country, State } from "country-state-city";
import { defaultCountry, defaultState } from "../../../constant";


export default function NewFirmForm() {

    const optionalFields = ["lic_no","gst_no"]


    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentCustomer, setCurrentCustomer] = useState({});
    const [relationDropdown, setRelationDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);
    const [errors, setErrors] = useState({});
    const [branchList, setBranchList] = useState([]);
    const [genterList, setGenterList] = useState([]);

  



    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getStateListData()
        getRelationListData()
        getBranchListData()
        getgenterListData()
        setFormMode(localStorage.getItem('customer_form_mode'))

       
        if (localStorage.getItem('customer_form_mode') === 'update') {
            getCustomerDetails()
        }
    }, [])

  
    const getStateListData = async () => {
        var response = await getAxios({
            url: stateDropdownListEndPoint + "101" + "/"
        })

        if (response !== null) {
            setStateDropdown(response.data.list)
        }
    }
    const getCityListData = async (id) => {
        var response = await getAxios({
            url: cityDropdownListEndPoint + id + "/"
        })

        if (response !== null) {
            setCityDropdown(response.data.list)
        }
    }
    const getRelationListData = async (id) => {
        var response = await getAxios({
            url: relationshipDropdownListEndPoint 
        })

        if (response !== null) {
            setRelationDropdown(response.data.list)
        }
    }
    const getBranchListData = async (id) => {
        var response = await getAxios({
            url: branchListEndPoint 
        })

        if (response !== null) {
            setBranchList(response.data.list)
        }
    }
    const getgenterListData = async (id) => {
        var response = await getAxios({
            url: genterDropdownListEndPoint 
        })

        if (response !== null) {
            setGenterList(response.data.list)
        }
    }

  

    const getCustomerDetails = async () => {
        var response = await getAxios({
            url: newFirmEndPoint + localStorage.getItem('customer_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                firm_name: response.data?.firm_name,
                lic_no: response.data?.lic_no,
                gst_no: response.data?.gst_no,
                address: response.data?.address,
                city: response.data?.city,
                state: response.data?.state,
                pincode: response.data?.pincode,
                mobile: response.data?.mobile,
                id_branch: response.data?.id_branch,
            }

            getCityListData(response.data?.state)
           
            setCurrentCustomer(tempDetails)
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        let formFields = new FormData(e.target);
        let formDataObject = Object.fromEntries(formFields.entries());

        var err = {}

        for (var field of Object.entries(formDataObject)) {
            if (field[1].length === 0 && !optionalFields.includes(field[0])) {
                err[field[0]] = `${field[0]} field is required`
            } else {
                err[field[0]] = ""
            }
        }

        setErrors((prevState) => ({
            ...prevState,
            ...err,
          }));

        if (isOptionalValidForm(formDataObject, optionalFields)) {
            if (localStorage.getItem('customer_form_mode') === 'create') {
                createCustomer(currentCustomer)
            } else {
                updateCustomer(currentCustomer)
            }
        }
    }

    const createCustomer = async (body) => {
        var response = await postAxiosWithToken({
            url: newFirmEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updateCustomer = async (body) => {
        var response = await putAxiosWithToken({
            url: newFirmEndPoint + localStorage.getItem('customer_form_id') + "/",
            body: body
        })

        if (response != null) {
            localStorage.removeItem('customer_form_id')
            localStorage.setItem('customer_form_mode', 'create')
            toast.success(response.message)
            navigate(-1)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

     if (name === 'state') {
        getCityListData(value)
        }
        
        
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value
            }))


    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} New Firm</p>
                <form onSubmit={onSubmit}>

                    <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        {/* <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Customer Details</p> */}
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Firm Name<span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Firm Name"
                                    className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="firm_name"
                                    value={currentCustomer.firm_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.firm_name}</p>
                            </div>
                            
                            
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Lic No</p>
                                <input
                                    type="text"
                                    placeholder="Lic No"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="lic_no"
                                    value={currentCustomer.lic_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Gst No</p>
                                <input
                                    type="text"
                                    placeholder="Gst No"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="gst_no"
                                    value={currentCustomer.gst_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Mobile <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Mobile"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobile"
                                    value={currentCustomer.mobile}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.mobile}</p>
                            </div>


                            <div className="my-[5px]">
                                    <>
                                        <p className="mb-[5px] text-[15px]">Branch <span className="text-red-500">*</span></p>
                                        <div className=''>
                                            <select
                                                placeholder="Branch"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                                name="id_branch"
                                                value={currentCustomer.id_branch}
                                                onChange={onValueUpdate}
                                            >
                                                <option value={""}>-- Select Branch --</option>
                                                {branchList.map((option) => (
                                                    <option key={option?.id} value={option.id}>
                                                        {option.branch_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>

                                    <p className="text-red-500 font-[400] text-[13px]">{errors.id_branch}</p>
                                </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Address <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Address"
                                    className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    value={currentCustomer.address}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.address}</p>
                            </div>
                           
                           
                            
                           
                           
                            
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">State <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    value={currentCustomer.state}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.state_name} value={option.id}>{option.state_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.state}</p>
                            </div>


                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">City/Mandal <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="City/Mandal"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="city"
                                    value={currentCustomer.city}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"city" + option.city_name} value={option.id}>{option.city_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.city}</p>
                            </div>



                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentCustomer.pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.pincode}</p>
                            </div>
                        </div>
                    </div>


                    

                    <div className="flex min-[423px]:flex-row min-[423px]:gap-3 flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}