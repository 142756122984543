import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const ExportToPDF = ({ apiData, fileName }) => {
  const exportToPDF = (apiData, fileName) => {
    const doc = new jsPDF({   orientation: 'landscape', // 'portrait' or 'landscape'
    unit: 'pt',               // units to use: 'pt', 'mm', 'cm', 'in'
    format: 'a4',  });

    const tableColumn = Object.keys(apiData[0]);
    const tableRows = apiData.map(item => Object.values(item));

    doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        styles: { fillColor: [255, 0, 0] },  // Example: set fill color for cells
        margin: { top: 20, bottom: 20, left: 10, right: 10 },  // Define margins
        headStyles: { fillColor: [100, 100, 255] }, // Custom styles for table header
        bodyStyles: { fillColor: [240, 240, 240] }, // Custom styles for table body
        alternateRowStyles: { fillColor: [220, 220, 220] }, // Styles for alternate rows
      });

    doc.save(`${fileName}.pdf`);
  };

  return (
    <button onClick={() => exportToPDF(apiData, fileName)}
    className='flex items-center bg-primary-btn p-2 text-white rounded-md gap-4'>
      <p>Export to PDF</p>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.55" d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5l5-5m-5-7v12"/></svg>
    </button>
  );
};