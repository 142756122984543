import { useEffect, useRef, useState } from "react";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { convertToDate } from "../../../utilities/datetime";
import {
  branchListEndPoint,
  newFirmListEndPoint,
} from "../../../service/api";
import { getAxios } from "../../../service/apiservice";

export default function LoassItemReportsFilter({
  filters,
  setFilters,
  searchRef,
  loanNoSearch,
  setLoanNoSearch,
  getLossItemReportListData,
  tableFilters
}) {
  const [branchList, setBranchList] = useState([]);
  const [firmList, setFirmList] = useState([]);

  useEffect(() => {
    getBranchDropdownData();
    getFirmDropdownData();
  }, []);

  const getBranchDropdownData = async () => {
    try {
      const response = await getAxios({
        url: branchListEndPoint,
      });
      if (response !== null) {
        setBranchList(response.data.list);
      }
    } catch (error) {
      console.error("Error fetching branch data", error);
    }
  };

  const getFirmDropdownData = async () => {
    try {
      const response = await getAxios({
        url: newFirmListEndPoint,
      });
      if (response !== null) {
        setFirmList(response.data.list);
      }
    } catch (error) {
      console.error("Error fetching firm data", error);
    }
  };

  const handleSearch = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      search: value,
    }));
  };

  const handleDateFromFilter = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      from_date: value === null ? null : convertToDate(value),
    }));
  };

  const handleDateToFilter = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      to_date: value === null ? null : convertToDate(value),
    }));
  };

  const handleDateClearFilter = () => {
    setFilters((prevState) => ({
      ...prevState,
      from_date: null,
      to_date: null,
    }));
  };

  const handleBranchFilter = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      branch: value === "all" ? null : value,
    }));
  };

  const handleFirmFilter = (value) => {
    setFilters((prevState) => ({
      ...prevState,
      firm: value === "all" ? null : value,
    }));
  };

  const handleInputChange = (event) => {
    setLoanNoSearch(event.target.value)
  };

  const handleSearchClick = () => {
    getLossItemReportListData({...tableFilters,from_loan_no : loanNoSearch});
  };

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-3 2xl:gap-[2%] items-center w-full">
        <div className="grid xl-grid-cols-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
          <div className="flex gap-3 border rounded-xl px-[10px]">
            <div className="flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              autoComplete="off"
              id="searchfilter"
              ref={searchRef}
              className="py-[10px] capitalize outline-none w-[230px]"
              placeholder="Search"
              value={filters.search || ''}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div className="">
            <select
              placeholder="firm"
              className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full  p-[11px] text-[17px] outline-none"
              name="firm"
              value={filters.firm || 'all'}
              onChange={(e) => handleFirmFilter(e.target.value)}
            >
              <option value="all">--Select Firm--</option>
              {firmList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.firm_name}
                </option>
              ))}
            </select>
          </div>

          {localStorage.getItem("branch") === "true" && (
            <div className="">
              <select
                placeholder="Branch"
                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                name="branch"
                value={filters.branch || 'all'}
                onChange={(e) => handleBranchFilter(e.target.value)}
              >
                <option value="all">--Select Branch--</option>
                {branchList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.branch_name}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="min-[400px]:flex gap-5 w-full 2xl:w-[50%] min-[400px]:justify-between border items-center py-[10px] px-[10px] rounded-xl">
            <div className="">
              <DatePicker
                className="w-full"
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                format="dd/MM/yyyy"
                onChange={handleDateFromFilter}
                value={filters.from_date}
              />
            </div>
            <p className="text-center">to</p>
            <div>
              <DatePicker
                className="w-full"
                dayPlaceholder={"dd"}
                monthPlaceholder={"mm"}
                yearPlaceholder={"yyyy"}
                format="dd/MM/yyyy"
                onChange={handleDateToFilter}
                value={filters.to_date}
              />
            </div>
            <div
              onClick={handleDateClearFilter}
              className="cursor-pointer"
            >
              <svg
                className="min-[400px]:block hidden"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 256 256"
              >
                <path
                  fill="currentColor"
                  d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z"
                />
              </svg>
              <p className="min-[400px]:hidden text-center block">clear</p>
            </div>
          </div>

          <div className="flex gap-3 border rounded-xl px-[10px]">
            <input
              type="text"
              value={loanNoSearch}
              onChange={handleInputChange}
              placeholder="Enter loan number"
            />
              <button
                onClick={(e) => handleSearchClick(e)}
                className="py-[10px] px-[15px] bg-blue-500 text-white rounded"
              >
                Submit
              </button>
          </div>

        </div>
      </div>
    </>
  );
}
