import { useEffect, useRef, useState } from "react";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  customerReportsListEndPoint,
  deliveryEndPoint,
  deliveryLoanListEndPoint,
  loanTableListEndPoint,
  principleListEndPoint,
  transactionListEndPoint,
} from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { object } from "yup";
import { ExportToPDF } from "../../../components/ExportToPdf";
import ReactToPrint from "react-to-print";
import PrincipleReportsFilter from "./principlereportsfilter";
import PrincipleReportsPrint from "./principlereportsprint";
import { convertToTime } from "../../../utilities/time";

export default function PrincipleReports() {
  const navigate = useNavigate();
  let componentRef = useRef();

  const searchRef = useRef();
  const fileName = "Principle Report";
  const [principleReportEntryList, setPrincipleReportEntryList] = useState([]);
  const [currentPurchase, setCurrentPurchase] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [excelViewData, setExcelViewData] = useState([]);

  const [principleReportExcel, setPrincipleReportExcel] = useState([]);
  const [principleTotale, setPrincipleTotale] = useState({});


  const [excelOpen, setExcelOpen] = useState(false);

  function closeExcelModal() {
    setExcelOpen(false);
  }

  const [tableFilters, setTableFilters] = useState({
    search: "",
    from_date: null,
    to_date: null,
    filterstatus: null,
    firm: null,
    branch: localStorage.getItem("branch") == "true" ? null : undefined,
    items_per_page: JSON.parse(localStorage.getItem("pagination")) ? 10 : null,
    page: 1,
  });

  const { actions, pagination } = useSelector((state) => state.appConfig);

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === "f") {
      event.preventDefault();
      if (
        document.getElementById("filteroptions").className === "my-[25px] block"
      ) {
        document.getElementById("filteroptions").className = "my-[25px] hidden";
      } else {
        document.getElementById("filteroptions").className = "my-[25px] block";
        searchRef.current?.focus();
      }
    } else if (event.ctrlKey && event.key === "m") {
      event.preventDefault();
      localStorage.setItem("user_purchaseentry_mode", "create");
      navigate("loan-form");
    }
  };

  useKeyPress(["f", "m"], onKeyPress);

  useEffect(() => {
    if (principleReportEntryList.length > 0) {
      let tempData = [];
      for (var i of principleReportEntryList) {
        var ExcelValue = {
          LoanDate: i.loan_date,
          Loanno: i.loanno,
          FirmName: i.firm_name,
          CustomerName: i.customer_name,
          BranchName: i.branch_name,
          LoanAmount: i.loan_amt,
          DistributeAmount: i.distribute_amt,
          MonthlyInterestAmount: i.monthly_interest_amount,
          InterestPercentage: i.interest_percentage,
          InterestOccured: i.interest_occured,
          TotalPayamount: i.total_pay_amount,
          Discount: i.discount,
          DeliveryInterestAmount: i.delivery_interest_amount,
          DeliveryAmount: i.delivery_amount,
          StatusName: i.statusname,
          CreatedAt: i.created_at,          
        };

        tempData.push(ExcelValue);
      }
      setPrincipleReportExcel(tempData);
    }
  }, [principleReportEntryList]);

  useEffect(() => {
    if (principleReportExcel.length > 0) {
      let firstData = Object.keys(principleReportExcel[0]);
      let row = [];
      for (var i of firstData) {
        row.push({
          value: i,
        });
      }

      setExcelViewData(row);
    }
  }, [principleReportExcel]);

  useEffect(() => {
    getPrincipleReportListData(tableFilters);
  }, [tableFilters]);

  const getPrincipleReportListData = async (body) => {
    var response = await postAxiosWithToken({
      url: principleListEndPoint,
      body: body,
    });

    if (response !== null) {
      setPrincipleTotale(response);
      setPrincipleReportEntryList(response.data.list);
      setIsLoading(false);
      setTotalPages(response.data.total_pages);
    }
  };

  const pageHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  return (
    <>
      <div className="mt-[50px]">

        



        <div className="grid xl-grid-cols-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 max-[638px]:grid-cols-2 gap-3 mx-auto">
          <div className="mx-auto">
          <ExcelViewer
            title={fileName}
            data={principleReportExcel}
            headers={excelViewData}
            enabled={excelOpen}
            setEnabled={setExcelOpen}
            closeModal={closeExcelModal}
          />
          </div>

          <div className="mx-auto">
          <ExportToExcel apiData={principleReportExcel} fileName={fileName} />
          </div>

          <div className="mx-auto">
          <ExportToPDF apiData={principleReportExcel} fileName={fileName} />
          </div>


          
          <div className="mx-auto">
            <ReactToPrint
              trigger={() => (
                <button className="flex items-center bg-primary-btn p-2 text-white rounded-md gap-4">
                  Print
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M16 8.616v-3H8v3H7v-4h10v4zm-11.423 1h14.846zm13.038 2.5q.425 0 .713-.288t.287-.712t-.287-.713t-.713-.288t-.712.288t-.288.713t.288.712t.713.288M16 19v-4.538H8V19zm1 1H7v-4H3.577v-5.384q0-.85.577-1.425t1.423-.576h12.846q.85 0 1.425.576t.575 1.424V16H17zm2.423-5v-4.384q0-.425-.287-.713t-.713-.288H5.577q-.425 0-.712.288t-.288.713V15H7v-1.538h10V15z"
                    />
                  </svg>
                </button>
              )}
              content={() => componentRef}
            />
          </div>


            <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
              <p>Total Loan</p>
              <p>{principleTotale.total_loan}</p>
            </div>
            <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
              <p>Open</p>
              <p>{principleTotale.total_open}</p>
            </div>
            <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
              <p>Close</p>
              <p>{principleTotale.total_close}</p>
            </div>
        </div>

        <div className="hidden">
          <div ref={(el) => (componentRef = el)}>
            <PrincipleReportsPrint data={principleReportExcel} />
          </div>
        </div>

        



        {/* <div className="flex justify-between mt-3">
          <div className="mt-1 flex justify-center overflow-auto">
            <table className="min-w-[200px] table-auto">
              <thead className="border border-black text-[15px] uppercase font-[700]">
                <tr>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Total Loan</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Open</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">closed</td>
                </tr>
              </thead>
              <tbody >
                
                  <tr >
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.total_loan}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.total_open}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.total_close}</td>
                  </tr>
                
              </tbody>
            </table>
          </div>
          <div className="mt-1 flex justify-center overflow-auto">
            <table className="min-w-[200px] table-auto" >
              <thead className="border border-black text-[15px] uppercase font-[700]">
                <tr>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Loan Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Interest Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Release Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Interest Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Balance Loan Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Interest Amount</td>
                </tr>
              </thead>
              <tbody >
                
                  <tr >
                  <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.loan_amount}</td>
                  <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.loan_interest}</td>
                  <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.delivery_amount}</td>
                  <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.delivery_interest}</td>
                  <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.balance_amount}</td>
                  <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleTotale.balance_interest_amount}</td>
                
                  </tr>
                
              </tbody>
            </table>
            
          </div>
        </div> */}

        <div id="filteroptions" className="my-[5px] mt-3">
          <p className="font-[600] text-[18px]">Filters</p>
          <PrincipleReportsFilter
            filters={tableFilters}
            searchRef={searchRef}
            setFilters={setTableFilters}
          />
        </div>




        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
          <table className="w-full">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[150px]">Loan Date</td>
                <td className="p-[10px] min-w-[150px] ">Loan No</td>

                <td className="p-[10px] min-w-[150px]">Firm Name</td>
                <td className="p-[10px] min-w-[180px]">Customer Name</td>
                <td className="p-[10px] min-w-[180px]">Branch</td>
                <td className="p-[10px] min-w-[150px]">Loan Amt</td>
                <td className="p-[10px] min-w-[150px]">Distribute Amt</td>
                <td className="p-[10px] min-w-[100px]">Interst %</td>
                <td className="p-[10px] min-w-[100px]">Monthly Interst </td>
                <td className="p-[10px] min-w-[100px]">Document Charge</td>
                <td className="p-[10px] min-w-[180px]">Till Interest</td>
                <td className="p-[10px] min-w-[180px]">Total Amount</td>
                <td className="p-[10px] min-w-[180px]">Discount</td>       
                <td className="p-[10px] min-w-[180px]">Delivery Interest</td>                
                <td className="p-[10px] min-w-[180px]">Delivery Amount</td>       
                <td className="p-[10px] min-w-[150px]">Status</td>
                <td className="p-[10px] min-w-[150px]">Create Date</td>

                
              </tr>
            </thead>
            <tbody className="text-primary-grey bg-[#fff]">
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={11}>
                    Loading...
                  </td>
                </tr>
              ) : principleReportEntryList.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                principleReportEntryList?.map((item, index) => (
                  <tr key={item.id} className="border">
                    <td className="p-[10px]">{index + 1}</td>
                    <td className="p-[10px]">{item.loan_date}</td>
                    <td className="p-[10px] bg-[#ffebcd]">{item.loanno}</td>

                    <td className="p-[10px] capitalize">{item.firm_name}</td>
                    <td className="p-[10px] capitalize">{item.customer_name}</td>
                    <td className="p-[10px] capitalize">{item.branch_name}</td>
                    <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.loan_amt).toFixed(2)}</td>
                    <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.distribute_amt).toFixed(2)}</td>
                    <td className="p-[10px]">{item.interest_percentage}%</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.monthly_interest_amount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.document_charge).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.interest_occured).toFixed(2)}</td>
                    <td className="p-[10px] bg-[#e0ffff]">Rs. {parseFloat(item.total_pay_amount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.discount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.delivery_interest_amount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.delivery_amount).toFixed(2)}</td>
                    <td className="p-[10px]">{item.statusname}</td>
                    <td className="p-[10px] capitalize">
                       <p>{convertToDate(item.created_at)}</p>
                       <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p>
                       </td>
                    
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {pagination === true ? (
          <div className="pb-5">
            <Pagination
              totalPages={totalPages}
              currentPage={tableFilters.page}
              setCurrentPage={pageHandler}
              entries={tableFilters.items_per_page}
              entriesHandler={entriesHandler}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
