import React, { useEffect, useState } from 'react'
import { getAxios, postAxiosWithToken } from '../../../../service/apiservice';
import { CustomerListEndPoint, customerReportsViewEndPoint, CustomerViewListEndPoint, StatusdropdownByStatusEndPoint, StatusEndPoint } from '../../../../service/api';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';

export default function CustomerReportsView() {

  const { actions, pagination } = useSelector((state) => state.appConfig);
  const navigate = useNavigate();

    const [viewCustomerReport, setViewCustomerReport] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [currentCustomer, setCurrentCustomer] = useState({});
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
      "search" : "",
      "from_date":null,
      "to_date":null,
      "page" : 1,
      "customer":localStorage.getItem('cutomer_report_id'),
      "metal":null,
      "items_per_page" : 10,
       "filterstatus":null,
       "firm":null
    });
    const [statusList, setStatusList] = useState([]);


    const totalLoanAmount = viewCustomerReport?.list?.reduce((total, item) => total + item.loan_amt||0, 0);
    const totalPaidAmount = viewCustomerReport?.list?.reduce((total, item) => total + item.monthly_interest_amount||0, 0);


    useEffect(() => {
        getStausDropdownData();
    },[])

    useEffect(() => {
      ViewCustomerReports(tableFilters);
    }, [tableFilters]);

    // const ViewCustomerReports = async () => {
    //     var response = await getAxios({
    //       url: customerReportsViewEndPoint + localStorage.getItem('cutomer_report_id') + "/",
        
    //     })
    
    //     if (response != null) {

    //         setViewCustomerReport(response.data)
    //         setIsLoading(false);
    //         toast.success(response.message)
          
          
    //     }
    //   }


  
    
      const ViewCustomerReports = async (body) => {
        var response = await postAxiosWithToken({
          url: CustomerViewListEndPoint,
          body: body,
        });
    
        if (response !== null) {
          setViewCustomerReport(response.data)
          setIsLoading(false);
          setTotalPages(response.data.total_pages);
          toast.success(response.message)
        }
      };

      




      const getStausDropdownData = async () => {
        var response = await getAxios({
          url: StatusEndPoint,
        });
    
        if (response !== null) {
            setStatusList(response.data.list);
        }
      };

      const handleFilterStatus = (value) => {
        console.log(value)
        setTableFilters((prevState) => ({
            ...prevState,
            filterstatus: value === "all" ? null : value
        }))
    }



    
  const pageHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };



  return (
    <div>

      <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">View Customer Reports</p>


        <div className='mt-3 flex gap-5 max-[480px]:flex-col'>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Customer Name</p>
            <p className='text-green-600'>{viewCustomerReport?.customer_name}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Mobile Number</p>
            <p className='text-green-600'>{viewCustomerReport?.mobile}</p>
            </div>

            <div className='mt-3'>
                        <select
                            placeholder="metal_type"
                            className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[200px] p-[11px] text-[17px] outline-none"
                            name="status_name"
                            value={tableFilters.filterstatus} 
                            onChange={(e) => handleFilterStatus(e.target.value)}
                            >
                            <option value="all">--Select Status--</option>
                            {statusList?.map((option) => (
                                <option key={option?.id} value={option?.id}>
                                {option?.statusname}
                                </option>
                            ))}
                        </select>
                    </div>
        </div>


        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
          <table className="w-full">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[150px]">Loan Date</td>
                <td className="p-[10px] min-w-[150px]">Loan No</td>
                <td className="p-[10px] min-w-[150px]">Customer Name</td>
                <td className="p-[10px] min-w-[150px]">Firm Name</td>
                {/* <td className="p-[10px] min-w-[150px]">Nominee</td> */}
                <td className="p-[10px] min-w-[100px]">Gross</td>
                <td className="p-[10px] min-w-[180px]">Loan Amt</td>
                <td className="p-[10px] min-w-[150px]">Distribute Amt</td>
                <td className="p-[10px] min-w-[100px]">Interst %</td>
                <td className="p-[10px] min-w-[100px]">Monthly Interst </td>
                <td className="p-[10px] min-w-[100px]">Document Charge </td>
                <td className="p-[10px] min-w-[180px]">Till Interest</td>
                <td className="p-[10px] min-w-[180px]">Total Amount</td>
                <td className="p-[10px] min-w-[180px]">Discount</td>       
                <td className="p-[10px] min-w-[180px]">Delivery Interest</td>                
                <td className="p-[10px] min-w-[180px]">Delivery Amount</td>       
                <td className="p-[10px] min-w-[150px]">Status</td>
                <td className="p-[10px] min-w-[150px] sticky right-0 bg-table-head">
                  Actions
                </td>
              </tr>
            </thead>
            <tbody className="text-primary-grey bg-[#fff]">
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={11}>
                    Loading...
                  </td>
                </tr>
              ) : viewCustomerReport.list.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                viewCustomerReport?.list?.map((item, index) => (
                  <tr key={item.id} className="border">
                    <td className="p-[10px]">{index + 1}</td>
                    <td className="p-[10px]">{item.loan_date}</td>
                    <td className="p-[10px] bg-[#ffebcd]">{item.loanno}</td>
                    <td className="p-[10px] capitalize">{item.customer_name}</td>
                    <td className="p-[10px] capitalize">{item.firm_name}</td>
                    {/* <td className="p-[10px] capitalize">{item.relationship_name}</td> */}
                    <td className="p-[10px]">{item.total_gross_weight}</td>
                    <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.loan_amt).toFixed(2)}</td>
                    <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.distribute_amt).toFixed(2)}</td>
                    <td className="p-[10px]">{item.interest_percentage}%</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.monthly_interest_amount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.document_charge).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.interest_occured).toFixed(2)}</td>
                    <td className="p-[10px] bg-[#e0ffff]">Rs. {parseFloat(item.total_pay_amount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.discount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.delivery_interest_amount).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.delivery_amount).toFixed(2)}</td>
                    <td className="p-[10px]">{item.statusname}</td>
                    <td className="p-[10px] sticky right-0 bg-white">
                      <div className="flex gap-3">
                        <div
                          onClick={() => {
                            if (actions.editPermit) {
                              setCurrentCustomer(item);
                              localStorage.setItem(
                                "cutomer_insidereport_view_id",
                                item.id.toString()
                              );
                              navigate("view-customer-reports");
                            } else {
                              toast.error("You don't have permission for edit");
                            }
                          }}
                          className="cursor-pointer hover:scale-125"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                          >
                            <circle cx="16" cy="16" r="4" fill="#61a375" />
                            <path
                              fill="#61a375"
                              d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 22.5a6.5 6.5 0 1 1 6.5-6.5a6.51 6.51 0 0 1-6.5 6.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}

<tr>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px] text-black font-bold text-[16px]'>Total</td>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px] text-black font-bold text-[16px]'>{totalLoanAmount}</td>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px]'></td>
                    <td className='p-[10px] text-black font-bold text-[16px]'>{totalPaidAmount}</td>  
                    <td className='p-[10px] sticky right-0 bg-white'></td>                  
                </tr>
            </tbody>

               


          </table>
        </div>
        {pagination === true ? (
          <div className="pb-5">
            <Pagination
              totalPages={totalPages}
              currentPage={tableFilters.page}
              setCurrentPage={pageHandler}
              entries={tableFilters.items_per_page}
              entriesHandler={entriesHandler}
            />
          </div>
        ) : (
          ""
        )}

        <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5  p-[20px] w-full">

<button
  id="backnav"
  onClick={(e) => { e.preventDefault(); navigate(-1) }}
  className="capitalize bg-menu-head-bg border text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "
>close</button>




</div>

      
    </div>
  )
}
