import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { changePasswordEndPoint, userEndPoint, userListEndPoint, userStatusEndPoint } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import UserFilters from "./userfilter";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { convertToTime } from "../../../utilities/time";

export default function User() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [userList, setUserList] = useState([]);
    const [filteredUserList, setFilteredUserList] = useState([]);
    const [currentUser, setCurrentUser] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [passwordPopup, setPasswordPopup] = useState(false);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: 10,
        page: 1
    });

    const [encryptPasswordValue, setEncryptPasswordValue] = useState(true);

    const { actions, pagination } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    function closePasswordModal() {
        setPasswordPopup(false)
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px]'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            localStorage.setItem('user_form_mode', 'create');
            navigate('user-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    useEffect(() => {
        getUserListData(tableFilters)
    }, [tableFilters])

    const getUserListData = async (body) => {
        var response = await postAxiosWithToken({
            url: userListEndPoint,
            body: body
        })

        if (response !== null) {
            setUserList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: userStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getUserListData()
            toast.success(response.message)
        }
    }
    

    const deleteStaff = async () => {
        var response = await deleteAxiosWithToken({
            url: userEndPoint + currentUser.id + '/'
        })

        if (response !== null) {
            getUserListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }



    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    const updatePassword = async () => {
        var response = await putAxiosWithToken({
            url: changePasswordEndPoint + currentUser.id + '/',
            body: {
                password: document.getElementById('password').value
            }
        })

        if (response != null) {
            toast.success(response.message)
            closePasswordModal()
        }
    }

    return (
        <>
            <div>
                <div className="flex justify-end">
                    <button
                        onClick={() => {
                            if (actions.addPermit) {
                                localStorage.setItem('user_form_mode', 'create'); navigate('user-form')
                            } else {
                                toast.error("You don't have permission for edit")
                            }
                        }}
                        className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add User <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div id="filteroptions" className='my-[5px]'>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <UserFilters filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Email</td>
                                <td className="p-[10px] min-w-[150px]">Phone no</td>
                                <td className="p-[10px] min-w-[150px]">User Role</td>
                                <td className="p-[10px] min-w-[150px]">Branch</td>
                                <td className="p-[10px] min-w-[150px]">Logged In</td>
                                <td className="p-[10px] min-w-[150px]">Created At</td>
                                <td className="p-[10px] min-w-[150px]">Status</td>
                                <td className="p-[10px] min-w-[150px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-[#fff]">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={8}>Loading...</td>
                                </tr> :
                                    userList.length === 0 ? <tr><td className="text-center border" colSpan={8}>No Data</td></tr> :
                                        userList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.email ? item.email : "-"}</td>
                                                <td className="p-[10px]">{item.phone}</td>
                                                <td className="p-[10px]">{item.role_name}</td>
                                                <td className="p-[10px]">{item.branch_name}</td>
                                                <td className="p-[10px]">
                                                    <div>
                                                        <SwitchButton enabled={item.is_loggedin} />
                                                    </div>
                                                </td>
                                                {/* <td className="p-[10px]">{datetimetodate(item.created_at)}</td> */}
                                                <td className="p-[10px] capitalize">
                       <p>{convertToDate(item.created_at)}</p>
                       <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p>
                       </td>                    <td className="p-[10px]">
                                                    <div onClick={() => {
                                                        if (actions.editPermit) {
                                                            onStatusChange(item.id)
                                                        } else {
                                                            toast.error("You don't have permission for update the status")
                                                        }
                                                    }}
                                                    >
                                                        <SwitchButton enabled={item.is_active} />
                                                    </div>
                                                </td>
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => {
                                                            if (actions.editPermit) {
                                                                setCurrentUser(item);
                                                                setPasswordPopup(true)
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12.63 2c5.53 0 10.01 4.5 10.01 10s-4.48 10-10.01 10c-3.51 0-6.58-1.82-8.37-4.57l1.58-1.25C7.25 18.47 9.76 20 12.64 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8C8.56 4 5.2 7.06 4.71 11h2.76l-3.74 3.73L0 11h2.69c.5-5.05 4.76-9 9.94-9m2.96 8.24c.5.01.91.41.91.92v4.61c0 .5-.41.92-.92.92h-5.53c-.51 0-.92-.42-.92-.92v-4.61c0-.51.41-.91.91-.92V9.23c0-1.53 1.25-2.77 2.77-2.77c1.53 0 2.78 1.24 2.78 2.77zm-2.78-2.38c-.75 0-1.37.61-1.37 1.37v1.01h2.75V9.23c0-.76-.62-1.37-1.38-1.37" /></svg>
                                                        </div>
                                                        <div onClick={() => {
                                                            if (actions.editPermit) {
                                                                setCurrentUser(item);
                                                                localStorage.setItem('user_form_mode', 'update'); localStorage.setItem('user_form_id', item.id.toString()); navigate('user-form');
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentUser(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>




                {pagination === true ?
                    <div className="pb-5">
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete User'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentUser?.email} user?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteStaff() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
            <Popup title={'Change Password'} setIsOpen={setPasswordPopup} isOpen={passwordPopup} closeModal={closePasswordModal}>
                <div className="mt-[10px]">
                    <div >
                        <p className="mb-[5px] text-[16px]">Password <span className="text-red-500">*</span></p>
                        <div className="flex border border-gray-300 rounded-lg items-center py-[7px] px-[10px]">
                            <input
                                type={encryptPasswordValue ? "password" : "text"}
                                placeholder="password"
                                className="sm:mb-[5px] w-full text-[17px] outline-none"
                                name="password"
                                id="password"
                            />
                            <div onClick={() => setEncryptPasswordValue(true)} className={`${!encryptPasswordValue ? 'block' : 'hidden'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z" /></svg>
                            </div>
                            <div onClick={() => setEncryptPasswordValue(false)} className={`${encryptPasswordValue ? 'block' : 'hidden'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="currentColor"><path d="m10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" /><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6l-12-12l.708-.708l12 12l-.708.708z" /></g></svg>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[10px] flex gap-3 justify-end items-center">
                        <button onClick={() => closePasswordModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Close</button>
                        <button type="submit" onClick={() => { updatePassword() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn ">Change Password</button>
                    </div>
                </div>
            </Popup>
        </>
    );
}