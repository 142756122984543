import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  todayGoldRate: 0.00,
  todaySilverRate: 0.00,
  fullScreenPopupOpen: true,
  actions: {
    viewPermit: false,
    addPermit: true,
    editPermit: false,
    deletePermit: false
  },
  pagination: true,
  addT: false,

  now: true,
  underMaintanance: false,

  datT: false,
  now: true
}

export const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: initialState,
  reducers: {
    setTodayGoldRate: (state, action) => {
      state.todayGoldRate = action.payload
    },
    setTodaySilverRate: (state, action) => {
      state.todaySilverRate = action.payload
    },
    setFullScreenPopupOpen: (state, action) => {
      state.fullScreenPopupOpen = action.payload
    },
    setActions: (state, action) => {
      state.actions = action.payload
    },
    setPagination: (state, actions) => {
      state.pagination = actions.payload
    },
    setAddT: (state, actions) => {
      state.addT = actions.payload
    },
    setDatT: (state, actions) => {
      state.datT = actions.payload
    },
    setNow: (state, actions) => {
      state.now = actions.payload
    },
    setUnderMaintanance: (state, actions) => {
      state.underMaintanance = actions.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTodayGoldRate, setTodaySilverRate, setFullScreenPopupOpen, setActions, setPagination, setAddT, setNow,setDatT,setUnderMaintanance } = appConfigSlice.actions

export default appConfigSlice.reducer