import { branchListEndPoint, cityDropdownListEndPoint, customerEndPoint, customerGroupListEndPoint, genterDropdownListEndPoint, relationshipDropdownListEndPoint, stateDropdownListEndPoint, towndropDownEndPoint, villagedropDownEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { isOptionalValidForm } from "../../../utilities/formvalidation";
import { Country, State } from "country-state-city";
import { defaultCountry, defaultState } from "../../../constant";
import ProfileImageUploader from "../../../components/Profileimage";
import ImageUploader from "../../../components/Profileimage";


export default function CustomerForm() {
    const optionalFields = ["additional_no","pan_no","aadhar_no"]

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentCustomer, setCurrentCustomer] = useState({});
    const [relationDropdown, setRelationDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);
    const [errors, setErrors] = useState({});
    const [branchList, setBranchList] = useState([]);
    const [genterList, setGenterList] = useState([]);

    const [isimage, setIsImage] = useState(true);

    const [selectedImages, setSelectedImages] = useState();

    console.log("currentCustomer",currentCustomer)

  



    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            setFormMode('create')
            navigate(-1)
        }
    };

    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getTownListData()
        getRelationListData()
        getBranchListData()
        getgenterListData()
        setFormMode(localStorage.getItem('customer_form_mode'))

       
        if (localStorage.getItem('customer_form_mode') === 'update') {
            getCustomerDetails()
        }
    }, [])


   

  
    const getTownListData = async () => {
        var response = await getAxios({
            url: towndropDownEndPoint 
        })

        if (response !== null) {
            setStateDropdown(response.data.list)
        }
    }
    const getVillageListData = async (id) => {
        var response = await getAxios({
            url: villagedropDownEndPoint + id + "/"
        })

        if (response !== null) {
            setCityDropdown(response.data.list)
        }
    }
    const getRelationListData = async (id) => {
        var response = await getAxios({
            url: relationshipDropdownListEndPoint 
        })

        if (response !== null) {
            setRelationDropdown(response.data.list)
        }
    }
    const getBranchListData = async (id) => {
        var response = await getAxios({
            url: branchListEndPoint 
        })

        if (response !== null) {
            setBranchList(response.data.list)
        }
    }
    const getgenterListData = async (id) => {
        var response = await getAxios({
            url: genterDropdownListEndPoint 
        })

        if (response !== null) {
            setGenterList(response.data.list)
        }
    }

  

    const getCustomerDetails = async () => {
        var response = await getAxios({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/"
        })

        if (response !== null) {
            console.log("res", response.data)
            var tempDetails = {
                customer_name: response.data?.customer_name,
                mobile: response.data?.mobile,
                additional_no: response.data?.additional_no,
                gender: response.data?.gender,
                address: response.data?.address,
                town: response.data?.town,
                village: response.data?.village,
                cus_image:response.data?.cus_image,
                pincode: response.data?.pincode,
                date_of_birth: response.data?.date_of_birth,
                id_branch: response.data?.id_branch,
                relationship_name: response.data?.relationship_name,
                relationship_mobile: response.data?.relationship_mobile,
                relationship_relationship: response.data?.relationship_relationship,
                aadhar_no: response.data?.aadhar_no,
                pan_no: response.data?.pan_no,


            }
            getVillageListData(response.data?.town)
            
           
            setCurrentCustomer(tempDetails)
        }
    }

    // const onSubmit = async (e) => {
    //     e.preventDefault();
    //     let formFields = new FormData(e.target);
    //     let formDataObject = Object.fromEntries(formFields.entries());

    //     var err = {}

    //     for (var field of Object.entries(formDataObject)) {
    //         if (field[1].length === 0 && !optionalFields.includes(field[0])) {
    //             err[field[0]] = `${field[0]} field is required`
    //         } else {
    //             err[field[0]] = ""
    //         }
    //     }

 
    //     setErrors((prevState) => ({
    //         ...prevState,
    //         ...err,
    //       }));


    //     if (isOptionalValidForm(formDataObject, optionalFields)) {
    //         if (localStorage.getItem('customer_form_mode') === 'create') {
                
    //             createCustomer(currentCustomer)
    //         } else {
    //             updateCustomer(currentCustomer)
    //         }
    //     }
    // }




const onSubmit = async (e) => {
    e.preventDefault();
    const formFields = new FormData(e.target);

    let err = {};
    const optionalFields = ['gender', 'relationship_name', 'date_of_birth', 'relationship_mobile', 'aadhar_no', 'pan_no', 'additional_no', 'relationship_relationship'];

    for (let [field, value] of formFields.entries()) {
        if (!optionalFields.includes(field) && typeof value === 'string' && value?.trim().length === 0) {
            err[field] = `${field.replace('_', ' ')} field is required`;
        } else {
            err[field] = "";
        }
    }

    setErrors(prevState => ({
        ...prevState,
        ...err
    }));

    formFields.append("cus_image", currentCustomer.cus_image);
    formFields.append("is_file", isimage);


    const hasErrors = Object.values(err).some((errorMsg) => errorMsg.length > 0);
 
        if (!hasErrors) {
            if (localStorage.getItem('customer_form_mode') === 'create') {
                // Submit FormData directly for creation
                createCustomer(formFields);
            } else {
                // Submit FormData directly for update
                updateCustomer(formFields);
            }
        }
};






    const createCustomer = async (body) => {
        var response = await postAxiosWithToken({
            url: customerEndPoint,
            body: body
        })

        if (response != null) {
            toast.success(response.message)
            navigate(-1)
        }
    }

    const updateCustomer = async (body) => {
        var response = await putAxiosWithToken({
            url: customerEndPoint + localStorage.getItem('customer_form_id') + "/",
            body: body
        })

        if (response != null) {                                     
            localStorage.removeItem('customer_form_id')
            localStorage.setItem('customer_form_mode', 'create')
            toast.success(response.message)
            navigate(-1)
        }
    }

    const onValueUpdate = (e) => {
        const { name, value } = e.target;

     if (name === 'town') {
        getVillageListData(value)
        }
        
            
            setCurrentCustomer((prevState) => ({
                ...prevState,
                [name]: value
            }))

             
            setErrors((prevState) => ({
                ...prevState,
                [name]: value.length === 0 ? `${name} field is required` : ''
            }));


    }


    const onImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Add validation and processing logic here
            console.log("Selected image:", file);

            setSelectedImages(file)
        }
    };


    const videoRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isCameraActive, setIsCameraActive] = useState(false);
   
    // Start the webcam stream
    const startCamera = async () => {
      try {
        setCapturedImage(null); // Clear any previous captured image
        setIsCameraActive(true); // Set camera active flag to true
   
        // Start the video stream
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      } catch (error) {
        console.error("Error accessing webcam:", error);
        alert("Camera access failed. Please check camera permissions and ensure your browser supports it.");
      }
    };
   
    // Capture the current frame from the video stream
    const captureImage = () => {
      const video = videoRef.current;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL("image/png");
      setCapturedImage(image); 
      console.log("image",image)
      
      setCurrentCustomer(prevState => ({
        ...prevState, // Preserve the other state properties
        cus_image: image, // Set the captured image URL
      }));// Set the captured image

      setIsImage(false)
   
      // Stop the camera stream after capturing the image
      const stream = videoRef.current?.srcObject;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop the video track
        videoRef.current.srcObject = null; // Disconnect the video stream
      }
   
      setIsCameraActive(false); // Set camera inactive after capture
    };
   
    // Handle file upload
    const handleFileUpload = (event) => {
      // Stop the camera if it's active before uploading an image
      if (isCameraActive) {
        const stream = videoRef.current?.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop()); // Stop the video track
          videoRef.current.srcObject = null; // Disconnect the video stream
        }
        setIsCameraActive(false); // Set camera inactive after file upload
      }
   
      const file = event.target.files[0];
      if (file) {
        const fileURL = URL.createObjectURL(file);
        setCapturedImage(fileURL); // Set the uploaded image as capturedImage

        setCurrentCustomer(prevState => ({
            ...prevState, // Preserve the other state properties
            cus_image: file, // Set the captured image URL
          }));
        setIsImage(true)
      }
    };



    

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} Customer</p>
                <form id="allData" onSubmit={onSubmit}>

                    <div className="border p-[15px] rounded-xl shadow-lg  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Customer Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Customer Full Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="customer name"
                                    className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="customer_name"
                                    value={currentCustomer.customer_name}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.customer_name}</p>
                            </div>
                            
                            
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Phone no <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="mobile"
                                    value={currentCustomer.mobile}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.mobile}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Alternate Number</p>
                                <input
                                    type="text"
                                    placeholder="Alternate Number"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="additional_no"
                                    value={currentCustomer.additional_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Date of Birth</p>
                                <input
                                    type="date"
                                    placeholder="DOB"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="date_of_birth"
                                    value={currentCustomer.date_of_birth}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.date_of_birth}</p>
                            </div>


                            <div className="my-[5px]">
                                    <>
                                        <p className="mb-[5px] text-[15px]">Gender </p>
                                        <div className=''>
                                            <select
                                                placeholder="Gender"
                                                className="sm:mb-[5px] capitalize border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                                name="gender"
                                                value={currentCustomer.gender}
                                                onChange={onValueUpdate}
                                            >
                                                <option value="">-- Select Gender --</option>
                                                {genterList.map((option) => (
                                                    <option className="capitalize" key={option?.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>

                                    <p className="text-red-500 font-[400] text-[13px]">{errors.gender}</p>
                                </div>





                                <div className="my-[5px]">
                                    <>
                                        <p className="mb-[5px] text-[15px]">Relationship </p>
                                        <div className=''>
                                            <select
                                                placeholder="Relationship"
                                                className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                                name="relationship_relationship"
                                                value={currentCustomer.relationship_relationship}
                                                // onChange={(e) => handleBranchFilter(e.target.value)}
                                                onChange={onValueUpdate}
                                            >
                                                <option value="">-- Select Relationship --</option>
                                                {relationDropdown.map((option) => (
                                                    <option className="capitalize" key={option?.id} value={option.id}>
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>

                                    <p className="text-red-500 font-[400] text-[13px]">{errors.relationship_relationship}</p>
                                </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Relation Name </p>
                                <input
                                    type="text"
                                    placeholder="Relation Name"
                                    className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[8px] text-[17px] outline-none bg-transparent"
                                    name="relationship_name"
                                    value={currentCustomer.relationship_name}
                                    onChange={onValueUpdate}
                                />
                               {/* <p className="text-red-500 font-[400] text-[13px]">{errors.relationship_name}</p> */}

                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Relation Number</p>
                                <input
                                    type="text"
                                    placeholder="Relation Number"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[8px] text-[17px] outline-none bg-transparent"
                                    name="relationship_mobile"
                                    value={currentCustomer.relationship_mobile}
                                    onChange={onValueUpdate}
                                />
                                {/* <p className="text-red-500 font-[400] text-[13px]">{errors.relationship_mobile}</p> */}

                            </div>

                            <div className="my-[5px]">
                                    <>
                                        <p className="mb-[5px] text-[15px]">Branch <span className="text-red-500">*</span></p>
                                        <div className=''>
                                            <select
                                                placeholder="Branch"
                                                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none bg-transparent"
                                                name="id_branch"
                                                value={currentCustomer.id_branch}
                                                onChange={onValueUpdate}
                                            >
                                                <option value={""}>-- Select Branch --</option>
                                                {branchList.map((option) => (
                                                    <option className="capitalize" key={option?.id} value={option.id}>
                                                        {option.branch_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>

                                    <p className="text-red-500 font-[400] text-[13px]">{errors.id_branch}</p>
                                </div>
                        

                                {/* {(currentCustomer.cus_image == undefined || currentCustomer.cus_image == null) ? (
        
        <div className="my-[5px]">
            <p className="mb-[5px] text-[16px]">Upload Profile Image</p>

            <input
                type="file"
                accept="image/*"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="cus_image"
                // value={currentCustomer.cus_image}
                onChange={onImageUpload}
            />

            <p className="text-red-500 font-[400] text-[13px]">{errors.cus_image}</p>
        </div>
        ) : ( <div><label htmlFor="customerimg"><img width={"200px"} src={currentCustomer.cus_image} alt="" /></label>
        <input
                id="customerimg"
                type="file"
                accept="image/*"
                className="sm:mb-[5px] mt-2 border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                name="cus_image"
                // value={currentCustomer.cus_image}
                onChange={onImageUpload}
            />
                        <p className="text-red-500 font-[400] text-[13px]">{errors.cus_image}</p>

 </div>)} */}
  
        </div>


        <div  className="my-[5px]">
        <p className="mb-[5px] text-[16px]">Camera and Image Upload</p>
        <div className="flex gap-3">
        {/* Show 'Start Camera' when no camera is active and no image is captured */}
        {!isCameraActive  && (
          <button className="p-2 bg-blue-500 text-white rounded-lg" onClick={startCamera}>Start Camera</button>
        )}
 
        {/* Show 'Capture Image' when the camera is active and no image is captured */}
        {isCameraActive && (
          <button  className="p-2 bg-green-500 text-white rounded-lg" onClick={captureImage}>Capture Image</button>
        )}
 
        {/* Upload image option */}
        <input
          type="file"
          accept="image/*"
          className="border border-gray-300 rounded-lg min-[480px]:w-[300px] w-full p-[10px] text-[17px] outline-none"
          onChange={handleFileUpload}
          style={{ marginLeft: "10px" }}
        />
  
      </div>
 
      <div className="mt-2">
        {/* Show the video stream only if no captured image */}

        {(isCameraActive)  && <video ref={videoRef}  width="300" height="200"  />}

        

        {/* Display the captured image */}
        {(capturedImage != null  && (currentCustomer.cus_image == undefined )) &&(
          <div>
            <h4>Captured/Uploaded Image:</h4>
            <img src={capturedImage} alt="Captured" width="300" />
          </div>
        )}

{ (currentCustomer.cus_image && capturedImage != null) &&  (
          <div>
            <h4>Captured/Uploaded Image:</h4>
            <img src={capturedImage} alt="Captured111" width="300" />
          </div>
        )}

        
{ (currentCustomer.cus_image && capturedImage == null) &&  (
          <div>
            <h4>Captured/Uploaded Image:</h4>
            <img src={currentCustomer.cus_image} alt="Captured000" width="300" />
          </div>
        )}
      </div>
    </div>









                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Address Details</p>
                        <div className="grid min-[2000px]:grid-cols-5 2xl:grid-cols-4 xl:grid-cols-3 md:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Address <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="Address"
                                    className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    value={currentCustomer.address}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.address}</p>
                            </div>
                           
                           
                            
                           
                           
                            
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Town <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="Town"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="town"
                                    value={currentCustomer.town}
                                    onChange={onValueUpdate}
                                >
                                    <option value={""}>--select town--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"town" + option.town_name} value={option.id}>{option.town_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.town}</p>
                            </div>


                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[15px]">Village <span className="text-red-500">*</span> </p>
                                <select
                                    placeholder="Village"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="village"
                                    value={currentCustomer.village}
                                    onChange={onValueUpdate}

                                >
                                    <option value={""}>--select village--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"village" + option.village_name} value={option.id}>{option.village_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.village}</p>
                            </div>



                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">Pincode <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    value={currentCustomer.pincode}
                                    onChange={onValueUpdate}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.pincode}</p>
                            </div>
                        </div>
                    </div>


                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px]  bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2">Other Details</p>
                        <div className="grid xl:grid-cols-3 sm:grid-cols-2 gap-5">
                           
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pan no</p>
                                <input
                                    type="text"
                                    placeholder="pan card no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pan_no"
                                    value={currentCustomer.pan_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Aadhar no</p>
                                <input
                                    type="text"
                                    placeholder="aadhar card no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="aadhar_no"
                                    value={currentCustomer.aadhar_no}
                                    onChange={onValueUpdate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); navigate(-1) }}
                            className="capitalize  bg-menu-head-bg text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}