import React, { useEffect, useState } from 'react'
import { loanEndPoint } from '../../../../service/api';
import toast from 'react-hot-toast';
import { getAxios } from '../../../../service/apiservice';
import { useNavigate } from 'react-router-dom';

export default function CustomerLoanView() {

    const navigate = useNavigate();

    const [viewCustomerLoanReport, setViewCustomerLoanReport] = useState([]);
    const [isLoading, setIsLoading] = useState(true);



    useEffect(() => {
        ViewCustomerLoanReports();
    },[])

    const ViewCustomerLoanReports = async () => {
        var response = await getAxios({
          url: loanEndPoint + localStorage.getItem('cutomer_insidereport_view_id') + "/",
        
        })
    
        if (response != null) {

            setViewCustomerLoanReport(response.data)
            setIsLoading(false);
          toast.success(response.message)
          
          
        }
      }



  return (
    <div>

<p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">View Loan Reports</p>


     <div className='mt-3 flex gap-[50px] max-[480px]:flex-col'>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Customer Name</p>
            <p className='text-green-600 capitalize'>{viewCustomerLoanReport?.loan_list?.customer_name}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Mobile Number</p>
            <p className='text-green-600'>{viewCustomerLoanReport?.loan_list?.mobile}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Address</p>
            <p className='text-green-600 capitalize'>{viewCustomerLoanReport?.loan_list?.address}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Loan No</p>
            <p className='text-green-600'>{viewCustomerLoanReport?.loan_list?.loanno}</p>
            </div>
            
        </div>


        <div className="p-2 mt-[50px]">
              <p className="font-bold text-[18px]">Item Details</p>

              <div className="mt-3  w-full overflow-x-scroll ">
                <table className='w-full'>
                  <thead className="border border-black text-[15px] uppercase font-[700]">
                    <tr>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        S No
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Type
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Item Name
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Pcs
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Gwt
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Swt
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Nwt
                      </td>
                      <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                        Remarks
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {viewCustomerLoanReport?.iteam_details?.map((item, index) => (
                      <tr>
                        <>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                            {index + 1}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2 capitalize">
                            {item.metal__metal_name}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2 capitalize">
                            {item.item__item_print}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                            {item.noofpieces}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                            {item.gross_weight}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                            {item.stone_weight}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                            {item.net_weight}
                          </td>
                          <td className="p-[10px] min-w-[150px] border border-black px-4 py-2">
                            {item.remarks}
                          </td>
                        </>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              
            </div>


            <div className="p-2 pb-[30px] mt-[50px]">
              <p className="font-bold text-[18px]">Amount Details</p>



              <div className='mt-3 grid xl-grid-cols-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-6 gap-3 mx-auto'>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Loan Date</p>
            <p className='text-green-600'>{viewCustomerLoanReport?.loan_list?.loan_date}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Total Amount</p>
            <p className='text-green-600'>Rs. {viewCustomerLoanReport?.loan_list?.loan_amt}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Distribute Amount</p>
            <p className='text-green-600'>Rs. {viewCustomerLoanReport?.loan_list?.distribute_amt}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Interest %</p>
            <p className='text-green-600'>{viewCustomerLoanReport?.loan_list?.interest_percentage}%</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Month Interest</p>
            <p className='text-green-600'>Rs. {viewCustomerLoanReport?.loan_list?.interest_amount}</p>
            </div>
            <div className='mt-3 border p-3 rounded-xl shadow-lg'>
             <p className=' font-bold text-[16px]'>Paid Amount</p>
            <p className='text-green-600'>Rs. {viewCustomerLoanReport?.loan_list?.paid_amount}</p>
            </div>
            
        </div>



             </div>


             <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5  p-[20px] w-full">

                          <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); navigate(-1) }}
                            className="capitalize bg-menu-head-bg border text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "
                        >close</button>


    

              </div>

      
    </div>
  )
}
