import React, { useEffect, useRef, useState } from "react";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../../service/apiservice";
import {
  branchListEndPoint,
  ConfigurationListEndPoint,
  deliveryEndPoint,
  intimationgetlistEndPoint,
  manageoverdueEndPoint,
  manageOverDueLoanListEndPoint,
  manageOverDueMobileListEndPoint,
  paymentModedropDownEndPoint,
  paymentTypeDropDownEndPoint,
  searchLoanNumberEndPoint,
  searchMobileNumberEndPoint,
} from "../../../service/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Popup";
import ReactToPrint from "react-to-print";
import AuctionPrintSave from "./auctionprintsave";

export default function LoanManageDue() {

  let componentRef = useRef();

  const [loanNumber, setLoanNumber] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [loanDetails, setLoanDetails] = useState({});
  const [interestAmount, setInterestAmount] = useState(0);
  const [interestoccured, setInterestOccured] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalMonth, setTotalMonth] = useState(null);
  const [loanAmount, setLoanAmount] = useState(0);
  const [netAmount, setNetAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [releaseCharge, setReleaseCharge] = useState(0);
  const [errors, setErrors] = useState({});
  const [formMode, setFormMode] = useState("create");
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setselectedBranch] = useState("");
  const [remarks, setRemarks] = useState("");

  const [paymentAmounts, setPaymentAmounts] = useState({});
  const [Realsecharge, setRealsecharge] = useState({});
  const [selectedLoan, setselectedLoan] = useState({});
  const [Currendelete, setCurrendelete] = useState({});
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  console.log(Currendelete, "Currendelete")

  const [maxMonth, setMaxMonth] = useState(null);
  const [paymentlable, setPaymentLable] = useState();


  const [paidAmount, setPaidAmount] = useState();

  // const [paymentTypeDropdown, setPaymentTypeDropdown] = useState([]);
  const [cash, setCash] = useState(0);
  const [bank, setBank] = useState(0);

  const [paymentModeDropdown, setPaymentModeDropdown] = useState([]);
  const [IntimationList, setIntimationList] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  // const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [paymentValues, setPaymentValues] = useState([]);

  const [savepayment, setsavepayment] = useState(false)


  const [delivery_print, setDeliveryPrint] = useState(0);


  const navigate = useNavigate();

  useEffect(() => {
    // getPaymentTypeDropDown();
    getConfigurationData()
    getPaymentModeDropDown();
    getBranchDropdownData();


  }, []);

  useEffect(() => {
    if (selectedPaymentMode == 3) {
      setCash(cash);
      setBank(bank);
    } else if (selectedPaymentMode == 1) {
      setCash(netAmount);
    } else if (selectedPaymentMode == 2) {
      setBank(netAmount);
    }

  }, [selectedPaymentMode, netAmount]);


  useEffect(() => {
    let data = [...paymentValues];
    Object.entries(paymentAmounts).map((item) => {
      if (item[0].includes('payment')) {

        for (var i in data) {
          if (data[i].id.toString() == item[0].slice(7, item[0].length)) {
            data[i]['value'] = item[1];
          }
        }
      }
    })

    setPaymentValues(data)
  }, [paymentAmounts])


  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // if (!selectedBranch && localStorage.getItem("branch") === "true") {
    //   newErrors.id_branch = "Branch is required";
    //   isValid = false;
    // }

    if (!selectedLoan.intimation_charge) {
      newErrors.intimation_charge = "intimation_charge is required";
      isValid = false;
    }


    // if (selectedPaymentMode == 1 || selectedPaymentMode == 3 ) {
    //   if (!cash || cash <= 0) {
    //     newErrors.cash = "Cash amount should be greater than zero";
    //     isValid = false;
    //   }
    // }

    // if (selectedPaymentMode == 2 || selectedPaymentMode == 3) {
    //   if (!bank || bank <= 0) {
    //     newErrors.bank = "Bank amount should be greater than zero";
    //     isValid = false;
    //   }
    // }

    setErrors(newErrors);
    return isValid;
  };






  const handleCashChange = (e) => {
    const value = parseFloat(e.target.value || 0);
    const remainingAmount = netAmount - value;
    setCash(e.target.value);
    setBank(remainingAmount);
    setCash(parseFloat(e.target.value || 0));
  };

  const handleBankChange = (e) => {
    const value = parseFloat(e.target.value || 0);
    const remainingAmount = netAmount - value;
    setBank(e.target.value);
    setCash(remainingAmount);
    setBank(parseFloat(e.target.value || 0));
  };

  // const getPaymentTypeDropDown = async () => {
  //   var respose = await getAxios({
  //     url: paymentTypeDropDownEndPoint,
  //   });

  //   if (respose !== null) {
  //     setPaymentTypeDropdown(respose.data.list);
  //   }
  // };

  // const getPaymentModeDropDown = async () => {
  //   var respose = await getAxios({
  //     url: paymentModedropDownEndPoint,
  //   });

  //   if (respose !== null) {
  //     setPaymentModeDropdown(respose.data.list);
  //     var tempList = [];
  //     for (var i of respose.data.list) {
  //       var dictData = i
  //       dictData['error'] = ""
  //       dictData['value'] = ""
  //       tempList.push(dictData);
  //     }
  //     setPaymentValues(tempList)



  //   }
  // };

  function closeDeleteModal() {
    setIsDeleteOpen(false)
  }

  const getPaymentModeDropDown = async () => {
    var respose = await getAxios({
      url: paymentModedropDownEndPoint,
    });

    if (respose !== null) {
      setPaymentModeDropdown(respose.data.list);
      var tempList = [];

      var values = [];
      Object.keys(paymentAmounts).map((item) => {
        if (item.includes("payment")) {
          values.push(item);
        }
      });

      if (values.length > 0) {
        Object.entries(paymentAmounts).map((item) => {
          if (item[0].includes("payment")) {
            for (var i of respose.data.list) {
              if (i.id.toString() == item[0].slice(7, item[0].length)) {
                var dictData = i;
                dictData["error"] = "";
                dictData["value"] = item[1];
                tempList.push(dictData);
              }
            }
          }
        });
      } else {
        for (var i of respose.data.list) {
          var dictData = i;
          dictData["error"] = "";
          dictData["value"] = "";
          tempList.push(dictData);
        }
      }
      setPaymentValues(tempList);
    }
  };


  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchList(response.data.list);
    }
  };

  const handleSearch = async (mobile) => {
    var response = await postAxiosWithToken({
      url: manageOverDueLoanListEndPoint,
      body: {
        loanno: mobile,
      },
    });

    if (response != null) {
      toast.success(response.message);

      // setLoanNumber(response.data);
      setLoanDetails(response.data);
      setIntimationList(response.data.intimation_charge)
      setselectedLoan(response.data.loan_list)
    }
  };


  // const LoanNumber = async (loanno) => {
  //   var response = await postAxiosWithToken({
  //     url: searchLoanNumberEndPoint,
  //     body: {
  //       loanno: loanno,
  //     },
  //   });

  //   if (response != null) {
  //     toast.success(response.message);
  //     setselectedBranch(response.data.loan_list.id_branch);
  //     setLoanDetails(response.data);
  //   }
  // };

  // useEffect(() => {
  //   if (loanDetails.loan_list) {
  //     setTotalMonth(loanDetails?.loan_list?.total_month || 0);
  //     setMaxMonth(loanDetails?.loan_list?.total_month || 0);
  //     setInterestAmount(loanDetails?.loan_list?.interest_amount || 0);
  //     setTotalAmount(loanDetails?.loan_list?.distribute_amt || 0);
  //     setInterestOccured(loanDetails?.loan_list?.interest_occured || 0);
  //     setLoanAmount(loanDetails?.loan_list?.loan_amt || 0);
  //     // setAdvanceAmount(loanDetails?.loan_list?.advance_interest || 0);
  //     // setIntrestCredit(loanDetails?.loan_list?.interest_credit || 0);
  //     setNetAmount(loanDetails?.loan_list?.distribute_amt || 0);
  //   }
  // }, [loanDetails]);






  // useEffect(() => {
  //   const calculatedInterestOccured = parseFloat(interestAmount || 0) * parseFloat(totalMonth || 0);
  //   setInterestOccured(calculatedInterestOccured);

  //   const calculatedTotalAmount = (
  //     (parseFloat(loanAmount || 0) - parseFloat(interestAmount || 0)) + calculatedInterestOccured
  //   );
  //   setTotalAmount(calculatedTotalAmount);
  //   const calculatedNetAmount = calculatedTotalAmount - parseFloat(discountAmount || 0);
  //   const calculatedNetAmountNew = calculatedNetAmount + parseFloat(releaseCharge || 0);
  //   setNetAmount(calculatedNetAmountNew);

  //   const calculatedPaidAmount = (parseFloat(cash || 0) + parseFloat(bank || 0));
  //   setPaidAmount(calculatedPaidAmount);

  // }, [
  //   totalMonth,
  //   interestAmount,
  //   loanAmount,
  //   discountAmount,
  //   releaseCharge,
  //   cash,
  //   bank,
  // ]);


  const getConfigurationData = async () => {
    var response = await getAxios({
      url: ConfigurationListEndPoint,
    });

    if (response !== null) {
      setDeliveryPrint(response.data.delivery_print);
    }
  };




  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    if (name == 'intimation_charge') {
      setselectedLoan((prevState) => ({
        ...prevState,
        intimation_charge: value,
      }));
    }

    if (name == 'remarks') {
      setselectedLoan((prevState) => ({
        ...prevState,
        remarks: value,
      }));
    }


  };

  const getDeliveryDetails = async () => {
    var response = await getAxios({
      url: deliveryEndPoint + localStorage.getItem("delivery_form_id") + "/",
    });

    if (response !== null) {

      // setLoanDetails(response?.data)
      setLoanDetails(response?.data);
      setMobileNumber(response?.data?.delivery_list?.mobile);
      setselectedBranch(response?.data?.delivery_list?.id_branch);
      // setSelectedPaymentType(response?.data?.delivery_list?.paymenttype);
      setSelectedPaymentMode(response?.data?.delivery_list?.payment_mode);
      setRemarks(response?.data?.delivery_list?.remarks);
      setCash(response?.data?.delivery_list?.cash);
      setBank(response?.data?.delivery_list?.bank);
      setDiscountAmount(response?.data?.delivery_list?.discount);
      setReleaseCharge(response?.data?.delivery_list?.release_charge);
      setPaymentAmounts(response?.data?.delivery_list?.paymentmode_list)
    }
  };



  // useEffect(() => {
  //   const loginDataString = localStorage.getItem("login");
  //   if (loginDataString) {
  //     const loginData = JSON.parse(loginDataString);

  //     setDeliveryPrint(loginData.delivery_print);
  //   } else {
  //     console.log("No login data found in localStorage");
  //   }
  // }, []); 



  const callApi = async (print) => {


    let body = {
      customer: loanDetails?.loan_list?.customer,
      loan: loanDetails?.loan_list?.id,
      intimation_charge: selectedLoan.intimation_charge,
      remarks: selectedLoan?.remarks,
      id_branch: loanDetails?.loan_list?.id_branch,


    }

    if (!validateForm()) {
      return;
    }

    console.log(body, "bodyyyyyyyyyyyyyyy")

    // if (oldValidations.includes(0)) {
    //     body['old_gold_particulars'] = []
    // } else {
    //     body['old_gold_particulars'] = oldParticulars
    // }

    var response = await postAxiosWithToken({
      url: manageoverdueEndPoint,

      // url: '' ,
      body: body,

    })

    if (response != null) {
      toast.success(response.message);
      if (print) {
        document.getElementById('print').click()
      }

      navigate(-1)
    }


  }

  // const handleCreateDelivery = (e) => {
  //   e.preventDefault();


  //   let data = [...paymentValues];
  //   for (var i in paymentValues) {
  //     if (paymentValues[i]['value'].length == 0) {
  //       data[i]['error'] = paymentValues[i].mode_name + "field is required"
  //     }
  //   }
  //   setPaymentValues(data)


  //   if (!validateForm()) {
  //     return;
  //   }



  //   const formDataObject = {};
  //   const formData = new FormData(e.target);

  //   formData.forEach((value, key) => {
  //     if (formDataObject[key]) {
  //       if (!Array.isArray(formDataObject[key])) {
  //         formDataObject[key] = [formDataObject[key]];
  //       }
  //       formDataObject[key].push(value);
  //     } else {
  //       formDataObject[key] = value;
  //     }
  //   });

  //   //  formDataObject.paid_amount = paidAmount;
  //   formDataObject.loanno = loanDetails?.loan_list?.loanno;
  //   formDataObject.customer = loanDetails?.loan_list?.customer;
  //   formDataObject.loan = loanDetails?.loan_list?.id;
  //   // formDataObject.distribute_amt = loanDetails?.loan_list?.distribute_amt;
  //   formDataObject.interest_occured = interestoccured;
  //   // formDataObject.interest_credit = intrestCredit;
  //   formDataObject.net_amount = netAmount;



  //   createDelivery(formDataObject);


  // };


  // const createDelivery = async (body, print) => {
  //   var response = await postAxiosWithToken({
  //     url: manageoverdueEndPoint,
  //     body: body,
  //   });

  //   if (response != null) {
  //     // clearData()
  //     toast.success(response.message);

  //     navigate(-1);


  //     if (print) {

  //       document.getElementById('print').click();
  //     }

  //   }
  // };

  // const updateDelivery = async (body) => {
  //   var response = await putAxiosWithToken({
  //     url: deliveryEndPoint + localStorage.getItem("delivery_form_id") + "/",
  //     body: body,
  //   });

  //   if (response != null) {
  //     toast.success(response.message);
  //     if (delivery_print == 1) {
  //       navigate("/Loan-Management/LoanDelivery-Print" + "/" + response.data.id);
  //     } else if (delivery_print == 2) {
  //       navigate("/Loan-Management/cart-print" + "/" + response.data.id);
  //     }

  //   }
  // };


  useEffect(() => {
    let totalvalue = 0;
    for (var i of paymentValues) {

      const value = parseFloat(i.value);

      if (!isNaN(value)) {
        totalvalue += value;
      }
    }
    setPaymentLable(totalvalue);
  }, [paymentValues]);





  const onPaymentValueUpdate = (e, index) => {
    const { name, value } = e.target;

    let data = [...paymentValues];
    data[index]['value'] = value;
    data[index]['error'] = value.length > 0 ? "" : data[index].mode_name + " field is required"

    setPaymentValues(data);
    setPaymentAmounts((prev) => ({
      ...prev,
      [name]: value, // Update the specific payment field
    }));


  }


  const handleSubmit = (e) => {
    e.preventDefault();

    // if (Number(paymentlable) !== Number(netAmount)) {
    //   toast.error("Payment and Net amount must be equal");
    //   return;
    // }
    // handleCreateDelivery(e);
  };


  const deleteintimation = async () => {
    var response = await deleteAxiosWithToken({
      url: manageoverdueEndPoint + Currendelete.id + '/',
    });

    if (response != null) {
      toast.success(response.message);
      Getintimation()
      setIsDeleteOpen(false);

    }
  };


  const Getintimation = async () => {
    var response = await getAxios({
      url: intimationgetlistEndPoint + Currendelete.loan + '/',
    });

    if (response != null) {
      setIntimationList(response.data.list)


    }
  };


  return (
    <div className="pb-5">


      <div className="hidden">
        <div className="mb-[25px]" ref={(el) => (componentRef = el)}>
          <AuctionPrintSave selectedLoan={selectedLoan} />

        </div>
      </div>
      <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Manage Over Due</p>


      <div >
        <div className="flex justify-between  border shadow-lg rounded-xl  p-[15px]">
          <div className=" flex items-center justify-evenly gap-[40px] mt-5">
            <div className=" w-full">
              <p className="mb-[5px] text-[15px]">Search Loan Number</p>
              <input
                type="text"
                placeholder="Loan Number"
                className="sm:mb-[5px] border border-gray-300 rounded-lg  w-ful max-[480px]:w-full p-[10px] text-[17px] outline-none"
                name="mobile"
                id="forminit"
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </div>
            <div className="w-1/2 flex gap-[10px] mt-4 max-[365px]:mt-[20px]">
              <button
                onClick={() => handleSearch(mobileNumber)}
                className="bg-[#0176FF] border border-gray-300 rounded-lg text-white p-[10px]"
              >
                Search
              </button>
            </div>
          </div>

          {/* <div className="my-[5px]">
                  <p className="mb-[5px] font-bold text-[16px]">
                  Loan Number
                  </p>
                  <select
                    placeholder="Loan Number"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                    name="loanno"
                    // value={2}
                    onChange={(e) => {
                      LoanNumber(e.target.value);
                      // onValueUpdate(e);
                    }}
                  >
                    <option value="">--Select Loan Number--</option>
                    {loanNumber.map((option) => (
                      <option
                        className="capitalize"
                        key={"loanno" + option.loanno}
                        value={option.loanno}
                      >
                        {option.loanno}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.loanno}
                  </p>
                </div> */}

        </div>


      </div>

      <form onSubmit={handleSubmit}>
        <div className="">
          <div className="border p-[15px] rounded-xl shadow-lg grid xl:grid-cols-6 sm:grid-cols-3 gap-5 mb-[10px] mt-2">
            <>
              {localStorage.getItem("branch") == "true" && (
                <div className="my-[5px]">
                  <p className="mb-[5px] font-bold text-[16px]">
                    Branch
                    {/* <span className="text-red-500">*</span> */}
                  </p>
                  <select
                    placeholder="branch"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                    name="id_branch"
                    value={selectedLoan.id_branch}
                    onChange={(e) => {
                      setselectedBranch(e.target.value);
                      onValueUpdate(e);
                    }}
                  >
                    <option value="">--Select Branch--</option>
                    {branchList.map((option) => (
                      <option
                        className="capitalize"
                        key={"branch" + option.branch_name}
                        value={option.id}
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {errors.id_branch && <p className="error text-red-500 font-[400] text-[13px]">{errors.id_branch}</p>}
                </div>
              )}

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[16px]">Customer Name</p>
                <p className="capitalize">{loanDetails?.loan_list?.customer_name}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[16px]">Loan No</p>
                <p>{loanDetails?.loan_list?.loanno}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Loan Date</p>
                <p>{loanDetails?.loan_list?.loan_date}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Interst percentage
                </p>
                <p>{loanDetails?.loan_list?.interest_percentage}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Interst / Month
                </p>
                <p>{loanDetails?.loan_list?.interest_amount}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Weight </p>
                <p>{loanDetails?.loan_list?.gross_weight}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Loan Amount </p>
                <p>{loanDetails?.loan_list?.loan_amt}</p>
              </div>
            </>
          </div>

          <div className=" border p-[10px] rounded-xl shadow-lg grid xl:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-5">
            <div className="w-full overflow-hidden">
              <div>
                <p className="font-bold text-[18px] mb-5 mt-5">Item Details</p>
              </div>
<div className="w-full overflow-x-scroll">

              <table className="w-full">
                <thead className="border border-black text-[15px] uppercase font-[700]">
                  <tr>
                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Type
                    </td>
                    <td className="p-[10px] min-w-[250px] border border-black px-4 py-2">
                      Item Name
                    </td>

                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Gwt
                    </td>
                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Swt
                    </td>
                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Nwt
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {loanDetails?.iteam_details?.length === 0 ? (
                    <tr>
                      <td className="text-center border" colSpan={2}>
                        No Data
                      </td>
                    </tr>
                  ) : (
                    loanDetails?.iteam_details?.map((item) => (
                      <tr>
                        <>
                          <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                            {item.metal__metal_name}
                          </td>
                          <td className="p-[10px] min-w-[250px] border border-black px-4 py-2">
                            {item.item__item_print}
                          </td>

                          <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                            {item.gross_weight}
                          </td>
                          <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                            {item.stone_weight}
                          </td>
                          <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                            {item.net_weight}
                          </td>
                        </>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
</div>
            </div>

            {/* <div>
              <div>
                <p className="font-bold text-[18px] mb-5 mt-5">
                  Interst Credit Details
                </p>
              </div>
              <table>
                <thead className="border border-black text-[15px] uppercase font-[700]">
                  <tr>
                    <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                      SNO
                    </td>
                    <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                      Date
                    </td>

                    <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                      Amount
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {loanDetails?.interest_details?.length === 0 ? (
                    <tr>
                      <td className="text-center border" colSpan={4}>
                        No Data
                      </td>
                    </tr>
                  ) : (
                    loanDetails?.interest_details?.map((item, index) => (
                      <tr>
                        <>
                          <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                            {index + 1}
                          </td>
                          <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                            {item.received_date}
                          </td>

                          <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                            {item.received_amount}
                          </td>
                        </>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div> */}
          </div>

          <div className="sm:grid xl:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:gap-5">
            <div className="border p-[15px] rounded-xl shadow-lg grid xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-5 mb-[10px] mt-2">
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px] ">
                  Year Months And Days
                </p>
                <p className="text-green-600">
                  {loanDetails?.loan_list?.year_month_date}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Delivery Date</p>
                <p className="text-green-600">
                  {new Date().toLocaleDateString("en-CA")}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Total Months <span className="text-red-500">*</span>{" "}
                </p>
                <p className="text-green-600">{selectedLoan.total_month}</p>
                {/* <input
                  type="text"
                  placeholder="Total Months"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                  // name="total_month"
                  value={selectedLoan.total_month}
                  onChange={
                    // setTotalMonth(e.target.value);
                    onValueUpdate
                  }
                />
                {errors.total_month && <p className="error text-red-500 font-[400] text-[13px]">{errors.total_month}</p>} */}
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Loan Amount</p>
                <p className="text-green-600">₹{selectedLoan.loan_amt}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Intrest Occured{" "}
                </p>
                <p className="text-green-600">{selectedLoan.interest_occured}</p>
              </div>
              {/* <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Advance Amt </p>
                <p className="text-green-600">₹{advanceAmount}</p>
              </div> */}
              {/* <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Intrest Credit</p>
                <p className="text-green-600">{intrestCredit}</p>
              </div> */}

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Total Amount</p>
                <p className="text-green-600">₹{selectedLoan.total_loanamount}</p>
              </div>


              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Release Charge</p>
                <p className="text-green-600">₹{selectedLoan.release_charge}</p>

              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Net Amount</p>
                <p className="text-green-600">₹{selectedLoan.total_netamount}</p>
              </div>
            </div>

            <div className="border p-[15px] rounded-xl shadow-lg  mb-[10px] mt-2">
              <div className=" h-fit flex  gap-[30px] flex-wrap ">
                {/* <div className="my-[5px] ">
                  <p className="mb-[5px] font-bold text-[15px]">
                    Payment For <span className="text-red-500">*</span>{" "}
                  </p>
                  <select
                    placeholder="Payment For"
                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                    name="paymenttype"
                    value={selectedPaymentType}
                    onChange={(e) => {
                      setSelectedPaymentType(e.target.value);
                      onValueUpdate(e);
                    }}
                  >
                    <option value={""}>--select Payment For--</option>
                    {paymentTypeDropdown?.map((item, index) => (
                      <option key={item.typename} value={item.id}>
                        {item.typename}
                      </option>
                    ))}
                  </select>

                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.entry_date}
                  </p>
                </div> */}

                <div className=" my-[10px] border rounded-md mt-5 max-h-[500px] overflow-y-auto overflow-x-auto">

                  <table className="table-auto w-full">
                    <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                      <tr>
                        <td className="p-[10px]">S.No</td>
                        <td className="p-[10px] min-w-[150px] ">Id</td>
                        <td className="p-[10px] min-w-[150px] ">Loan No</td>
                        <td className="p-[10px] min-w-[180px]">Intimation Charge</td>
                        <td className="p-[10px] min-w-[150px]">Remarks</td>
                        <td className="p-[10px] min-w-[100px] bg-table-head sticky right-0">Delete</td>
                      </tr>
                    </thead>

                    <tbody>
                      {
                        IntimationList.map((item, index) => (
                          <tr key={item.id} className="border">

                            <td className="p-[10px]">{index + 1}</td>
                            <td className="p-[10px] min-w-[150px] ">{item.id}</td>
                            <td className="p-[10px] min-w-[150px] ">{item.loan_no}</td>
                            <td className="p-[10px] min-w-[180px]">{item.intimation_charge}</td>
                            <td className="p-[10px] min-w-[150px]">{item.remarks}</td>
                            <td className="p-[10px] min-w-[100px] bg-white sticky right-0">
                              <div onClick={() => {
                                setIsDeleteOpen(true);
                                setCurrendelete(item);

                              }} className="">
                                <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                              </div>
                            </td>



                          </tr>
                        ))
                      }

                    </tbody>

                  </table>





                </div>




                <div className="flex max-[480px]:block gap-2 max-[656px]:block ">
                  <div className="">
                    <p className="mb-[5px] text-[15px]">Intimation Charge <span className="text-red-500">*</span></p>
                    <input
                      type="text"
                      placeholder="Intimation Charge"
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                      name="intimation_charge"
                      onChange={(e) => {
                        onValueUpdate(e);
                      }}
                    />
                    {errors.intimation_charge && <p className="error text-red-500 font-[400] text-[13px]">{errors.intimation_charge}</p>}
                  </div>
                  <div className="">
                    <p className="mb-[5px] text-[15px]">Remarks</p>
                    <input
                      type="text"
                      placeholder="Remarks"
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                      name="remarks"
                      onChange={(e) => {
                        onValueUpdate(e);
                      }}
                    />
                  </div>
                </div>



                {/* <div>
                  {(selectedPaymentMode == 1 || selectedPaymentMode == 3) && (
                    <div className="my-[5px]">
                      <p className="mb-[5px] font-bold text-[15px]">Cash </p>
                      <div className="">
                        <input
                          type="text"
                          placeholder="Cash"
                          className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                          name="cash"
                          value={cash}
                          onChange={(e) => {
                            {
                              handleCashChange(e);
                              onValueUpdate(e);
                            }
                          }}
                        />

                          {errors.cash && <p className="error text-red-500 font-[400] text-[13px]">{errors.cash}</p>}
                      </div>
                    </div>
                  )}
                  {(selectedPaymentMode == 2 || selectedPaymentMode == 3) && (
                    <div className="my-[5px]">
                      <p className="mb-[5px] font-bold text-[15px]">Bank </p>
                      <div className="">
                        <input
                          type="text"
                          placeholder="Bank"
                          className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                          name="bank"
                          value={bank}
                          onChange={(e) => {
                            {
                              handleBankChange(e);
                              onValueUpdate(e);
                            }
                          }}
                          // onChange={handleBankChange}
                        />

                          {errors.bank && <p className="error text-red-500 font-[400] text-[13px]">{errors.bank}</p>}
                      </div>
                    </div>
                  )}
                </div> */}


              </div>
            </div>
          </div>
        </div>

        <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[10px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
          <button
            id="backnav"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="capitalize mb-[20px] bg-menu-head-bg border text-black w-[200px]  rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "
          >
            close
          </button>

            <button
              type="submit"
              id="formsubmit"
              onClick={(e) => { e.preventDefault(); callApi(false) }}
              className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
            >Save <span className="lowercase">(ctrl+s)</span></button>
            <ReactToPrint
              trigger={() => <button id="print" ref={componentRef} className="capitalize hidden bg-secondary text-black w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none">
                Save & Print (ctrl+p)
              </button>}
              content={() => componentRef}
            />
            <button type="submit"
              id="formsubmit"
              onClick={(e) => { e.preventDefault(); callApi(true) }}
              className="capitalize bg-secondary text-black w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none">
              Save & Print (ctrl+p)
            </button>
          {/* </div> */}


        </div>
      </form>

      <div>
        <Popup title={'Delete Metal'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
          <div className="mt-[10px]">
            <p className="font-400 text-[15px]">Do you want to delete the intimation?</p>
            <div className="mt-[10px] flex gap-3 justify-end items-center">
              <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
              <button type="submit" onClick={() => { deleteintimation() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
            </div>
          </div>
        </Popup>
      </div>
    </div>


  );
}
