import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { RaterangeEndPoint, RaterangeListEndPoint, branchEndPoint, branchListEndPoint, branchStatusEndPoint, interestPercentageEndPoint, intrestlistEndPoint } from "../../../service/api";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import SwitchButton from "../../../components/Switch";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { convertToTime } from "../../../utilities/time";
import IntrestPercentageForm from "./intrestpercentageform";
import IntrestPercentageFilters from "./intrestpercentagefilter";

export default function IntrestPercentage() {

    const searchRef = useRef();

    const [intrestrateList, setIntrestList] = useState([]);
    const [filteredBranchList, setfilteredBranchList] = useState([]);
    const [currentBranch, setCurrentBranch] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        items_per_page: 10,
        page: 1
    });

    const { actions,pagination } = useSelector((state) => state.appConfig);

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getInterestpercentageListData(tableFilters)
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px]'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    const getInterestpercentageListData = async (body) => {
        var response = await postAxiosWithToken({
            url: intrestlistEndPoint,
            body: body
        })

        if (response !== null) {
            setIntrestList(response.data.list)
            setIsLoading(false)
        }
    }

  

    const deletemenuGroup = async () => {
        var response = await deleteAxiosWithToken({
            url: interestPercentageEndPoint + currentBranch.id + '/'
        })

        if (response !== null) {
            getInterestpercentageListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          page: value,
        }));
      };
    
      const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
          ...prevState,
          items_per_page: value,
          page: 1,
        }));
      };

    return (
        <>
            <div>

                {
                    formMode === 'create' ? actions.addPermit ? <div>
                        <IntrestPercentageForm getData={getInterestpercentageListData} formMode={formMode} setFormMode={setFormMode} currentBranch={currentBranch} setCurrentBranch={setCurrentBranch} />
                    </div> : '' : actions.editPermit ? <div>
                        <IntrestPercentageForm getData={getInterestpercentageListData} formMode={formMode} setFormMode={setFormMode} currentBranch={currentBranch} setCurrentBranch={setCurrentBranch} />
                    </div> : ''
                }
                {/* <div>
                    <BranchForm getData={getInterestpercentageListData} formMode={formMode} setFormMode={setFormMode} currentBranch={currentBranch} setCurrentBranch={setCurrentBranch} />
                </div> */}
                <div id="filteroptions" className='my-[5px]'>
                    <p className="font-[600] text-[18px]">Filters</p>

                    <IntrestPercentageFilters searchRef={searchRef} filters={tableFilters} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll mt-5 border rounded-md">
                    <table className="w-full">
                         <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Intrest Percentage</td>
                                <td className="p-[10px] min-w-[150px]">Create Date</td>
                                <td className="p-[10px] min-w-[150px]">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-[#fff]">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    intrestrateList.length === 0 ? <tr><td className="text-center border" colSpan={7}>No Data</td></tr> :
                                    intrestrateList.map((item, index) => (
                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px] capitalize">{item.interest_percent}</td>
                                                <td className="p-[10px] capitalize">
                                                    <p>{convertToDate(item.created_at)}</p>
                                                    <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p></td>
                                               
                                                <td className="p-[10px]">
                                                    <div className="flex gap-3">
                                                        <div onClick={() => {
                                                            if (actions.editPermit) {
                                                                setCurrentBranch(item);
                                                                setFormMode('update');
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div>

                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentBranch(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))

                            }

                        </tbody>
                    </table>
                </div>

                {pagination === true ? 
                <div className="mb-[50px]">
                <Pagination
      totalPages={totalPages}
      currentPage={tableFilters.page}
      setCurrentPage={pageHandler}
      entries={tableFilters.items_per_page}
      entriesHandler={entriesHandler}
      />
            </div>:""}
            </div>
            <div>
                <Popup title={'Delete Rate Range'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentBranch?.interest_percent} Rate Range?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deletemenuGroup() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}