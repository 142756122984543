import React, { useEffect, useState } from "react";
import { TEChart } from "tw-elements-react";
import ApexCharts from "apexcharts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { faker } from "@faker-js/faker";
import DatePicker1 from "../../components/Datepicker";
import Arrow from "../../../src/assets/images/arrowsymbol.svg";

import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import Linechart from "./linechart";
import Barchart from "./barchart";
import ApexChart from "./monthchart";
import ChartComponent from "./piechart";
import { useNavigate } from "react-router-dom";
import CircleChart from "./circlechart";
import { getAxios, postAxiosWithToken } from "../../service/apiservice";
import {
  DashBordCustomerEndPoint,
  DashBordOverDueEndPoint,
  DashBordPaymentPaidEndPoint,
  DashBordPaymentReceivedEndPoint,
  branchListEndPoint,
  deliveryLoanListEndPoint,
  headercountEndPoint,
  loanTableListEndPoint,
  purchaselistEndPoint,
  saleslistEndPoint,
  stocklistEndPoint,
} from "../../service/api";
import Popup from "../../components/Popup";
import Purchaseentrypopup from "./purchaseentrypopup";
import SalesDetailsPopup from "./salesdetails";
import Stockdetailspopup from "./stockdetails";
import { convertToDate } from "../../utilities/datetime";
import { addDays } from "date-fns";
import Dashboardfilter from "./dashboardfilter";
import { useSelector } from "react-redux";
import ApexChart2 from "./monthchart2";
import Pagination from "../../components/Pagination";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const chartConfig = {
  type: "bar",
  height: 240,
  series: [
    {
      name: "Sales",
      data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
    },
  ],
  options: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    title: {
      show: "",
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#61A375"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        borderRadius: 2,
      },
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          colors: "#616161",
          fontSize: "12px",
          fontFamily: "inherit",
          fontWeight: 400,
        },
      },
      categories: [
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      labels: {
        style: {
          colors: "#616161",
          fontSize: "12px",
          fontFamily: "inherit",
          fontWeight: 400,
        },
      },
    },
    grid: {
      show: true,
      borderColor: "#dddddd",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 5,
        right: 20,
      },
    },
    fill: {
      opacity: 0.8,
    },
    tooltip: {
      theme: "dark",
    },
  },
};

export function Dashboard() {
  const navigate = useNavigate();

  const [headercount, setHeadercount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseentrypopup, setPurchaseentryPopup] = useState(false);
  const [salesdetailspopup, setSalesdetailsPopup] = useState(false);
  const [stockdetailspopup, setStockdetailsPopup] = useState(false);

  const [loantable, setLoanTable] = useState([]);
  const [deliveryloantable, setDeliveryLoanTable] = useState([]);
  const [overdueTable, setOverdueTable] = useState([]);
  const [paymentTable, setPaymentTable] = useState([]);
  const [paymentReceivedTable, setPaymentReceivedTable] = useState([]);
  const [customerTable, setCustomerTable] = useState([]);


  const overduesValue = headercount?.overdues || 0;
  const paymentPaidValue = headercount?.payment_paid || 0;
  const payment_receivedValue = headercount?.payment_received || 0;
  const customerValue = headercount?.customer || 0;

  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });

  const { addT, now,pagination } = useSelector((state) => state.appConfig);

  const [totalPages, setTotalPages] = useState(10);

  const [tableFilters, setTableFilters] = useState({
    from_date: null,
    to_date: null,
    branch: localStorage.getItem("branch") == "true" ? null : undefined,
  });
  const [tablePagenationFilters, setTablePagenationFilters] = useState({
    from_date: null,
    to_date: null,
    branch: localStorage.getItem("branch") == "true" ? null : undefined,
    items_per_page: JSON.parse(localStorage.getItem("pagination")) ? 10 : null,
    page: 1,
  });


  const[cartlists , setCartLists] = useState({
    branch: localStorage.getItem("branch") == "true" ? null : undefined,

  })

  useEffect(() => {
    setTableFilters(tableFilters);
  }, [tableFilters]);

  useEffect(() => {
    setTablePagenationFilters(tablePagenationFilters);
  }, [tablePagenationFilters]);

  useEffect(() => {
    setCartLists(cartlists);
  }, [cartlists]);

  const closePurchaseentry = () => {
    setPurchaseentryPopup(!purchaseentrypopup);
  };

  const closeSalesDetails = () => {
    setSalesdetailsPopup(!salesdetailspopup);
  };

  const closeStockDetails = () => {
    setStockdetailsPopup(!stockdetailspopup);
  };

  useEffect(() => {
    getHeaderData(tableFilters);
    getDeliveryLoanTableData(tableFilters);
  }, [tableFilters]);


  useEffect(()=>{
    getLoanTableData(tablePagenationFilters);
    getDeliveryLoanTableData(tablePagenationFilters);
  },[tablePagenationFilters])



  useEffect(() => {
    getOverdueTableData(tableFilters)
    getPaymentTableData(tableFilters)
    getPaymentReceivedTableData(tableFilters)
    getCustomerTableData(tableFilters)
  },[tableFilters])


  const getHeaderData = async (body) => {
    var response = await postAxiosWithToken({
      url: headercountEndPoint,
      body: body,
    });

    if (response !== null) {
      setHeadercount(response.data.list);
      setIsLoading(false);
    }
  };
  const getLoanTableData = async (body) => {
    var response = await postAxiosWithToken({
      url: loanTableListEndPoint,
      body: body,
    });

    if (response !== null) {
      setLoanTable(response.data.list);
      setIsLoading(false);
      setTotalPages(response.data.total_pages);
    }
  };
  const getDeliveryLoanTableData = async (body) => {
    var response = await postAxiosWithToken({
      url: deliveryLoanListEndPoint,
      body: body,
    });

    if (response !== null) {
      setDeliveryLoanTable(response.data.list);
      setTotalPages(response.data.total_pages);
      setIsLoading(false);
    }
  };
  const getOverdueTableData = async (body) => {
    var response = await postAxiosWithToken({
      url: DashBordOverDueEndPoint,
      body: body,
    });

    if (response !== null) {
      setOverdueTable(response.data.list);
      // setIsLoading(false);
    }
  };
  const getPaymentTableData = async (body) => {
    var response = await postAxiosWithToken({
      url: DashBordPaymentPaidEndPoint,
      body: body,
    });

    if (response !== null) {
      setPaymentTable(response.data.list);
      // setIsLoading(false);
    }
  };
  const getPaymentReceivedTableData = async (body) => {
    var response = await postAxiosWithToken({
      url: DashBordPaymentReceivedEndPoint,
      body: body,
    });

    if (response !== null) {
      setPaymentReceivedTable(response.data.list);
      // setIsLoading(false);
    }
  };
  const getCustomerTableData = async (body) => {
    var response = await postAxiosWithToken({
      url: DashBordCustomerEndPoint,
      body: body,
    });

    if (response !== null) {
      setCustomerTable(response.data.list);
      // setIsLoading(false);
    }
  };





  
  const pageHandler = (value) => {
    setTablePagenationFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setTablePagenationFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };



  const pageFilter = (value) => {
    setTablePagenationFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesFilter = (value) => {
    setTablePagenationFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };









  return (
    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
      <div className="md:flex justify-between p-3">
        <div className="text-[20px] font-[700] my-auto">Overview</div>
        <div>
          <Dashboardfilter
            filters={tableFilters}
            setFilters={setTableFilters}
          />
        </div>
        {/* <div>
                    {localStorage.getItem("branch") === "true" &&
                        <div className=''>
                            <select
                                placeholder="metal_type"
                                className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full sm:w-[300px] p-[11px] text-[17px] outline-none"
                                name="metal_type"
                                // value={classification.classification_name} 
                                onChange={(e) => handleBranchFilter(e.target.value)}
                            >
                                <option value="all">All Branch</option>
                                {branchList?.map((option) => (
                                    <option key={option?.id} value={option?.id}>
                                        {option?.branch_name}
                                    </option>
                                ))}
                            </select>
                        </div>}
                    <div className="w-[225px] border border-gray-300 rounded">
                        <DatePicker1 handleValueChange={handleDateFilter} value={value}
                        />
                    </div>
                </div> */}
      </div>

      <div className="w-full">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
          <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between  border border-[#61A375] items-center  ">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="green"
                  d="M3 6h18v12H3zm9 3a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3M7 8a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2v-4a2 2 0 0 1-2-2z"
                />
              </svg>
              <div className="flex gap-2 w-[125px]">
                <h3 class="font-[500] text-black text-[14px]">Over Dues</h3>
                <div className="">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                </div>
              </div>
              <p class=" text-[15px] mt-1 text-green-800">{headercount.overdues}</p>
              {/* <p class="text-black text-[12px] mt-1">Today's</p> */}
            </div>
            <div>
              <CircleChart
                color={["#166622"]}
                value={[overduesValue]}
              />
            </div>
          </div>

          <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between  border border-[#F1C837] items-center ">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
              >
                <g fill="none" stroke="orange" stroke-width="1.5">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m2 11l2.807-3.157A4 4 0 0 1 7.797 6.5H8m-6 13h5.5l4-3s.81-.547 2-1.5c2.5-2 0-5.166-2.5-3.5C8.964 12.857 7 14 7 14"
                  />
                  <path d="M8 13.5V7a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2h-6.5" />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a2 2 0 1 1 0-4a2 2 0 0 1 0 4m4.5-1.99l.01-.011m-9.01.011l.01-.011"
                  />
                </g>
              </svg>
              <div className="flex gap-2 w-[150px]">
                <h3 class="font-[500] text-black text-[14px]">Payments Paid</h3>
                <div className="">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                </div>
              </div>
              <p class="text-[#F1C837] text-[15px] mt-1">
                {parseFloat(headercount.payment_paid)?.toFixed(2)}
              </p>
              {/* <p class="text-black text-[12px] mt-1">Today's</p> */}
            </div>

            <div>
              <CircleChart color={["#F1C837"]} value={[paymentPaidValue]} />
            </div>
          </div>

          <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between border border-[#61A375] items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="green"
                  d="M3 6v12h10.32a6.4 6.4 0 0 1-.32-2H7a2 2 0 0 0-2-2v-4c1.11 0 2-.89 2-2h10a2 2 0 0 0 2 2v.06c.67 0 1.34.12 2 .34V6zm9 3c-1.7.03-3 1.3-3 3s1.3 2.94 3 3c.38 0 .77-.08 1.14-.23c.27-1.1.72-2.14 1.83-3.16C14.85 10.28 13.59 8.97 12 9m9.63 3.27l-3.87 3.9l-1.35-1.37L15 16.22L17.75 19l5.28-5.32z"
                />
              </svg>
              <div className="flex gap-2 w-[125px]">
                <h3 class="font-[500] text-black text-[14px]">
                  Payment Received
                </h3>
                <div className="">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                </div>
              </div>
              <p class="text-green-800 text-[15px] mt-1">{headercount.payment_received?.toFixed(2)}</p>
              {/* <p class="text-black text-[12px] mt-1">Today's</p> */}
            </div>

            <div>
              <CircleChart color={["#166622"]} value={[payment_receivedValue]} />
            </div>
          </div>

          <div class="shadow-lg px-5 py-1 m-2 rounded-[10px] flex justify-between  border border-[#F1C837] items-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                viewBox="0 0 32 32"
              >
                <path
                  fill="orange"
                  d="M26 30h-2v-5a5.006 5.006 0 0 0-5-5h-6a5.006 5.006 0 0 0-5 5v5H6v-5a7.01 7.01 0 0 1 7-7h6a7.01 7.01 0 0 1 7 7zM22 6v4c0 1.103-.897 2-2 2h-1a1 1 0 0 0 0 2h1c2.206 0 4-1.794 4-4V6zm-6 10c-3.86 0-7-3.14-7-7s3.14-7 7-7c1.989 0 3.89.85 5.217 2.333l-1.49 1.334A5 5 0 0 0 16 4c-2.757 0-5 2.243-5 5s2.243 5 5 5z"
                />
              </svg>
              <div className="flex gap-2 w-[150px]">
                <h3 class="font-[500] text-black text-[14px]">Customer</h3>
                <div className="">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256" transform="rotate(40)"><path fill="currentColor" d="M202.83 114.83a4 4 0 0 1-5.66 0L132 49.66V216a4 4 0 0 1-8 0V49.66l-65.17 65.17a4 4 0 0 1-5.66-5.66l72-72a4 4 0 0 1 5.66 0l72 72a4 4 0 0 1 0 5.66" /></svg> */}
                </div>
              </div>
              <p class="text-[#F1C837] text-[15px] mt-1">{headercount.customer}</p>
              {/* <p class="text-black text-[12px] mt-1">Today's</p> */}
            </div>

            <div>
              <CircleChart color={["#F1C837"]} value={[customerValue]} />
            </div>
          </div>
        </div>
      </div>

      <div className="grid xl-grid-cols-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 px-1 py-6 text-gray-700">
        <div className="border border-green-800 rounded-[4px] p-4">
          <div className="flex justify-between border-b border-green-800 py-2">
            <p>Over Dues</p>
            <div className="bg-green-800 rounded-[10px] px-2">
            <p className="text-white">{overdueTable.total}</p>
            </div>
          </div>
          <div className="flex justify-between border-b border-green-800 py-2">
            <p>Types</p>
            <p >Dues</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Today</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{overdueTable.overdues_today}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>Yesterday</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{overdueTable.overdues_yesterday}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Week</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{overdueTable.overdues_week}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Month</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{overdueTable.overdues_month}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Year</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{overdueTable.overdues_year}</p>
             </div>
          </div>
        </div>


        <div className="border border-orange-600 rounded-[4px] p-4">
          <div className="flex justify-between border-b border-orange-600 py-2">
            <p>Payments Paid</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{paymentTable.total?.toFixed(2)}</p>
            </div>
          </div>
          <div className="flex justify-between border-b border-orange-600 py-2">
            <p>Types</p>
            <p >Dues</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Today</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
             <p className="text-white">{paymentTable.payment_today?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>Yesterday</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
             <p className="text-white">{paymentTable.payment_yesterday?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Week</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
             <p className="text-white">{paymentTable.payment_week?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Month</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
             <p className="text-white">{paymentTable.payment_month?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Year</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
             <p className="text-white">{paymentTable.payment_year?.toFixed(2)}</p>
             </div>
          </div>
          
        </div>



        <div className="border border-green-800 rounded-[4px] p-4">
          <div className="flex justify-between border-b border-green-800 py-2">
            <p>Payment Received</p>
            <div className="bg-green-800 rounded-[10px] px-2">
            <p className="text-white">{paymentReceivedTable.total?.toFixed(2)}</p>
            </div>
          </div>
          <div className="flex justify-between border-b border-green-800 py-2">
            <p>Types</p>
            <p >Dues</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Today</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{paymentReceivedTable.paymentreceived_today?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>Yesterday</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{paymentReceivedTable.paymentreceived_yesterday?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Week</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{paymentReceivedTable.paymentreceived_week?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Month</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{paymentReceivedTable.paymentreceived_month?.toFixed(2)}</p>
             </div>
          </div>
          <div className="flex justify-between py-2">
            <p>This Year</p>
            <div className="bg-green-800 rounded-[10px] px-2">
             <p className="text-white">{paymentReceivedTable.paymentreceived_year?.toFixed(2)}</p>
             </div>
          </div>
         
        </div>


        <div className="border border-orange-600 rounded-[4px] p-4">
          <div className="flex justify-between border-b border-orange-600 py-2">
            <p>Customer</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{customerTable.total}</p>
            </div>
          </div>
          <div className="flex justify-between border-b border-orange-600 py-2">
            <p>Types</p>
            <p >Dues</p>
          </div>
          <div className="flex justify-between py-2">
            <p>Today</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{customerTable.customer_today}</p>
            </div>
        </div>
          <div className="flex justify-between py-2">
            <p>Yesterday</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{customerTable.customer_yesterday}</p>
            </div>
        </div>
          <div className="flex justify-between py-2">
            <p>This Week</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{customerTable.customer_week}</p>
            </div>
        </div>
          <div className="flex justify-between py-2">
            <p>This Month</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{customerTable.customer_month}</p>
            </div>
        </div>
          <div className="flex justify-between py-2">
            <p>This Year</p>
            <div className="bg-orange-600 rounded-[10px] px-2">
            <p className="text-white">{customerTable.customer_year}</p>
            </div>
        </div>
        </div>
      </div>


      <div className="grid xl-grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 py-6 text-gray-700">
      <div className=" bg-white border border-[#D9D9D9] rounded-lg  max-w-full overflow-hidden">
        <div>
          <div className="flex justify-between p-5 ">
            <div className="font-[700] text-[18px]">Loans List</div>

          </div>

          <div className="p-4 border-t">
            <div class="shadow-md overflow-x-scroll sm:rounded-lg mt-[22px] border">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead class="bg-[#F1F5F9] text-xs text-gray-700 uppercase  dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                      Loan No
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                      phone
                    </th>
                    <th scope="col" class="px-6 py-3">
                      <div class="flex items-center">
                        Loan Amt
                       
                      </div>
                    </th>
                    
                  </tr>
                </thead>
                {loantable.length===0?<tr>
                  <td className="text-center border" colSpan={7}>
                    No Data
                  </td>
                </tr>: loantable.map((item) => (
                  <tbody className="overflow-scroll w-full">
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-[#3F80CC] whitespace-nowrap dark:text-white"
                      >
                        {item.loanno}
                      </th>
                      <td class="px-6 py-4">{item.customer_name}</td>
                      <td class="px-6 py-4">{item.mobile}</td>
                      <td class="px-6 py-4">{item.distribute_amt?.toFixed(2)}</td>
                    
                    </tr>
                  </tbody>
               ))} 
              </table>
              </div>
              {pagination === true ? (
          <div className="p-3 mt-2">
            <Pagination
              totalPages={totalPages}
              currentPage={tablePagenationFilters.page}
              setCurrentPage={pageFilter}
              entries={tablePagenationFilters.items_per_page}
              entriesHandler={entriesFilter}
            />
          </div>
        ) : (
          ""
        )}
            
          </div>
          </div>
        </div>
        <div className=" bg-white border  border-[#D9D9D9] rounded-lg  max-w-full overflow-hidden">
          <div className="flex justify-between p-5 ">
            <div className="font-[700] text-[18px]">Delivery List</div>

          </div>

          <div className="p-4 border-t">
            <div class="shadow-md overflow-x-scroll sm:rounded-lg mt-[22px] border">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                <thead class="bg-[#F1F5F9] text-xs text-gray-700 uppercase  dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                  <tr>
                    <th scope="col" class="px-6 py-3">
                    Customer
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Phone
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Loan Amt
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Int
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Received Date
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Int Amt
                    </th>
                    <th scope="col" class="px-6 py-3">
                    Total Amt
                    </th>
                   
                
                  </tr>
                </thead>
                {deliveryloantable.length ===0? 
                <tr>
                  <td className="text-center border" colSpan={7}>
                    No Data
                  </td>
                </tr>: deliveryloantable.map((item) => (
                  <tbody className="overflow-scroll w-full">
                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-[#3F80CC] whitespace-nowrap dark:text-white"
                      >
                        {item.customer_name}
                      </th>
                      <td class="px-6 py-4">{item.mobile}</td>
                      <td class="px-6 py-4">{item.distribute_amt?.toFixed(2)}</td>
                      <td class="px-6 py-4">{item.interest_percentage}</td>
                      <td class="px-6 py-4">{item.received_date}</td>
                      <td class="px-6 py-4">{item.interest_amount}</td>
                      <td class="px-6 py-4">{item.total_value?.toFixed(2)}</td>
                   
                    </tr>
                  </tbody>
               ))} 
              </table>
              
            </div>
          </div>
          {pagination === true ? (
          <div className="p-2 mt-2">
            <Pagination
              totalPages={totalPages}
              currentPage={tablePagenationFilters.page}
              setCurrentPage={pageHandler}
              entries={tablePagenationFilters.items_per_page}
              entriesHandler={entriesHandler}
            />
          </div>
        ) : (
          ""
        )}
        </div>
      </div>




    

      <div className="">
        <Popup
          title={` Puchase Details`}
          setIsOpen={setPurchaseentryPopup}
          isOpen={purchaseentrypopup}
          closeModal={closePurchaseentry}
        >
          <Purchaseentrypopup closeModal={closePurchaseentry} />
        </Popup>
      </div>

      <div className="">
        <Popup
          title={` Sales Details`}
          setIsOpen={setSalesdetailsPopup}
          isOpen={salesdetailspopup}
          closeModal={closeSalesDetails}
        >
          <SalesDetailsPopup closeModal={closeSalesDetails} />
        </Popup>
      </div>

      <div className="">
        <Popup
          title={`Stock Details`}
          setIsOpen={setStockdetailsPopup}
          isOpen={stockdetailspopup}
          closeModal={closeStockDetails}
        >
          <Stockdetailspopup closeModal={closeStockDetails} />
        </Popup>
      </div>
    </div>
  );
}
