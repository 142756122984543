// export const baseUrl = 'http://127.0.0.1:8000';
// export const baseUrl = 'http://192.168.1.85:8001';
// export const baseUrl = 'http://13.49.168.229:8011';
export const baseUrl = 'https://api.soletideva.pb.auss.co';

export const loginEndPoint = baseUrl + '/accounts/login/';
export const logoutEndPoint = baseUrl + '/accounts/logout/';
export const changePasswordEndPoint = baseUrl + '/accounts/change-password/';

export const menuPermissionEndPoint = baseUrl + '/settings/menu-permission/';
export const activeUserPermissionEndPoint = baseUrl + '/settings/active-user-menu-permission/';
export const gstVerifyEndPoint = baseUrl + '/settings/gst-verification/';
export const ifscVerifyEndPoint = baseUrl + '/settings/ifsc-verification/';

export const userRoleListEndPoint = baseUrl + '/settings/user-role-list/';
export const userRoleEndPoint = baseUrl + '/settings/user-role/';
export const userRoleStatusEndPoint = baseUrl + '/settings/change-user-role-status/';
export const userRoleAdminStatusEndPoint = baseUrl + '/settings/change-user-role-admin-status/';

export const menuListEndPoint = baseUrl + '/settings/menu-list/';
export const menuEndPoint = baseUrl + '/settings/menu/';
export const menuStatusEndPoint = baseUrl + '/settings/change-menu-status/';

export const menuGroupListEndPoint = baseUrl + '/settings/menu-group-list/';
export const menuGroupEndPoint = baseUrl + '/settings/menu-group/';
export const menuGroupStatusEndPoint = baseUrl + '/settings/change-menu-group-status/';

export const locationListEndPoint = baseUrl + '/organizations/location-list/';
export const locationEndPoint = baseUrl + '/organizations/location/';
export const locationStatusEndPoint = baseUrl + '/organizations/change-location-status/';

export const branchListEndPoint = baseUrl + '/organizations/branch-list/';
export const branchEndPoint = baseUrl + '/organizations/branch/';
export const branchStatusEndPoint = baseUrl + '/organizations/change-branch-status/';

export const departmentListEndPoint = baseUrl + '/organizations/department-list/';
export const departmentEndPoint = baseUrl + '/organizations/department/';
export const departmentStatusEndPoint = baseUrl + '/organizations/change-department-status/';

export const designationListEndPoint = baseUrl + '/organizations/designation-list/';
export const designationEndPoint = baseUrl + '/organizations/designation/';
export const designationStatusEndPoint = baseUrl + '/organizations/change-designation-status/';

export const staffListEndPoint = baseUrl + '/organizations/staff-list/';
export const staffEndPoint = baseUrl + '/organizations/staff/';
export const staffStatusEndPoint = baseUrl + '/organizations/change-staff-status/';
export const staffListByBranchEndPoint = baseUrl + "/organizations/staff-branch-list/";

export const userListEndPoint = baseUrl + '/organizations/user-list/';
export const userEndPoint = baseUrl + '/organizations/user/';
export const userStatusEndPoint = baseUrl + '/organizations/change-user-status/';


export const metalEndPoint = baseUrl + '/masters/metal/';
export const metalStatusEndPoint = baseUrl + '/masters/change-metal-status/';
export const metaltypeListEndPoint = baseUrl + '/masters/tag-type/';


export const purityListEndPoint = baseUrl + '/masters/purity-list/';
export const purityEndPoint = baseUrl + '/masters/purity/';
export const purityStatusEndPoint = baseUrl + '/masters/change-purity-status/';
export const purityVisibleStatusEndPoint = baseUrl + '/masters/change-purity-visible/';

export const metalRatesListEndPoint = baseUrl + '/masters/metal-rates-list/';
export const metalRatesEndPoint = baseUrl + '/masters/metal-rates/';
export const metalRatesStatusEndPoint = baseUrl + '/masters/change-metal-rates-status/';
export const metalPuritybasedRateEndPoint = baseUrl + '/masters/metal-purity-based-rate/';

export const floorListEndPoint = baseUrl + '/infrastructure/floor-list/';
export const floorEndPoint = baseUrl + '/infrastructure/floor/';
export const floorStatusEndPoint = baseUrl + '/infrastructure/change-floor-status/';

export const counterListEndPoint = baseUrl + '/infrastructure/counter-list/';
export const counterEndPoint = baseUrl + '/infrastructure/counter/';
export const counterStatusEndPoint = baseUrl + '/infrastructure/change-counter-status/';

export const companyListEndPoint = baseUrl + '/books/company-list/';
export const companyEndPoint = baseUrl + '/books/company/';
export const companyStatusEndPoint = baseUrl + '/books/change-company-status/';
export const companyBankMultiDeleteEndPoint = baseUrl + '/books/company-bank-delete-list/';

export const groupLedgerListEndPoint = baseUrl + '/books/group-ledger-list/';

export const groupTypeListEndPoint = baseUrl + '/books/group-type-list/';

export const accountGoupListEndPoint = baseUrl + '/books/account-group-list/';
export const accountGroupEndPoint = baseUrl + '/books/account-group/';
export const accountGroupStatusEndPoint = baseUrl + '/books/change-account-group-status/';

export const tagListEndPoint = baseUrl + '/masters/tag-type-list/';
export const tagEndPoint = baseUrl + '/masters/tag-type/';
export const tagStatusEndPoint = baseUrl + '/masters/change-tag-type-status/';

export const taxListEndPoint = baseUrl + '/masters/tax-list/';
export const taxEndPoint = baseUrl + '/masters/tax/';
export const taxStatusEndPoint = baseUrl + '/masters/change-tax-status/';

export const caratrateListEndPoint = baseUrl + '/masters/carat-rate-list/';
export const caratrateEndPoint = baseUrl + '/masters/carat-rate/';
export const caratrateStatusEndPoint = baseUrl + '/masters/carat-rate-status/';

export const shapeListEndPoint = baseUrl + '/masters/shape-list/';
export const shapeEndPoint = baseUrl + '/masters/shape/';
export const shapeStatusEndPoint = baseUrl + '/masters/change-shape-status/';

export const cutListEndPoint = baseUrl + '/masters/cut-list/';
export const cutEndPoint = baseUrl + '/masters/cut/';
export const cutStatusEndPoint = baseUrl + '/masters/change-cut-status/';

export const clarityListEndPoint = baseUrl + '/masters/clarity-list/';
export const clarityEndPoint = baseUrl + '/masters/clarity/';
export const clarityStatusEndPoint = baseUrl + '/masters/change-clarity-status/';

export const colorListEndPoint = baseUrl + '/masters/color-list/';
export const colorEndPoint = baseUrl + '/masters/color/';
export const colorStatusEndPoint = baseUrl + '/masters/change-color-status/';

export const stoneListEndPoint = baseUrl + '/masters/stone-list/';
export const stoneEndPoint = baseUrl + '/masters/stone/';
export const stoneStatusEndPoint = baseUrl + '/masters/change-stone-status/';

export const centgroupListEndPoint = baseUrl + '/masters/cent-group-list/';
export const createcentgroupEndPoint = baseUrl + '/masters/cent-group/';
export const centgropStatusEndPoint = baseUrl + '/masters/change-cent-group-status/';

export const rangestockListEndPoint = baseUrl + '/masters/range-stock-list/';
export const createrangestockEndPoint = baseUrl + '/masters/range-stock/';  
export const rangestockStatusEndPoint = baseUrl + '/masters/change-range-stock-status/';
export const rangestockReportEndPoint = baseUrl + '/masters/range-stock-report/';

export const accountheadListEndPoint = baseUrl + '/books/account-head-list/';
export const accountheadEndPoint = baseUrl + '/books/account-head/';  
export const accountheadStatusEndPoint = baseUrl + '/books/change-account-head-status/';
export const accountheadBankMultiDeleteEndPoint = baseUrl + '/books/account-head-delete-bank/';
export const accountheadAddressMultiDeleteEndPoint = baseUrl + '/books/account-head-delete-address/';
export const accountheadContactMultiDeleteEndPoint = baseUrl + '/books/account-head-delete-contact/';

export const customertypeListEndPoint = baseUrl + '/books/customer-type-list/';
export const accounttypeListEndPoint = baseUrl + '/books/account-type-list/';
export const groupnameListEndPoint = baseUrl + '/books/account-group-list/';

export const itemMasterListEndPoint = baseUrl + '/product/item-list/';




export const customerByMobileEndPoint = baseUrl + '/customer/customer-mobile-search/'

export const customerGroupListEndPoint = baseUrl + '/customer/customer-group-list/';
export const customerGroupEndPoint = baseUrl + '/customer/customer-group/';
export const customerGroupStatusEndPoint = baseUrl + '/customer/customer-group-status/';

export const SingleTagUpdateEndPoint = baseUrl + '/tagging/single-tag-update/';



export const AdvancePaymentListEndPoint = baseUrl + '/advance-payment/advance-payment-list/';
export const AdvancePurposeEndPoint = baseUrl + '/advance-payment/advance-purpose/';
export const AdvancepaymentEndPoint = baseUrl + '/advance-payment/advancepayment/';
export const AdvancePaaymentIdEndPoint = baseUrl + '/advance-payment/advance-number-autogenerate/';






export const ApprovalTypeListEndPoint = baseUrl + '/approval/approval-type-list/';
export const ApprovalTypeEndPoint = baseUrl + '/approval/approval-type/';
export const ApprovalTypeStatusEndPoint = baseUrl + '/approval/approval-type-status/';

export const ApprovalruleListEndPoint = baseUrl + '/approval/approval-rule-list/'
export const ApprovalruleEndPoint = baseUrl + '/approval/approval-rule/'
export const GetApprovalruleEndPoint = baseUrl + '/organizations/user-list-by-userrole/'
export const GetApprovalruleStatusEndPoint = baseUrl + '/approval/approval-rule-status/'




export const StatusdropdownByStatusEndPoint = baseUrl + "/settings/status-list/";
export const CustomerViewListEndPoint = baseUrl + "/reports/viewcustomerloan/";

export const ConfigurationListEndPoint = baseUrl + "/settings/configuration-list/";
export const UpdateConfiguration = baseUrl + "/settings/configuration/";









// vendor due
export const generateVendorPaymentId = baseUrl + '/purchase/vendorpayment-id/';
export const vendorDetailsByIdEndPoint = baseUrl + '/purchase/getvendordetail/';
export const vendorDueListEndPoint = baseUrl + '/purchase/vendor-list/';
export const amountRateCutEndPoint = baseUrl + '/purchase/amount-rate-cut/';
export const metalRateCutEndPoint = baseUrl + '/purchase/metal-rate-cut/';
export const cashRateCutEndPoint = baseUrl + '/purchase/cash-rate-cut/';

export const customerReciptListEndPoint = baseUrl + '/repair-management/customer-receipt-list/'
export const customerReciptIdEndPoint = baseUrl + '/repair-management/customer-repair-receipt-id/'
export const customerReciptDetailsEndPoint = baseUrl + '/repair-management/customer-receipt/'





export const billingUpdateEndPoint = baseUrl + '/billing/sale-bill-update/'
export const headercountEndPoint = baseUrl + '/dashboard/dashboard-count/'
export const totalpaymentEndPoint = baseUrl + '/dashboard/total-payment/'
export const metalwisesalesEndPoint = baseUrl + '/dashboard/metal_wise_sales/'
export const purchasevssalesEndPoint = baseUrl + '/dashboard/salesvspurchase-list/'
export const purchaseDashboardEndPoint = baseUrl + '/purchase/purchaseentry/'
export const salesdetailsEndPoint = baseUrl + '/billing/sale-bill/'
export const stockdetailsEndPoint = baseUrl + '/product/item/'
export const vendorwiseEndPoint = baseUrl + '/dashboard/vendorwise-list/'





// customer
export const stateDropdownListEndPoint = baseUrl + '/organizations/state-list/'
export const cityDropdownListEndPoint = baseUrl + '/organizations/city-list/'
export const townDropdownListEndPoint = baseUrl + '/organizations/town-list/'
export const villageDropdownListEndPoint = baseUrl + '/organizations/village-list/'

export const relationshipDropdownListEndPoint = baseUrl + '/settings/relationship-list/'
export const genterDropdownListEndPoint = baseUrl + '/settings/gender-list/' 
export const customerEndPoint = baseUrl + '/management/customer/' 
export const customerListEndPoint = baseUrl + '/management/customer-list/' 

// newfirm

export const newFirmListEndPoint = baseUrl + '/management/firm-list/' 
export const newFirmEndPoint = baseUrl + '/management/firm/' 

// item

export const ItemListEndPoint = baseUrl + '/management/items-list/' 
export const ItemEndPoint = baseUrl + '/management/items/';
export const metalListEndPoint = baseUrl + '/masters/metal-list/';

export const ItemMetalListEndPoint = baseUrl + '/management/metalitems-list/' 



// loan
export const loanTableListEndPoint = baseUrl + '/loanmanagement/manageloan-list/';
export const loanEndPoint = baseUrl + '/loanmanagement/manageloan/';
export const customerMobilenumberEndPoint = baseUrl + '/management/customer-mobile-search/';
export const loanIdEndPoint = baseUrl + '/loanmanagement/loan-id/';
export const todayMetalRatesEndPoint = baseUrl + '/masters/today-metal-rate/';


export const towndropDownEndPoint = baseUrl + '/organizations/town-list/';
export const villagedropDownEndPoint = baseUrl + '/organizations/village-list/';

export const paymentModedropDownEndPoint = baseUrl + '/settings/payment-mode/';


// delivery loan

export const deliveryLoanListEndPoint = baseUrl + '/deliverymangment/deliveryloan-list/';
export const searchMobileNumberEndPoint = baseUrl + '/loanmanagement/searchmobileloanno/';
export const searchLoanNumberEndPoint = baseUrl + '/deliverymangment/searchloannumber/';
export const paymentTypeDropDownEndPoint = baseUrl + '/settings/payment-type/';
export const deliveryEndPoint = baseUrl + '/deliverymangment/deliveryloan/';


// export const metalrateEndPoint = baseUrl + '/masters/metalrate/';


// manageinterest

export const manageIntrestListEndPoint = baseUrl + '/deliverymangment/interest-list/';
export const manageIntrestEndPoint = baseUrl + '/deliverymangment/deleteinterest/';


// reports

export const customerReportsListEndPoint = baseUrl + '/reports/customer-list/';
export const customerReportsViewEndPoint = baseUrl + '/reports/viewcustomermobileloan/';
export const transactionListEndPoint = baseUrl + '/reports/cashandbank-list/';
export const principleListEndPoint = baseUrl + '/reports/principleamount-list/';
export const deliveryReportsListEndPoint = baseUrl + '/deliverymangment/deliveryloan-list/';
export const daywiseReportsListEndPoint = baseUrl + '/reports/daywise-interestlist/';
export const interestReportsListEndPoint = baseUrl + '/reports/interest-list/';
export const overdueReportsListEndPoint = baseUrl + '/reports/overdue-list/';
export const loassItemReportsListEndPoint = baseUrl + '/reports/lossitem-list/';
export const DayBookReportsListEndPoint = baseUrl + '/reports/daybook-list/';
export const PrincipleSummaryReportsEndPoint = baseUrl + '/reports/principle-summary-list/';
export const MonthlyReportsEndPoint = baseUrl + '/reports/monthwise-summary-report/';
export const LoanSummaryReportsEndPoint = baseUrl + '/reports/loan-summary-report/';


export const DeliverymangmentSummaryReportsEndPoint = baseUrl + '/deliverymangment/paymenttype-list/';



// rateRange

export const RaterangeListEndPoint = baseUrl + '/management/raterange-list/';
export const RaterangeEndPoint = baseUrl + '/management/raterange/';

// remarks
export const RemarksdropDownEndPoint = baseUrl + '/loanmanagement/metal-remarks-list/';
export const RemarksListEndPoint = baseUrl + '/loanmanagement/metal-remarks/';

// dasbord

export const DashBordOverDueEndPoint = baseUrl + '/dashboard/over-due/';
export const DashBordPaymentPaidEndPoint = baseUrl + '/dashboard/payment-paid/';
export const DashBordPaymentReceivedEndPoint = baseUrl + '/dashboard/payment-received/';
export const DashBordCustomerEndPoint = baseUrl + '/dashboard/customer/';

export const CustomerStatusEndPoint = baseUrl + '/management/customer-activestatus/';
export const StatusEndPoint = baseUrl + '/settings/loanstatus-list/';


export const printtypeDropdownListEndPoint = baseUrl + '/settings/print-type-list/';
export const ItemslistReportsListEndPoint = baseUrl + '/reports/items-list/';
export const PaymentModeTransactionReportListEndPoint = baseUrl + '/reports/payment-mode-transaction/';


// manageOverdue

export const manageOverDueListEndPoint = baseUrl + '/reports/manageoverdue-list/';
export const manageOverDueMobileListEndPoint = baseUrl + '/management/searchmobileloan/';
export const manageOverDueLoanListEndPoint = baseUrl + '/management/searchoverdueloan/';


// payment mode

export const paymentModeListEndPoint = baseUrl + '/settings/payment-mode/';
export const paymentModeCreateEndPoint = baseUrl + '/settings/paymentmode/';
export const paymentModeStatusEndPoint = baseUrl + '/settings/payment-mode-status/';



export const manageoverdueEndPoint = baseUrl + '/loanmanagement/intimationcharge/';


export const intimationgetlistEndPoint = baseUrl + '/loanmanagement/initimationcharge-list/';
export const intimationgetdetailEndPoint = baseUrl + '/reports/overdueloan/';



export const intrestlistEndPoint = baseUrl + '/masters/interest-list/';
export const interestPercentageEndPoint = baseUrl + '/masters/interestpercentage/';









