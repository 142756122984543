import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { deliveryEndPoint, deliveryLoanListEndPoint, loanTableListEndPoint, manageOverDueListEndPoint, } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { convertToTime } from "../../../utilities/time";
import ManageOverDueFilters from "./manageoverduefilter";

export default function ManageOverDue() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [manageoverdueList, setManageOverdueList] = useState([]);
    const [currentOverdue, setCurrentOverdue] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);


    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        firm: null,
        payment_mode: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });



    const { actions, pagination } = useSelector((state) => state.appConfig);


    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px]'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            localStorage.setItem('user_purchaseentry_mode', 'create');
            navigate('loan-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    useEffect(() => {
        getmanageOverdueListData(tableFilters)
    }, [tableFilters])

    const getmanageOverdueListData = async (body) => {
        var response = await postAxiosWithToken({
            url: manageOverDueListEndPoint,
            body: body
        })

        if (response !== null) {
            setManageOverdueList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const deletePurchase = async () => {
        var response = await deleteAxiosWithToken({
            url: deliveryEndPoint + currentOverdue.id + '/'
        })

        if (response !== null) {
            getmanageOverdueListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }



    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div>

                {/* <button  onClick={() => {navigate('/Overdue-Management/Manage-Overdue/Auction-Print')}}>
                    print
                </button> */}

                <div className="flex justify-end">


                    <button
                        onClick={() => {
                            if (actions.addPermit) {
                                localStorage.setItem('loan_manageoverdue_mode', 'create'); navigate('manageoverdue-form')
                            } else {
                                toast.error("You don't have permission for add")
                            }
                        }}
                        className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Intimation Charge<span className="lowercase">(ctrl+m)</span></button>
                </div>



                <div id="filteroptions" className='my-[5px] '>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <ManageOverDueFilters filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px] ">Loan Date</td>
                                <td className="p-[10px] min-w-[150px] ">Loan No</td>
                                <td className="p-[10px] min-w-[180px]">Firm Name</td>
                                <td className="p-[10px] min-w-[150px]">Customer Name</td>
                                <td className="p-[10px] min-w-[150px]">Mobile Number</td>
                                <td className="p-[10px] min-w-[180px]">Loan Amount</td>
                                <td className="p-[10px] min-w-[180px]">Till Interest Amount</td>
                                <td className="p-[10px] min-w-[180px]">Charge Amount</td>
                                <td className="p-[10px] min-w-[180px]">Total Amount</td>
                                <td className="p-[10px] min-w-[150px]">Create Date</td>

                                <td className="p-[10px] min-w-[150px] sticky right-0 bg-table-head text-center">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-[#fff]">
                            {isLoading ? (
                                <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={11}>
                                        Loading...
                                    </td>
                                </tr>
                            ) : manageoverdueList.length === 0 ? (
                                <tr>
                                    <td className="text-center border" colSpan={11}>
                                        No Data
                                    </td>
                                </tr>
                            ) : (
                                manageoverdueList?.map((item, index) => (
                                    <tr key={item.id} className="border">
                                        <td className="p-[10px]">{index + 1}</td>
                                        <td className="p-[10px]">{item.loan_date}</td>
                                        <td className="p-[10px] bg-[#ffebcd]">{item.loanno}</td>

                                        <td className="p-[10px] capitalize">{item.firm_name}</td>
                                        <td className="p-[10px] capitalize">{item.customer_name}</td>
                                        <td className="p-[10px] capitalize">{item.mobile}</td>
                                        <td className="p-[10px]">Rs. {parseFloat(item.loan_amt).toFixed(2)}</td>
                                        <td className="p-[10px]">Rs. {parseFloat(item.interest_occured).toFixed(2)}</td>
                                        <td className="p-[10px]">Rs. {parseFloat(item.intimation_charge).toFixed(2)}</td>
                                        <td className="p-[10px]">Rs. {parseFloat(item.total_loan_amt).toFixed(2)}</td>

                                        <td className="p-[10px] capitalize">
                                            <p>{convertToDate(item.created_at)}</p>
                                            <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p>
                                        </td>

                                        <td className="p-[10px] sticky right-0 bg-white flex justify-center gap-2 mt-2">
                                            <div className="flex gap-3">

                                                <div onClick={() => { navigate("/Overdue-Management/Manage-Overdue/Auction-Print" + "/" + item.id) }} className="cursor-pointer hover:scale-125">
                                                    <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16 8V5H8v3H6V3h12v5zM4 10h16zm14 2.5q.425 0 .713-.288T19 11.5t-.288-.712T18 10.5t-.712.288T17 11.5t.288.713t.712.287M16 19v-4H8v4zm2 2H6v-4H2v-6q0-1.275.875-2.137T5 8h14q1.275 0 2.138.863T22 11v6h-4zm2-6v-4q0-.425-.288-.712T19 10H5q-.425 0-.712.288T4 11v4h2v-2h12v2z" /></svg></div>

                                            </div>

                                            <div className="flex gap-3">

                                                <div onClick={() => { navigate("/Overdue-Management/Manage-Overdue/Auction-view" + "/" + item.id) }} className="cursor-pointer hover:scale-125">
                                                <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32"><path fill="currentColor" d="M30.94 15.66A16.69 16.69 0 0 0 16 5A16.69 16.69 0 0 0 1.06 15.66a1 1 0 0 0 0 .68A16.69 16.69 0 0 0 16 27a16.69 16.69 0 0 0 14.94-10.66a1 1 0 0 0 0-.68M16 25c-5.3 0-10.9-3.93-12.93-9C5.1 10.93 10.7 7 16 7s10.9 3.93 12.93 9C26.9 21.07 21.3 25 16 25"/><path fill="currentColor" d="M16 10a6 6 0 1 0 6 6a6 6 0 0 0-6-6m0 10a4 4 0 1 1 4-4a4 4 0 0 1-4 4"/></svg></div>

                                            </div>
                                        </td>




                                    </tr>
                                ))
                            )}

                            

                        </tbody>
                    </table>
                </div>




                {pagination === true ?
                    <div className="pb-5" >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete User'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentOverdue?.customer_name} loan delivery?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deletePurchase() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}