import React, { useEffect, useRef, useState } from "react";


export default function TransactionPrint({data}) {





  return (
    <div>
      <div className="px-[10px] mt-[50px]">
        <div >
          <table class="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                   Sno
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                EntryDate
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Release
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                DeliveryIntrest
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Loan 
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                LoanIntrest
                </th>
                
              </tr>
            </thead>
            <tbody>

            {data.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                data?.map((item, index) => (


              <tr class="bg-gray-50">
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">
                {index+1}
                </td>
                <td class="p-[10px] text-[10px] min-w-[80px] border border-gray-300 px-2 py-2">{item.EntryDate}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Release}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.DeliveryIntrest}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Loan}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.LoanIntrest}</td>
         
              </tr>
                 ))
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
