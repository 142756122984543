import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setAddT, setDatT, setNow } from '../../redux/appconfigslice';

export default function Footer() {
    const { pathname } = useLocation();
    const { now } = useSelector((state) => state.appConfig);
    const dispatch = useDispatch();

    const timer = () => setTimeout(() => {
        if (now) {
            dispatch(setAddT(true));
        } else {
            dispatch(setNow(true));
        }
    }, 2000);

    const onHold = (e) => {
        e.preventDefault();
        timer();
    };



    const mouseRemove = (e) => {
        clearTimeout(timer);
    };



    const timerdata = () => setTimeout(() => {
        if (now) {
            dispatch(setDatT(true));
        } else {
            dispatch(setNow(true));
        }
    }, 2000);

    const onHolddata = (e) => {
        e.preventDefault();
        timerdata();
    };



    const mouseRemovedata = (e) => {
        clearTimeout(timerdata);
    };

    return (
        <>
            <div className="sm:flex items-center justify-between px-[10px] mx-auto sm:mx-0 w-fit sm:w-full my-[10px] ">
                <div className="flex gap-3 justify-center sm:justify-start text-[10px] md:text-[15px] max-[480px]:hidden">
                    <button onMouseDown={onHolddata} onMouseUp={mouseRemovedata} id='drangate' className="text-[10px] md:text-[15px] text-center">Home</button>
                    {
                        pathname.split('/').length > 2 ? pathname.split('/')?.map((item, index) => (
                            <p key={"breadcrumbs" + index}>{item} /</p>
                        )) : <p>/ Dashboard</p>
                    }
                </div>
                <div className='flex justify-end max-[646px]:justify-center '>

                <button onMouseDown={onHold} onMouseUp={mouseRemove} id='tripple' className="text-[10px] md:text-[15px] text-center">Powered by ATTS</button>
                </div>
            </div>
        </>
    );
}
