import React, { useEffect, useRef, useState } from "react";


export default function InterestReportsPrint({data}) {



  return (
    <div>
      <div className="px-[10px] mt-[50px]">
        <div >
          <table class="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                   Sno
                </th>
               
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Loan NO
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                 Customer Name
                </th>
               
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Firm Name
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Branch
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Recevied Date
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Mobile
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Address
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Cash
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Bank
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Total Interest
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Payment Type 
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Payment Mode 
                </th>
                
                
              </tr>
            </thead>
            <tbody>

            {data.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                data?.map((item, index) => (


              <tr class="bg-gray-50">
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">
                {index+1}
                </td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.LoanNo}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.CustomerName}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.FirmName}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Branch}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.ReceivedDate}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Mobile}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Address}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Cash}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Bank}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.TotalInterest}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.PaymentType}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.PaymentMode}</td>



              </tr>
                 ))
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
