import React, { useEffect, useRef, useState } from "react";
import {
  ConfigurationListEndPoint,
  ItemMetalListEndPoint,
  RemarksdropDownEndPoint,
  branchListEndPoint,
  customerMobilenumberEndPoint,
  loanEndPoint,
  loanIdEndPoint,
  metalListEndPoint,
  newFirmListEndPoint,
  todayMetalRatesEndPoint,
} from "../../../service/api";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../../service/apiservice";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useKeyPress } from "../../../hooks/useKeyPress";
import ReactToPrint from "react-to-print";
import Select from "react-select";
import { convertToDate } from "../../../utilities/datetime";
import Popup from "../../../components/Popup";
import LoanDistributionDetails from "./loandeatils";
import { isOptionalValidForm } from "../../../utilities/formvalidation";
import SearchDropDown from "../../../components/SearchDropDown";
import MetaRemarks from "../../masters/remarks";

function NewLoanForm() {
  const optionalFields = [];

  const [formMode, setFormMode] = useState("create");
  const [branchList, setBranchList] = useState([]);
  const [remarksList, setRemarksList] = useState([]);
  const [updateRemarks, setUpdateRemarks] = useState([]);

  const [metaldrop, setMetalDrop] = useState("");
  const [errors, setErrors] = useState({});
  const [metalTypeDropdown, setMetalTypeDropdown] = useState([]);
  const [itemTypeDropdown, setItemTypeDropdown] = useState([]);
  const [firmList, setFirmList] = useState([]);
  const [todayMetalRateList, setTodayMetalRateList] = useState({});

  const [currentList, setcurrentList] = useState({
    loan_date: new Date().toLocaleDateString("en-CA"),
  });

  const [mobileNumber, setMobileNumber] = useState("");
  const [loanidNumber, setLoanIdList] = useState("");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState({});

  const navigate = useNavigate();
  let componentRef = useRef();

  const [metals, setMetal] = useState("");

  const [items, setItem] = useState("");
  const [noofpiece, setNoofpieces] = useState("");
  const [metalRate, setMetalRate] = useState({});

  console.log("metals", metals);
  console.log("metalRate", metalRate);

  const [grossweight, setGrossweight] = useState("");
  const [netweight, setNetweight] = useState("");
  const [stoneweight, setStoneweight] = useState(0);
  const [puritys, setPurity] = useState("");
  const [remarks, setRemarks] = useState("");
  const [values, setValue] = useState("");
  const [touchs, setTouch] = useState(75);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState();

  const [imageUpdate, setIsImageUpdate] = useState(false);

  const [updateRemarksItem, setUpdateRemarksItem] = useState(null);

  const [particularsData, setParticularsData] = useState([]);

  console.log("particularsData", particularsData);
  const [PurchaseData, setTotalPurchaseData] = useState([]);

  const [payment, setPayment] = useState({});

  const [cash, setCash] = useState({});
  const [bank, setBank] = useState({});

  const [paymentValues, setPaymentValues] = useState([]);
  const [loanprint, setLoanPrint] = useState(0);

  function closeDeleteModal() {
    setIsDeleteOpen(false);
    setIsPopupOpen(false);
  }
  function closePopupModal() {
    setIsPopupOpen(false);
  }

   

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === "s") {
      event.preventDefault();
      document.getElementById("formsubmit").click();
      document.getElementById("forminit").blur();
    } else if (event.key === "Escape") {
      event.preventDefault();
      // reset()
      setFormMode("create");
      navigate(-1);
    }
  };

  useKeyPress(["s", "Escape"], onKeyPress);

  useEffect(() => {
    getMetalTypeDropDown();
  }, []);

  useEffect(() => {
    if (String(metals).length > 0) {
      getItemTypeDropDown();
    }
  }, [metals]);

  useEffect(() => {
    getRemarksDropdownData();
  }, [formMode]);

  useEffect(() => {
    getBranchDropdownData();
    getConfigurationData();
    getFirmDropDownData();
    getMetalRateData();
    setFormMode(localStorage.getItem("user_purchaseentry_mode"));

    if (localStorage.getItem("user_purchaseentry_mode") === "update") {
      getLoanDetails();
    }
  }, []);

  useEffect(() => {
    const netWeight =
      parseFloat(grossweight || 0) - parseFloat(stoneweight || 0);
    setNetweight(netWeight);
  }, [grossweight, stoneweight]);

  useEffect(() => {
    const dividedValue =
      (parseFloat(netweight || 0) * parseFloat(touchs || 0)) / 100;
    setPurity(dividedValue);
  }, [netweight, touchs]);

  useEffect(() => {
    const Totalvalues =
      metals == 1
        ? parseFloat(puritys || 0) * parseFloat(todayMetalRateList.gold_rate)
        : metals == 2
        ? parseFloat(puritys || 0) * parseFloat(todayMetalRateList.silver_rate)
        : "";
    setValue(Totalvalues);
  }, [puritys]);

  // const handleItemDetailsChange = (e) => {
  //   const { name, value } = e.target;

  //   let parsedValue = parseFloat(value);
  //   if (isNaN(parsedValue)) parsedValue = value;

  //   let newErrors = { ...errors };

  //   if (name === "remarks") {

  //     setRemarks(value);
  //     if (!value) newErrors.remarks = "Remarks cannot be empty";
  //     else delete newErrors.remarks;
  //   } else if (name === "gross_weight") {
  //     setGrossweight(parsedValue);
  //     if (parsedValue === 0) newErrors.gross_weight = "Gross weight cannot be zero";
  //     else delete newErrors.gross_weight;
  //   } else if (name === "touch") {
  //     setTouch(value);
  //     if (!value) newErrors.touch = "Touch cannot be empty";
  //     else delete newErrors.touch;
  //   } else if (name === "noofpieces") {
  //     setNoofpieces(parsedValue);
  //     if (parsedValue === 0) newErrors.noofpieces = "Number of pieces cannot be zero";
  //     else delete newErrors.noofpieces;
  //   } else if (name === "item") {
  //     setItem(value);
  //     if (!value) newErrors.item = "Item cannot be empty";
  //     else delete newErrors.item;
  //   } else if (name === "stone_weight") {
  //     setStoneweight(parsedValue);
  //     if (parsedValue < 0) newErrors.stone_weight = "Stone weight cannot be negative";
  //     else delete newErrors.stone_weight;
  //   }

  //   setErrors(newErrors);
  // };

  // const handleAddItem = (e) => {
  //   e.preventDefault();

  //   var data = {
  //     metal: metals,
  //     item: items,
  //     touch: touchs,
  //     value: values,
  //     remarks: remarks,
  //     purity: puritys,
  //     stone_weight: stoneweight,
  //     gross_weight: grossweight,
  //     net_weight: netweight,
  //     noofpieces: noofpiece,
  //   };

  //   for (var i of metalTypeDropdown) {
  //     if (i.id == metals) {
  //       data["metal__metal_name"] = i.metal_name;
  //       break; // Stop loop once found
  //     }
  //   }
  //   for (var i of itemTypeDropdown) {
  //     if (i.value == items) {
  //       data["item__item_print"] = i.label;
  //       break; // Stop loop once found
  //     }
  //   }
  //   for (var i of remarksList) {
  //     if (i.id == remarks) {
  //       data["metal__metal_remarks"] = i.metal_remarks;
  //       break; // Stop loop once found
  //     }
  //   }

  //   // Add the validated item to particularsData
  //   setParticularsData([...particularsData, data]);

  //   // Reset itemDetails state for a new item entry
  //   setMetal("");
  //   setItem("");
  //   setTouch("75");
  //   setValue("");
  //   setRemarks("");
  //   setPurity("");
  //   setStoneweight(0.0);
  //   setGrossweight("");
  //   setNetweight(0.0);
  //   setNoofpieces("");
  // };

  const handleItemDetailsChange = (e) => {
    const { name, value } = e.target;

    console.log("Raw value:", value);

    // We don't immediately parse the value to allow decimal input like "5."
    let newErrors = { ...errors };

    switch (name) {
      case "remarks":
        setRemarks(value);
        if (!value) newErrors.remarks = "Remarks cannot be empty";
        else delete newErrors.remarks;
        break;

      case "gross_weight":
        setGrossweight(value); // Store the value as string for now

        // Parse only if it's a valid number (to prevent issues with '5.')
        const parsedGrossWeight = parseFloat(value);
        if (isNaN(parsedGrossWeight) || parsedGrossWeight === 0) {
          newErrors.gross_weight = "Gross weight cannot be zero or invalid";
        } else {
          delete newErrors.gross_weight;
        }
        break;

      case "touch":
        setTouch(value);
        if (!value) newErrors.touch = "Touch cannot be empty";
        else delete newErrors.touch;
        break;

      case "metal_rate":
        // Update metalRate object depending on the 'metals' value
        if (metals == 1) {
          setMetalRate((prev) => ({ ...prev, gold_rate: value }));
        } else if (metals == 2) {
          setMetalRate((prev) => ({ ...prev, silver_rate: value }));
        }
        if (!value) newErrors.metal_rate = "Metal Rate cannot be empty";
        else delete newErrors.metal_rate;
        break;

      case "noofpieces":
        setNoofpieces(value);
        const parsedNoOfPieces = parseFloat(value);
        if (parsedNoOfPieces === 0)
          newErrors.noofpieces = "Number of pieces cannot be zero";
        else delete newErrors.noofpieces;
        break;

      case "item":
        setItem(value);
        if (!value) newErrors.item = "Item cannot be empty";
        else delete newErrors.item;
        break;

      case "stone_weight":
        setStoneweight(value);
        const parsedStoneWeight = parseFloat(value);
        if (parsedStoneWeight < 0)
          newErrors.stone_weight = "Stone weight cannot be negative";
        else delete newErrors.stone_weight;
        break;

      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    let errorMessages = {};

    // Check if required fields are empty
    if (!metals) errorMessages.metals = "Metal is required.";
    if (!items) errorMessages.items = "Item is required.";
    if (!noofpiece) errorMessages.noofpiece = "Number of pieces is required.";
    if (!grossweight) errorMessages.grossweight = "Gross weight is required.";
    if (!netweight) errorMessages.netweight = "Net weight is required.";

    // Check metal rate based on the selected metal
    if (metals == 1 && !metalRate.gold_rate) {
      errorMessages.metalRate = "Gold rate is required.";
    } else if (metals == 2 && !metalRate.silver_rate) {
      errorMessages.metalRate = "Silver rate is required.";
    }

    // Validate if the numeric fields are valid numbers
    if (isNaN(stoneweight))
      errorMessages.stoneweight =
        "Please enter a valid number for stone weight.";
    if (isNaN(grossweight))
      errorMessages.grossweight =
        "Please enter a valid number for gross weight.";
    if (isNaN(netweight))
      errorMessages.netweight = "Please enter a valid number for net weight.";
    if (isNaN(noofpiece))
      errorMessages.noofpiece =
        "Please enter a valid number for number of pieces.";

    // Validate metalRate as a number based on metals selection
    const selectedRate =
      metals == 1
        ? metalRate.gold_rate
        : metals == 2
        ? metalRate.silver_rate
        : null;
    if (isNaN(selectedRate))
      errorMessages.metalRate = "Please enter a valid number for Metal Rate.";

    if (Object.keys(errorMessages).length > 0) {
      setErrors(errorMessages);
      return;
    }

    var data = {
      metal: metals,
      metal_rate: selectedRate,
      item: items,
      touch: touchs,
      value: values,
      remarks: remarks,
      purity: puritys,
      stone_weight: stoneweight,
      gross_weight: grossweight,
      net_weight: netweight,
      noofpieces: noofpiece,
    };

    for (var i of metalTypeDropdown) {
      if (i.id == metals) {
        data["metal__metal_name"] = i.metal_name;
        break; // Stop loop once found
      }
    }
    for (var i of itemTypeDropdown) {
      if (i.value == items) {
        data["item__item_print"] = i.label;
        break; // Stop loop once found
      }
    }

    for (var i of remarksList) {
      if (i.value == remarks) {
        data["metal__metal_remarks"] = i.label;
        break; // Stop loop once found
      }
    }

    // Add the validated item to particularsData
    setParticularsData([...particularsData, data]);

    // Reset itemDetails state for a new item entry
    setMetal("");
    setItem("");
    // setMetalRate({ gold_rate: "", silver_rate: "" });  // Reset both rates
    setTouch("75");
    setValue("");
    setRemarks("");
    setPurity("");
    setStoneweight(0.0);
    setGrossweight("");
    setNetweight(0.0);
    setNoofpieces("");
    setErrors({});
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchList(response.data.list);
    }
  };
  const getRemarksDropdownData = async () => {
    var response = await getAxios({
      url: RemarksdropDownEndPoint,
    });

    if (response !== null) {
      setUpdateRemarks(response.data.list);

      let tempList = [];
      for (var i of response.data.list) {
        var tempDict = {
          value: i.id,
          label: i.metal_remarks,
        };
        tempList.push(tempDict);
      }
      setRemarksList(tempList);
    }
  };

  const getLoanidData = async (id) => {
    var response = await getAxios({
      url: loanIdEndPoint + id + "/",
    });

    if (response !== null) {
      setcurrentList((prevState) => ({
        ...prevState,
        loanno: response.data.loanno,
      }));
    }
  };

  const getMetalTypeDropDown = async () => {
    var respose = await getAxios({
      url: metalListEndPoint,
    });

    if (respose !== null) {
      setMetalTypeDropdown(respose.data.list);
    }
  };
  const getItemTypeDropDown = async () => {
    var respose = await getAxios({
      url: ItemMetalListEndPoint + metals + "/",
    });

    if (respose !== null) {
      // setItemTypeDropdown(respose.data);
      let tempList = [];
      for (var i of respose.data) {
        var tempDict = {
          value: i.id,
          label: i.item_desc,
        };
        tempList.push(tempDict);
      }
      setItemTypeDropdown(tempList);
    }
  };

  const getFirmDropDownData = async () => {
    var response = await getAxios({
      url: newFirmListEndPoint,
    });

    if (response !== null) {
      setFirmList(response.data.list);
    }
  };
  const getMetalRateData = async () => {
    var response = await getAxios({
      url: todayMetalRatesEndPoint,
    });

    if (response !== null) {
      setTodayMetalRateList(response.data);
      setMetalRate(response.data);
    }
  };

  const onValueUpdate = (event) => {
    const { name, value } = event.target;

    if (name == "firm") {
      getLoanidData(value);
    }

    setcurrentList((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: value.length === 0 ? `${name} field is required` : "",
    }));
  };

  const handleSearch = async (mobile) => {
    var response = await getAxios({
      url: customerMobilenumberEndPoint + mobile + "/",
    });

    if (response != null) {
      toast.success(response.message);
      setcurrentList((prevState) => ({
        ...prevState,
        customer_name: response.data?.customer_name,
        village_name: response.data?.village_name,
        customer: response.data?.id,
        village: response.data?.village,
        id_branch: response.data?.id_branch,
        cus_image: response.data?.cus_image,
      }));
    }
  };

  const getLoanDetails = async () => {
    var response = await getAxios({
      url: loanEndPoint + localStorage.getItem("purchase_form_id") + "/",
    });

    if (response !== null) {

      var tempDetails = {
        customer_name: response.data?.loan_list?.customer_name,
        village_name: response.data?.loan_list?.village_name,
        firm: response.data?.loan_list?.firm,
        loanno: response.data?.loan_list?.loanno,
        id_branch: response.data?.loan_list?.id_branch,
        loan_date: response.data?.loan_list?.loan_date,
        distribute_amt: response.data?.loan_list?.distribute_amt,
        interest_percentage: response.data?.loan_list?.interest_percentage,
        // cash: response.data?.loan_list?.cash,
        // bank: response.data?.loan_list?.bank,
        // payment_mode: response.data?.loan_list?.payment_mode,
        loan_img: response.data?.loan_list?.loan_img,
        accounter_name: response.data?.loan_list?.accounter_name,
        cus_image: response.data?.loan_list?.cus_image,
      };
      setSelectedPaymentMode(response.data?.loan_list?.payment_mode);
      setBank(response.data?.loan_list?.bank);
      setCash(response.data?.loan_list?.cash);
      setMobileNumber(response.data.loan_list.mobile);
      setcurrentList(tempDetails);
      setParticularsData(response.data?.iteam_details);
      setPayment(response.data.loan_list.paymentmode_list);

      handleSearch(response.data.loan_list.mobile)
    }
  };

  const validateForm = () => {
    let isValid = true;
    let newErrors = { ...errors };

    if (selectedPaymentMode == 1 || selectedPaymentMode == 3) {
      if (parseFloat(cash) === 0) {
        newErrors.cash = "Cash amount cannot be zero";
        isValid = false;
      }
    }

    if (selectedPaymentMode == 2 || selectedPaymentMode == 3) {
      if (parseFloat(bank) === 0) {
        newErrors.bank = "Bank amount cannot be zero";
        isValid = false;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleCreatePurchase = (e) => {
    e.preventDefault();

    let data = [...paymentValues];
    for (var i in paymentValues) {
      if (selectedPaymentMode.toString() != "1") {
        if (paymentValues[i]["value"].length == 0) {
          data[i]["value"] = 0;
        }
      } else {
        if (selectedPaymentMode == paymentValues[i].id) {
          if (paymentValues[i]["value"].length == 0) {
            data[i]["error"] = paymentValues[i].mode_name + "field is required";
          }
        }
      }
    }
    setPaymentValues(data);

    // if (!validateForm()) {
    //   toast.error("Please correct the errors before submitting the form.");
    //   return;
    // }

    const formDataObject = {};
    const formData = new FormData(e.target);

    formData.forEach((value, key) => {
      if (formDataObject[key]) {
        if (!Array.isArray(formDataObject[key])) {
          formDataObject[key] = [formDataObject[key]];
        }
        formDataObject[key].push(value);
      } else {
        formDataObject[key] = value;
      }
    });

    var err = {};

    for (var field of Object.entries(formDataObject)) {
      if (field[1].length === 0 && !optionalFields.includes(field[0])) {
        err[field[0]] = `${field[0]} field is required`;
      } else {
        err[field[0]] = "";
      }
    }

    // if (localStorage.getItem("user_purchaseentry_mode") === "create") {
    //   if (!selectedImages || selectedImages === undefined) {
    //     // err["loan_img"] = "Loan image is required.";
    //     toast.error("Loan image is required")
    //   }
    // } else {
    //   if (imageUpdate && (!selectedImages || selectedImages === undefined)) {
    //     err["loan_img"] = "Loan image is required for update.";
    //   }
    // }

    setErrors((prevState) => ({
      ...prevState,
      ...err,
    }));

    const combinedDataObject = {
      ...formDataObject,
      ...PurchaseData,
      ...currentList,
    };

    combinedDataObject.item_details = particularsData.map((item) => ({
      metal: item.metal,
      item: item.item,
      gross_weight: item.gross_weight,
      net_weight: item.net_weight,
      noofpieces: item.noofpieces,
      purity: item.purity,
      remarks: item.remarks,
      stone_weight: item.stone_weight,
      touch: item.touch,
      value: item.value,
    }));

    const allData = new FormData();

  

    for (let [key, value] of Object.entries(combinedDataObject)) {
      if (key === "item_details" && Array.isArray(value)) {
        // Instead of appending each item with an index, append the entire object directly
        allData.append("item_details", JSON.stringify(value)); // Serialize array as JSON
      } else {
        allData.append(key, value);
      }
    }
    if (localStorage.getItem("user_purchaseentry_mode") === "create") {
    allData.append("loan_img", selectedImages);}else{
      allData.append("is_image_update", imageUpdate);
      if(imageUpdate){
        allData.delete("loan_img");
        allData.append("loan_img", selectedImages);
      }
    }



    if (isOptionalValidForm(formDataObject, optionalFields)) {
      if (localStorage.getItem("user_purchaseentry_mode") === "create") {
        createLoan(allData);
      } else {
        updateLoan(allData);
      }
    }
  };

  const getConfigurationData = async () => {
    var response = await getAxios({
      url: ConfigurationListEndPoint,
    });

    if (response !== null) {
      setLoanPrint(response.data.loan_print);
    }
  };

  const createLoan = async (body) => {
    var response = await postAxiosWithToken({
      url: loanEndPoint,
      body: body,
    });

    if (response != null) {
      // clearData()
      toast.success(response.message);

      if (loanprint == 1) {
        navigate(
          "/Loan-Management/New-Loan/newloan-print" + "/" + response.data.id
        );
      } else if (loanprint == 2) {
        navigate(
          "/Loan-Management/New-Loan/newloan-print-amount" +
            "/" +
            response.data.id
        );
      }
    }
  };

  const updateLoan = async (body) => {
    var response = await putAxiosWithToken({
      url: loanEndPoint + localStorage.getItem("purchase_form_id") + "/",
      body: body,
    });

    if (response != null) {
      toast.success(response.message);

      if (loanprint == 1) {
        navigate(
          "/Loan-Management/New-Loan/newloan-print" + "/" + response.data.id
        );
      } else if (loanprint == 2) {
        navigate(
          "/Loan-Management/New-Loan/newloan-print-amount" +
            "/" +
            response.data.id
        );
      }
    }
  };

  const handleEditItem = (index) => {
    const editedItem = particularsData[index];
    setMetal(editedItem.metal);
    setItem(editedItem.item);
    setNoofpieces(editedItem.noofpieces);
    setGrossweight(editedItem.gross_weight);
    setNetweight(editedItem.net_weight);
    setStoneweight(editedItem.stone_weight);
    setPurity(editedItem.purity);
    setRemarks(editedItem.remarks);
    setValue(editedItem.value);
    setTouch(editedItem.touch);

    const updatedParticularsData = [...particularsData];
    updatedParticularsData.splice(index, 1);
    setParticularsData(updatedParticularsData);
  };

  const handleDeleteItem = (index) => {
    const updatedParticularsData = [...particularsData];
    updatedParticularsData.splice(index, 1);
    setParticularsData(updatedParticularsData);
  };

  useEffect(() => {
    calculateTotals();
  }, [particularsData]);

  const calculateTotals = () => {
    let totalPieces = 0;
    let totalGrossWeight = 0;
    let totalNetWeight = 0;
    let totalPurity = 0;
    let totalValue = 0;

    particularsData.forEach((item) => {
      totalPieces += parseInt(item.noofpieces);
      totalGrossWeight += parseFloat(item.gross_weight);
      totalNetWeight += parseFloat(item.net_weight);
      totalPurity += parseFloat(item.purity);
      totalValue += parseFloat(item.value);
    });

    setTotalPurchaseData({
      ...PurchaseData,
      noofpieces: totalPieces,
      gross_weight: totalGrossWeight.toFixed(2),
      net_weight: totalNetWeight.toFixed(2),
      purity: totalPurity.toFixed(2),
      value: totalValue,
    });
  };

  const onImageUpload = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      // Add validation and processing logic here
      console.log("Selected image:", file);

      setSelectedImages(file);
      setIsImageUpdate(true)

    }else{
      setIsImageUpdate(false)
    }

    console.log("file",file)

  
  };



  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoRef.current.srcObject = stream;
      setIsCameraOpen(true);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const capturePhoto = () => {
    const context = canvasRef.current.getContext("2d");
    context.drawImage(videoRef.current, 0, 0, 300, 200);
    const imageDataUrl = canvasRef.current.toDataURL("image/png");
    setCapturedImage(imageDataUrl);
    stopCamera();
  };

  const stopCamera = () => {
    if (videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    }
    setIsCameraOpen(false);
  };









  return (
    <>
      <div className="mb-[50px] border bg-menu-head-bg shadow-lg p-[15px] rounded-lg">
        <p className="text-menu-head font-[600] text-[18px] capitalize">
          {formMode} New Loan
        </p>

        <div className="border p-[15px] rounded-xl shadow-lg flex items-center gap-[40px] mt-5">
          <div>
            <p className="mb-[5px] text-[15px]">Search Mobile Number</p>
          <div className=" w-full">
            <input
              type="text"
              placeholder="Search Mobile Number"
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-full max-[480px]:w-full p-[10px] text-[17px] outline-none"
              name="mobile_number"
              id="forminit"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
          </div>
          </div>
          <div className="w-1/2 flex gap-[10px] mt-4 max-[365px]:mt-[50px]">
            <button
              onClick={(e) => {
e.preventDefault();
handleSearch(mobileNumber)
              }}
              className="bg-[#0176FF] border border-gray-300 rounded-lg text-white p-[10px]"
            >
              Search
            </button>
          </div>
        </div>

        <form id="purchaseEntryform">
              <p className="text-[18px] font-[500] mt-5 text-[menu-head]">
                New Loan
              </p>
          <div className="grid xl-grid-cols-3 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3">
            <div>

              {currentList.cus_image == undefined ||
              currentList.cus_image == null ? (
                <div>
                  <p className="text-[16px] mt-3 ">Customer Image</p>
                  <p className="text-[18px] font-[500] text-[red]">
                    There is no image
                  </p>
                </div>
              ) : (
                <>
                  <p className="text-[16px] mb-2 mt-3">Customer Image</p>
                  <img
                    width={"150px"}
                    src={currentList.cus_image}
                    alt="customerimage"
                  />
                </>
              )}
            </div>

            <div className="flex justify-end gap-5">
              <div>
                <p>Today Gold Rate</p>
                <p className="bg-yellow-400 p-2 rounded-[10px]">
                  {todayMetalRateList.gold_rate}
                </p>
              </div>

              <div>
                <p>Today Silver Rate</p>
                <p className="bg-sky-400 p-2 rounded-[10px]">
                  {todayMetalRateList.silver_rate}
                </p>
              </div>
            </div>
          </div>

          <div className="grid xl:grid-cols-6 sm:grid-cols-3 gap-5 rounded-lg mb-[10px] mt-2">
            <>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">
                  Customer Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Customer Name"
                  className="sm:mb-[5px] capitalize border border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none bg-[#dcdcdc]"
                  name="customer"
                  value={currentList.customer_name}
                  onChange={onValueUpdate}
                  readOnly
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.customer}
                </p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">
                  Village Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Village Name"
                  className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none bg-[#dcdcdc]"
                  name="village"
                  value={currentList.village_name}
                  onChange={onValueUpdate}
                  readOnly
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.village}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Firm Name <span className="text-red-500">*</span>
                </p>
                <select
                  placeholder="Firm Name"
                  className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[10px] text-[14px] outline-none"
                  name="firm"
                  value={currentList.firm}
                  onChange={(event) => {
                    onValueUpdate(event);
                  }}
                >
                  <option value={""}>--select Firm--</option>
                  {firmList.map((item, index) => (
                    <option
                      className="capitalize"
                      key={item.firm_name}
                      value={item.id}
                    >
                      {item.firm_name}
                    </option>
                  ))}
                </select>

                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.firm}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Loan No <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Loan No"
                  className="sm:mb-[5px]  border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none bg-[#dcdcdc]"
                  name="loanno"
                  value={currentList.loanno}
                  onChange={onValueUpdate}
                  readOnly
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.loanno}
                </p>
              </div>

              {localStorage.getItem("branch") == "true" && (
                <div className="my-[5px]">
                  <p className="mb-[5px] text-[16px]">
                    Branch <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="Branch"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                    name="id_branch"
                    value={currentList.id_branch}
                    onChange={onValueUpdate}
                  >
                    <option value="">--Select Branch--</option>
                    {branchList.map((option) => (
                      <option
                        className="capitalize"
                        key={"branch" + option.branch_name}
                        value={option.id}
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.id_branch}
                  </p>
                </div>
              )}

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Date <span className="text-red-500">*</span>
                </p>
                <input
                  type="date"
                  placeholder="Loan Date"
                  readOnly
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                  name="loan_date"
                  value={currentList.loan_date}
                  // defaultValue={new Date().toLocaleDateString("en-CA")}
                  onChange={onValueUpdate}
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.loan_date}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] text-[15px]">
                  Accounter Name <span className="text-red-500">*</span>
                </p>
                <input
                  type="text"
                  placeholder="Accounter Name"
                  className="sm:mb-[5px] border capitalize border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                  name="accounter_name"
                  value={currentList.accounter_name}
                  onChange={onValueUpdate}
                />
                <p className="text-red-500 font-[400] text-[13px]">
                  {errors.accounter_name}
                </p>
              </div>

              {currentList.loan_img == undefined ||
              currentList.loan_img == null ? (
                <div className="my-[5px]">
                  <p className="mb-[5px] text-[16px]">Upload Loan Image</p>

                  <input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                    name="loan_img"
                    // value={currentList.loan_img}
                    onChange={onImageUpload}
                  />

                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.loan_img}
                  </p>
                </div>
              ) : (
                <div>
                  <label htmlFor="customerimg">
                    <img width={"100px"} src={currentList.loan_img} alt="" />
                  </label>
                  <input
                    id="customerimg"
                    type="file"
                    accept="image/*"
                    className="sm:mb-[5px] mt-2 border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                    name="loan_img"
                    // value={currentList.loan_img}
                    onChange={onImageUpload}
                  />
                </div>
              )}














            </>
          </div>

          <>
            <div className="border bg-menu-head-bg shadow-lg mb-2 p-[15px] rounded-lg">
              <p className="text-[18px] font-[500] text-[menu-head] p-[10px]">
                Item Details
              </p>

              <div className="grid xl:grid-cols-6 sm:grid-cols-6 gap-5 rounded-lg mb-[10px] mt-2">
                <div className="my-[5px]">
                  <p className=" text-[15px] capitalize">
                    Metal <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="metal name"
                    className="sm:mb-[5px] bg-white border capitalize border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                    name="metal"
                    value={metals}
                    onChange={(e) => setMetal(e.target.value)}
                  >
                    <option value={""}>--select metal--</option>
                    {metalTypeDropdown.map((item, index) => (
                      <option
                        className="capitalize"
                        key={item.metal_name}
                        value={item.id}
                      >
                        {item.metal_name}
                      </option>
                    ))}
                  </select>

                  {errors.metals && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.metals}
                    </p>
                  )}
                </div>

                <div className="my-[5px]">
                  <p className=" capitalize text-[15px]">
                    Item <span className="text-red-500">*</span>
                  </p>

                  <SearchDropDown
                    name={"item"}
                    id={"item"}
                    options={itemTypeDropdown}
                    value={items}
                    setValue={setItem}
                  />
                  {errors.items && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.items}
                    </p>
                  )}
                </div>

                {console.log(
                  "dddd",
                  metals == 1
                    ? metalRate.gold_rate
                    : metals == 2
                    ? metalRate.silver_rate
                    : ""
                )}
                <div className="my-[5px]">
                  <p className="mb-[5px] text-[12px]">
                    Metal Rate <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Rate"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="metal_rate"
                    value={
                      metals == 1
                        ? metalRate.gold_rate
                        : metals == 2
                        ? metalRate.silver_rate
                        : ""
                    }
                    onChange={handleItemDetailsChange}
                  />
                  {errors.metalRate && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.metalRate}
                    </p>
                  )}
                </div>

                <div className="my-[5px]">
                  <p className="mb-[5px] text-[12px]">
                    Pcs <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Pcs"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="noofpieces"
                    value={noofpiece}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.noofpiece && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.noofpiece}
                    </p>
                  )}
                </div>

                <div className="my-[5px]">
                  <p className="mb-[5px] text-[12px]">
                    Grs Wt <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Grs Wt"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="gross_weight"
                    value={grossweight}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.grossweight && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.grossweight}
                    </p>
                  )}
                </div>
                <div className="my-[5px]">
                  <p className="mb-[5px] text-[12px]">Swt</p>
                  <input
                    type="text"
                    placeholder="Swt"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="stone_weight"
                    value={stoneweight}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.stoneweight && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.stoneweight}
                    </p>
                  )}
                </div>

                <div className="my-[5px]">
                  <p className="mb-[5px] text-[12px]">
                    Net Wt <span className="text-red-500">*</span>
                  </p>
                  <input
                    type="text"
                    placeholder="Net Wt"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="net_weight"
                    value={netweight}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.netweight && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.netweight}
                    </p>
                  )}
                </div>
                <div className="my-[5px]">
                  <p className="mb-[5px] text-[12px]">Touch</p>
                  <input
                    type="text"
                    placeholder="Touch"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="touch"
                    value={touchs}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.touchs && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.touchs}
                    </p>
                  )}
                </div>
                <div className="my-[5px] ">
                  <p className="mb-[5px] text-[12px]">Pure</p>
                  <input
                    type="text"
                    placeholder="Pure"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="purity"
                    value={puritys}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.puritys && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.puritys}
                    </p>
                  )}
                </div>
                <div className="my-[5px] ">
                  <p className="mb-[5px] text-[12px]">Value (Rs.)</p>
                  <input
                    type="text"
                    placeholder="Value"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="value"
                    value={values}
                    onChange={handleItemDetailsChange}
                  />
                  {errors.values && (
                    <p className="text-red-500 font-[400] text-[13px]">
                      {errors.values}
                    </p>
                  )}
                </div>

                {/* <div className="my-[5px] ">
                  <p className="mb-[5px] text-[12px]">Remark</p>
                  <input
                    type="text"
                    placeholder="Remark"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none"
                    name="remarks"
                    value={remarks}
                    onChange={handleItemDetailsChange}
                  />
                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.item_name}
                  </p>
                </div> */}

                <div className="my-[5px] ">
                  <p className="mb-[5px] text-[12px]">Remarks</p>
                  <div className="flex items-center">
                    {/* <select
                    placeholder="branch"
                    className=" border capitalize border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                    name="remarks"
                    value={remarks}
                    onChange={handleItemDetailsChange}
                  >
                    <option value="">--Select Remarks--</option>
                    {remarksList?.map((option) => (
                      <option
                        className="capitalize"
                        key={"remarks" + option.metal_remarks}
                        value={option.id}
                      >
                        {option.metal_remarks}
                      </option>
                    ))}
                  </select> */}
                    <SearchDropDown
                      name={"remarks"}
                      id={"remarks"}
                      options={remarksList}
                      value={remarks}
                      setValue={setRemarks}
                      onChange={handleItemDetailsChange}
                    />
                    {errors.remarks && (
                      <p className="text-red-500 font-[400] text-[13px]">
                        {errors.remarks}
                      </p>
                    )}

                    <div
                      class="text-green-500 ml-[10px] outline-none border-none"
                      onClick={() => {
                        if (remarks.length > 0) {
                          setFormMode("update");
                          for (var i of updateRemarks) {
                            if (i.id == remarks) {
                              setUpdateRemarksItem(i);
                              break;
                            }
                          }
                        } else {
                          setFormMode("create");
                          setUpdateRemarksItem(null);
                        }
                        setIsPopupOpen(true);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="currentColor"
                          d="M228 128a12 12 0 0 1-12 12h-76v76a12 12 0 0 1-24 0v-76H40a12 12 0 0 1 0-24h76V40a12 12 0 0 1 24 0v76h76a12 12 0 0 1 12 12"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="my-[5px] mt-5">
                  <button
                    onClick={handleAddItem}
                    className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div className="mt-[25px]">
              <p className="text-[18px] font-[500]">Particulars</p>

              <div className="overflow-x-scroll mt-[15px]">
                <table className="w-full">
                  <thead className="border bg-table-head text-table-head-font text-[16px] font-[700]">
                    <tr>
                      <td className="p-[10px] min-w-[150px]">S.No</td>
                      <td className="p-[10px] min-w-[150px]">Type</td>
                      <td className="p-[10px] min-w-[150px]">Item</td>
                      <td className="p-[10px] min-w-[150px]">Metal Rate</td>
                      <td className="p-[10px] min-w-[150px]">Pcs</td>
                      <td className="p-[10px] min-w-[150px]"> Gwt</td>
                      <td className="p-[10px] min-w-[150px]">Swt</td>
                      <td className="p-[10px] min-w-[150px]">Nwt</td>
                      <td className="p-[10px] min-w-[150px]">Touch</td>
                      <td className="p-[10px] min-w-[150px]">Pure</td>
                      <td className="p-[10px] min-w-[150px]"> Value (Rs.)</td>
                      <td className="p-[10px] min-w-[150px]">Remark</td>
                      <td className="p-[10px] min-w-[150px]"> Action</td>
                    </tr>
                  </thead>
                  <tbody className="text-primary-grey bg-menu-head-bg">
                    {particularsData.length === 0 ? (
                      <tr>
                        <td className="text-center border" colSpan={11}>
                          No Data
                        </td>
                      </tr>
                    ) : (
                      particularsData?.map((item, index) => (
                        <tr key={item.id} className="border">
                          <td className="p-[10px]">{index + 1}</td>
                          <td className="p-[10px] capitalize">
                            {item.metal__metal_name}
                          </td>
                          <td className="p-[10px] capitalize">
                            {item.item__item_print}
                          </td>
                          <td className="p-[10px] capitalize">
                            {item.metal_rate}
                          </td>
                          <td className="p-[10px] capitalize">
                            {item.noofpieces}
                          </td>
                          <td className="p-[10px] capitalize">
                            {item.gross_weight}
                          </td>
                          <td className="p-[10px] capitalize">
                            {item.stone_weight}
                          </td>
                          <td className="p-[10px] capitalize">
                            {item.net_weight}
                          </td>
                          <td className="p-[10px] capitalize">{item.touch}</td>
                          <td className="p-[10px] capitalize">{item.purity}</td>
                          <td className="p-[10px] capitalize">{item.value}</td>
                          <td className="p-[10px] capitalize">
                            {item.metal__metal_remarks}
                          </td>
                          <td className="p-[10px] flex gap-3">
                            <div
                              onClick={() => handleEditItem(index)}
                              className="cursor-pointer hover:scale-125"
                            >
                              <svg
                                className="!text-[#94A3B8]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                              >
                                <g
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                >
                                  <path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" />
                                  <path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" />
                                </g>
                              </svg>
                            </div>
                            <div
                              onClick={() => handleDeleteItem(index)}
                              className="cursor-pointer hover:scale-125"
                            >
                              <svg
                                className="!text-[#94A3B8]"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 256 256"
                              >
                                <path
                                  fill="currentColor"
                                  d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z"
                                />
                              </svg>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="border bg-menu-head-bg shadow-lg mb-2 p-[15px] rounded-lg mt-5">
              <div className="mt-[25px] relative">
                <p className="text-[18px] font-[500]">Total Purchase</p>

                <div className="grid xl:grid-cols-6 sm:grid-cols-3 gap-5 rounded-lg mb-[10px] mt-2">
                  <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Total Pcs </p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none bg-[#dcdcdc]"
                      name="total_pieces"
                      value={PurchaseData.noofpieces}
                      onChange={onValueUpdate}
                      readOnly
                    />
                  </div>
                  <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Total Gwt </p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none bg-[#dcdcdc]"
                      name="total_grossweight"
                      value={PurchaseData.gross_weight}
                      onChange={onValueUpdate}
                      readOnly
                    />
                  </div>
                  <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Total Nwt</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none bg-[#dcdcdc]"
                      name="total_netweight"
                      value={PurchaseData.net_weight}
                      onChange={onValueUpdate}
                      readOnly
                    />
                  </div>
                  <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Total Pure</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none bg-[#dcdcdc]"
                      name="total_purity"
                      value={PurchaseData.purity}
                      onChange={onValueUpdate}
                      readOnly
                    />
                  </div>
                  <div className="my-[5px]">
                    <p className="mb-[5px] text-[15px]">Total Value</p>
                    <input
                      type="text"
                      placeholder=""
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[5px] text-[17px] outline-none bg-[#dcdcdc]"
                      name="total_value"
                      value={PurchaseData.value}
                      onChange={onValueUpdate}
                      readOnly
                    />
                    <p className="text-red-500 font-[400] text-[13px]"></p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </form>
      </div>
      <div className="flex min-[423px]:flex-row flex-col-reverse pb-[50px] justify-end gap-5 sticky  mt-[30px] bg-[#fff] p-[20px] border rounded-md w-full">
        <button
          id="backnav"
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="capitalize bg-menu-head-bg border text-black w-[200px] sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none max-[480px]:mx-auto "
        >
          close<span className="lowercase">(esc)</span>
        </button>

        <button
          onClick={() => {
            if (!selectedImages || selectedImages === undefined) {
              // Show error if no image is selected
              toast.error("Loan image is required");
            } else {
              setIsDeleteOpen(true); // Proceed if image exists
            }
          }}
          className="capitalize bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto"
        >
          Loan Deatils
        </button>
      </div>

      <div>
        <Popup
          extraClassName="min-w-[60%]"
          title={"Loan Distribution Details"}
          setIsOpen={setIsDeleteOpen}
          isOpen={isDeleteOpen}
          closeModal={closeDeleteModal}
        >
          <LoanDistributionDetails
            selectedPaymentMode={selectedPaymentMode}
            setSelectedPaymentMode={setSelectedPaymentMode}
            setErrors={setErrors}
            errors={errors}
            onValueUpdate={onValueUpdate}
            handleCreatePurchase={handleCreatePurchase}
            totalamount={PurchaseData.value}
            currentList={currentList}
            onClose={closeDeleteModal}
            setBank={setBank}
            bank={bank}
            setCash={setCash}
            cash={cash}
            paymentValues={paymentValues}
            setPaymentValues={setPaymentValues}
            payment={payment}
            setPayment={setPayment}
          />
        </Popup>

        <Popup
          extraClassName="min-w-[60%]"
          title={"Loan Distribution Details"}
          setIsOpen={setIsPopupOpen}
          isOpen={isPopupOpen}
          closeModal={closePopupModal}
        >
          <MetaRemarks
            updateItem={updateRemarksItem}
            formMode={formMode}
            setFormMode={setFormMode}
            setRemarks={setRemarks}
            getRemarksDropdown={getRemarksDropdownData}
          />

          {/* <LoanDistributionDetails
            errors={errors}
            onValueUpdate={onValueUpdate}
            handleCreatePurchase={handleCreatePurchase}
            totalamount={PurchaseData.value}
            currentList={currentList}
            onClose={closeDeleteModal}
          /> */}
        </Popup>
      </div>
    </>
  );
}

export default NewLoanForm;
