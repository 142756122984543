// import { useEffect, useState } from "react";
// import { metalRatesEndPoint, purityListEndPoint } from "../../../service/api";
// import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
// import { useKeyPress } from "../../../hooks/useKeyPress";
// import { toast } from "react-hot-toast";
// import { isValidForm } from "../../../utilities/formvalidation";

// export default function MetalRatesForm({ getData, formMode, currentMetalRates, setFormMode, setCurrentMetalRates }) {

//     const [purityListInput, setPurityListInput] = useState([]);

//     const onKeyPress = (event) => {
//         if (event.ctrlKey && event.key === 's') {
//             event.preventDefault();
//             document.getElementById('formsubmit').click()
//         } else if (event.ctrlKey && event.key === 'e') {
//             event.preventDefault();
//             clearData()
//             setFormMode('create')
//         }
//     };

//     useKeyPress(['s', 'e'], onKeyPress);

//     useEffect(() => {
//         getPurityListData()
//     }, [])

//     const getPurityListData = async () => {
//         var response = await getAxios({
//             url: purityListEndPoint
//         })

//         if (response !== null) {
//             setPurityListInput(response.data.list)
//         }
//     }

//     const handleSubmit = (event) => {
//         event.preventDefault();


//         if (formMode === 'create') {
//             let tempData = []
//             let validationList = [];
//             for (var item of purityListInput) {
//                 var data = {}
//                 var value = document.getElementById(item.metal__metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"value").value

//                 if (value.length !== 0) {
//                     data[item.metal__metal_name?.split(' ').join('_') + "_" + item.purity_name?.split(' ').join('_')] = value
//                     document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] hidden"
//                 } else {
//                     document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] block"
//                 }
//                 data['rate'] = value
//                 data['metal_details'] = item.id
//                 data['purity_details'] = item.id
//                 if (value.length !== 0) {
//                     tempData.push(data)
//                     validationList.push('1')
//                 } else {
//                     validationList.push('0')
//                 }
//             }
//                 // for (var item of purityListInput) {
//                 //     var value = document.getElementById(item.metal__metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"value").value


//                 // }
//                 let body = {
//                     rate_details: tempData
//                 }

//                 if (!validationList.includes('0')) {
//                     createMetalRates(body)
//                 }

//         } else {
//             //     var data = {}
//             //     for (var item of purityListInput) {
//             //         var value = document.getElementById(item.metal__metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"value").value

//             //         if (value.length !== 0) {
//             //             data[item.metal__metal_name?.split(' ').join('_') + "_" + item.purity_name?.split(' ').join('_')] = value
//             //             document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] hidden"
//             //         } else {
//             //             document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] block"
//             //         }

//             //     }
//             //     let body = {
//             //         rate: data,
//             //         association_rate: data
//             //     }
//                 // updateMetalRates(formValues)
//         }


//     }

//     const createMetalRates = async (body) => {
//         var response = await postAxiosWithToken({
//             url: metalRatesEndPoint,
//             body: body
//         })

//         if (response !== null) {
//             getData()
//             clearData()
//             toast.success(response.message)
//         }
//     }

//     const updateMetalRates = async (body) => {
//         var response = await putAxiosWithToken({
//             url: metalRatesEndPoint + currentMetalRates?.id + '/',
//             body: body
//         })

//         if (response !== null) {
//             getData()
//             clearData()
//             setFormMode('create')
//             toast.success(response.message)
//         }
//     }

//     const clearData = () => {
//         for (var item of purityListInput) {
//             document.getElementById(item.metal__metal_name?.split(' ').join('_') + "(" + item.purity_name?.split(' ').join('_') + ")" + "value").value = ""
//             document.getElementById(item.metal_name?.split(' ').join('_') + "(" + item.purity_name?.split(' ').join('_') + ")" + "error").className = "text-red-500 font-[400] text-[13px] hidden"
//         }
//     }

//     return (
//         <>
//             <div className="p-5 rounded-[10px] bg-menu-head-bg shadow mt-3 border">
//                 <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Metal Rates</p>
//                 <form className="grid 2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 gap-5 mt-4" onSubmit={handleSubmit}>
//                     {
//                         purityListInput.map((item, index) => (
//                             <div key={"input"+index} className="my-[5px]">
//                                 <p className="mb-[5px] text-[16px]">{item.metal__metal_name} ({item.purity_name}) INR</p>
//                                 <input
//                                     type="number"
//                                     step={0.01}
//                                     placeholder={`${item.metal__metal_name} (${item.purity_name}) INR`}
//                                     className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
//                                     name={item.metal_name?.split(' ').join('_') + "_" + item.purity_name?.split(' ').join('_')}
//                                     id={`${item.metal__metal_name?.split(' ').join('_')}(${item.purity_name?.split(' ').join('_')})value`}
//                                 />
//                                 <p id={`${item.metal_name?.split(' ').join('_')}(${item.purity_name?.split(' ').join('_')})error`} className="text-red-500 font-[400] text-[13px] hidden">{item.metal_name} ({item.purity_name}) INR is required</p>
//                             </div>
                            
//                         ))

                        
//                     }

//                     <div className="min-[423px]:flex gap-4 sm:mt-9 xl:mt-[35px]">
//                         <button
//                             type="submit"
//                             id="formsubmit"
//                             className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
//                         >save<span className="lowercase">(ctrl+s)</span></button>
//                         <button
//                             onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); }}
//                             className="max-[423px]:mt-[10px] capitalize bg-secondary text-primary-grey w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border"
//                         >Reset<span className="lowercase">(ctrl+e)</span></button>
//                     </div>
//                 </form>

//             </div>
//         </>
//     );
// }

import { useEffect, useState } from "react";
import { metalListEndPoint, metalRatesEndPoint, purityEndPoint, purityListEndPoint, todayMetalRatesEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setPagination, setTodayGoldRate, setTodaySilverRate } from "../../../redux/appconfigslice";

export default function MetalRatesForm({ getData, formMode, currentMetalRates, setFormMode, setCurrentMetalRates }) {

    const [purityListInput, setPurityListInput] = useState([]);

    const dispatch = useDispatch();

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
        } else if (event.ctrlKey && event.key === 'e') {
            event.preventDefault();
            clearData()
            setFormMode('create')
        }
    };

    useKeyPress(['s', 'e'], onKeyPress);

    useEffect(() => {
        getPurityListData()
    }, [])

    const getPurityListData = async () => {
        var response = await getAxios({
            url: purityListEndPoint
        })

        if (response !== null) {
            setPurityListInput(response.data.list)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (formMode === 'create') {
            var data = {}
            for (var item of purityListInput) {
                var value = document.getElementById(item.metal__metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"value").value
                
                if (value.length !== 0) {
                    data[item.metal__metal_name?.split(' ').join('_') + "_" + item.purity_name?.split(' ').join('_')] = value
                    document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] hidden"
                } else {
                    document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] block"
                }
                
            }
            let body = {
                rate: data,
                association_rate: data
            }
            createMetalRates(body)
        } else {
            var data = {}
            for (var item of purityListInput) {
                var value = document.getElementById(item.metal__metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"value").value
                
                if (value.length !== 0) {
                    data[item.metal__metal_name?.split(' ').join('_') + "_" + item.purity_name?.split(' ').join('_')] = value
                    document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] hidden"
                } else {
                    document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] block"
                }
                
            }
            let body = {
                rate: data,
                association_rate: data
            }
            // updateMetalRates(body)
        }
    }

    const createMetalRates = async (body) => {
        var response = await postAxiosWithToken({
            url: metalRatesEndPoint,
            body: body
        })

        if (response !== null) {
            getData()
            getTodayRates()
            clearData()
            toast.success(response.message)
        }
    }

    const updateMetalRates = async (body) => {
        var response = await putAxiosWithToken({
            url: metalRatesEndPoint + currentMetalRates?.id + '/',
            body: body
        })

        if (response !== null) {
            getData()
            getTodayRates()
            clearData()
            setFormMode('create')
            toast.success(response.message)
        }
    }

    const clearData = () => {
        for (var item of purityListInput) {
            document.getElementById(item.metal__metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"value").value = ""
            document.getElementById(item.metal_name?.split(' ').join('_')+"("+item.purity_name?.split(' ').join('_')+")"+"error").className = "text-red-500 font-[400] text-[13px] hidden"            
        }
    }

    const getTodayRates = async () => {
        var response = await getAxios({
            url: todayMetalRatesEndPoint
        })

        if (response !== null) {
            if (response.data.gold && response.data.gold > 0) {
                dispatch(setTodayGoldRate(response.data.gold))
            } 
            if (response.data.silver && response.data.silver > 0) {
                dispatch(setTodaySilverRate(response.data.silver))
            }
        }
    }

    return (
        <>
            <div>
                <form className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5" onSubmit={handleSubmit}>
                    {
                        purityListInput.map((item, index) => (
                            <div key={"input"+index} className="my-[5px]">
                                <p className="mb-[5px] text-[15px]">{item.metal__metal_name} ({item.purity_name}) INR</p>
                                <input
                                    type="number"
min="0"
                                    step={0.01}
                                    placeholder={`${item.metal__metal_name} (${item.purity_name}) INR`}
                                    className="sm:mb-[5px] border border-gray-300 w-full rounded-lg p-[10px] text-[17px] outline-none"
                                    name={item.metal_name?.split(' ').join('_') + "_" + item.purity_name?.split(' ').join('_')}
                                    id={`${item.metal__metal_name?.split(' ').join('_')}(${item.purity_name?.split(' ').join('_')})value`}
                                />
                                <p id={`${item.metal_name?.split(' ').join('_')}(${item.purity_name?.split(' ').join('_')})error`} className="text-red-500 font-[400] text-[13px] hidden">{item.metal_name} ({item.purity_name}) INR is required</p>
                            </div>
                            
                        ))

                        
                    }
                    <div className="min-[423px]:flex gap-4 sm:mt-9 xl:mt-[35px]">
                        <button
                        onClick={handleSubmit}
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                        <button
                            onClick={(e) => { e.preventDefault(); clearData(); setFormMode('create'); }}
                            className="max-[423px]:mt-[10px] capitalize bg-secondary text-black w-full rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >Reset<span className="lowercase">(ctrl+e)</span></button>
                    </div>
                </form>
                
            </div>
        </>
    );
}