import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { getAxios } from '../../../service/apiservice';
import { loanEndPoint } from '../../../service/api';
import { convertToDate } from '../../../utilities/datetime';

export default function AuctionPrintSave({ selectedLoan }) {


  const [emptyRows, setEmptyRows] = useState([1,2,3,4,5,6,7]);



  console.log("selectedLoan",selectedLoan)
  var tempdata = [];

  var templist = {
    "loanno": selectedLoan.loanno,
    "loan_date": selectedLoan.loan_date,
    "loan_amt": selectedLoan.loan_amt,
    "item_description": selectedLoan.item_description,
  };
  
  // Push the object templist into the tempdata array
  tempdata.push(templist);
  
  console.log("templist", templist);
  console.log("tempdata", tempdata); // This will show the array with templist inside
  
  useEffect(() => {
    createArray(tempdata?.length > 0 ? tempdata?.length : 0)
  },[tempdata])

  function createArray(N) {
    var len = 7 - N;
    setEmptyRows([...Array(len).keys()].map(i => i + 1));
}



  return (
    <div className='pb-5'>

      <div className='w-full flex justify-center'>
        <div className=' w-[1000px] border-2 border-black rounded-xl' >
          <div className='text-center'>
            <p className='text-[20px] font-bold '>ஏல முன் அறிவிப்பு</p>
          </div>

          <div className='text-center w-full flex justify-center'>
            <p className='text-[10px] w-[800px]'>கீழ்க்கண்ட அங்கு வணிகர் வசம் பட்டியலில் கண்டுள்ள பொருட்கள் அடகு வைக்கப்பட்டு, அடகு வணிக சட்டப்படி காலங்கடந்து விட்டபடியால், பட்டியலில் கண்டுள்ள அடகு நகைகளை</p>
          </div>

          <div className='flex gap-3 justify-center items-center mt-3'>
            <p className='text-[10px]'>நாள்</p>
            <p className='border-2 border-black  p-3 w-[150px] '></p>
            <p className='text-[10px]'>க்குள்</p>
          </div>

          <div className='text-center mt-2 w-full flex justify-center'>
            <p className='text-[10px] w-[800px]'>மீட்டுக்கொள்ள வேண்டியது. அவ்வாறு மேற்படி தேதிக்குள் மீட்கப்படாத அடகுப்பொருட்கள். அடகு வணிக சட்டப்படி, அரசாங்க அங்கீகாரம் பெற்ற ஏலதாரர் மூலம் ஏலம் போடப்படும்.</p>
          </div>

          <div className='w-full flex justify-center mt-3'>
            <div className='border-2 border-black text-center w-[400px]'>

              <p className='text-[12px] border-b-2 border-black p-2 font-semibold'>அடகு வணிகரின் பெயரும், முகவரியும்</p>

              <p className='text-[12px] font-semibold mt-2'>V. தேவேந்திரன்</p>
              <p className='text-[10px]'>நெ:2 மேட்டுத் தெரு திருக்கழுக்குன்றம் -603 109</p>

            </div>
          </div>

          <div className='flex justify-center mt-3'>
            <p className='text-[15px] border border-black rounded-xl w-[150px] text-center font-semibold'>நிபந்தனைகள்:</p>
          </div>

          <div className='w-full flex justify-center'>
            <div className='w-[800px]'>
              <p className='text-[10px] mt-2'>1.  பட்டியலில் கண்டுள்ள அடகு வைத்தியர்களுக்கு அறிவிக்கப்படுவது யாதெனில் மேற்படி அடகு வணிகர் வசம் பொருட்கள் அடகு வைக்கப்பட்டு, தமிழ்நாடு அடகு வணிகச்சட்டம் மற்றும் விதிகளின்படி அடகு பொருட்களை மீட்டுக்கொள்ள வேண்டிய காலத்தவணையான ஓர் ஆண்டு மற்றும் அனுவலத்தவணை 7 நாட்களும் சேர்த்து 372 நாட்கள் கடந்துவிட்டபடியால், அதிகார வரம்புக்கு உட்பட்ட கோட்டாட்சியர் அவர்களின் அனுமதியின் பேரில் அரசாங்க அங்கீகாரம் பெற்ற ஏலதாரர் மூலம் பட்டியலில் கண்டுள்ள அடகுப் பொருட்கள் ஏலம் போட்டு முடிவு செய்யவேண்டிய நிலையில் உள்ளது</p>
              <p className='text-[10px] mt-2'>2.  பட்டியலில் கண்டுள்ள அடகு வைத்தவர்களுக்கு மேலும் அறிவிக்கப்படுவது யாதெனில் இந்த அறிவிப்பு கிடைத்ததும் மேற்படி அடகு வணிகளுக்குச் சேரவேண்டிய அசல், வட்டி மற்றும் இதர செலவுகளை செலுத்தி அடகு வபாருட்களை மேற்படிதேதிக்குள் மீட்டுக்கொள்ளவேண்டியது. அவ்வாறு மேற்படி தேதிக்குள் மீட்கத் தவறும் பட்சத்தில் அட்கு வணிக சட்டப்படி அடகுப்
                பொருட்கள் ஏலம் போடப்பட்டு முடிவு செய்யப்படும்.</p>
              <p className='text-[10px] mt-2'>3.  மேலும் அறிவிக்கப்படுவது யாதெனில் ஏல சம்பந்தமாக இந்த அறிவிப்பே கடைசியாகும். அடகுப் பொருட்கள் ஏலம் போட்டு முடிவான பிறகு அடகு வைத்தவர்கள் பவ்வித குறை கூறினாலும் மேற்படி அடகு வணிகள் எந்ததைத்திலும் பொறுப்பாளியாாட்டார்.</p>
              <p className='text-[10px] mt-2'>4.  பட்டியலில் அச்சுப்பிழை இருந்தால் அடகு ரசிசிலுள்ளபடி ஒப்புக்கொள்ளப்படும். அடகு ரசீதை தவறாமல் உடன் கொண்டு விபரம்</p>
            </div>
          </div>

          <div className="mt-2 flex justify-center ">
            <div className="h-[203px] w-[800px] border border-black">
              <table className="w-full">
                <thead className=" text-[15px] uppercase font-[700]">
                  <tr className='border-b border-black'>
                    <td className="border-r p-[2px] min-w-[20px] border-black px-2 text-[14px] text-center">
                      நெ.
                    </td>
                    <td className="border-r p-[1px] min-w-[115px] border-black px-2 text-[14px] text-center">
                      தேதி
                    </td>
                    <td className="border-r p-[1px] min-w-[115px] border-black px-2 text-[14px] text-center">
                      L. No.
                    </td>
                    <td className="border-r p-[1px] min-w-[115px] border-black px-2 text-[14px] text-center">
                      அடகுத்தொகை
                    </td>
                    <td className="p-[1px] min-w-[300px] border-black px-2 text-[14px] text-center">
                      விபரம்
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {tempdata.map((item, index) => (
                    <tr>
                      <td className="p-[2px] min-w-[20px] px-2 text-[14px] border-r border-black">
                        {index + 1}
                      </td>
                      <td className="p-[2px] min-w-[115px] px-2 text-[14px] border-r border-black">
                        {item.loan_date}
                      </td>
                      <td className="p-[2px] min-w-[115px] px-2 text-[14px] border-r border-black">
                        {item.loanno}
                      </td>
                      <td className="p-[2px] min-w-[115px] px-2 text-[14px] border-r border-black">
                        {item.loan_amt}
                      </td>
                      <td className="p-[2px] min-w-[300px] px-2 text-[14px]">
                        {item.item_description}
                      </td>

                    </tr>
                  ))}
                  {
                    emptyRows.map((item, index) => (
                      <tr>
                        <td className="p-[2px] min-w-[20px] px-2 text-[14px] border-r border-black">
                          <div className='invisible'>{index + 1 + tempdata?.length}</div>
                        </td>
                        <td className="p-[2px] min-w-[115px] px-2 text-[14px] border-r border-black">

                        </td>
                        <td className="p-[2px] min-w-[115px] px-2 text-[14px] border-r border-black">

                        </td>
                        <td className="p-[2px] min-w-[115px] px-2 text-[14px] border-r border-black">

                        </td>
                        <td className="p-[2px] min-w-[300px] px-2 text-[14px]">

                        </td>
                      </tr>
                    ))
                  } 
                </tbody>
              </table>
            </div>

          </div>


          <div className='text-center mt-3'>

            <p className='text-[18px] font-semibold'>இங்ஙனம், மேற்படி அடகு வணிகர்</p>
          </div>
          <div className='w-full flex justify-center'>
            <div className='w-[800px]'>
              <div className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2'>

                <div className='mt-3'>

                  <p className='text-[18px] mt-3'>Register-Post</p>

                  <p className='text-[18px] mt-3'>If undelivered please return it to:</p>

                  <div className='text-center mt-5'>
                    <p className='text-[15px] font-bold'>V. தேவேந்திரன்</p>
                    <p className='text-[15px]'>அடகு வியாபாரம்</p>
                    <p className='text-[15px]'>நெ:2 மேட்டுத் தெரு</p>
                    <p className='text-[15px]'> திருக்கழுக்குன்றம் -603 109</p>
                  </div>

                  <div className='text-center mt-5'>
                    <p className='text-[18px] font-bold'>சோலேட்டி தேவா ஜூவல்லர்ஸ்</p>
                  </div>


                </div>



                <div className='mt-3 border-l-2 border-black pl-5'>
                  <div>
                    <p className='text-[18px] mt-3'>To:</p>
                    <p className='text-[18px] mt-3'>Sir,</p>
                  </div>


                  {/* <div className='pl-[100px]'>
                    <p>{overduedeatils.loan_list?.customer_name}</p>
                    <p>{overduedeatils.loan_list?.address}</p>
                    <p>Phone:  {overduedeatils.loan_list?.mobile}</p>
                    <p>Pincode:  {overduedeatils.loan_list?.pincode}</p>
                  </div> */}
                </div>

              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  )
}
