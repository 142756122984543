import React, { useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import { deliveryEndPoint, loanEndPoint } from "../../../../../service/api";
import { getAxios } from "../../../../../service/apiservice";
import ReactToPrint from "react-to-print";

export default function LoanDeliveryPrint() {
  const navigate = useNavigate();
  let componentRef = useRef();

  const [loandeatils, setLoandeatils] = useState({});
  const { id } = useParams();

  useEffect(() => {
    getLoanDeatilsPrint(id);
  }, [id]);

  const getLoanDeatilsPrint = async (id) => {
    var respose = await getAxios({
      url: deliveryEndPoint + id + "/",
    });

    if (respose !== null) {
      setLoandeatils(respose.data);
    }
  };

  return (
    <div className="pb-5">
      <div className="flex justify-end gap-5">
        <button
          onClick={() => {
            navigate("/Loan-Management/Manage-Delivery");
          }}
          className="capitalize bg-menu-head-bg border text-black w-[100px] sm:w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "

        >
          Back
        </button>

        <div>
         
          <ReactToPrint
            trigger={() => (
              <button className="capitalize mb-[50px] bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto">
                Print
              </button>
            )}
            content={() => componentRef}
          />
        </div>
      </div>

      <div className="mx-[10px]" ref={(el) => (componentRef = el)}>
        <div className="border-b-2 border-dashed border-gray-600 mt-[10px]">
          <div className="text-center">
            <p className="font-bold text-[16px]">
              {loandeatils?.loan_list?.firm_name}
            </p>
            <p className="text-[14px]">
              3/1105, Thiruvallur Road Sunguvarchatram,
              <br /> Tamil Nadu, 602106.
              <br />
              Phone:9790986668 <br /> Lic No:02/2020-2021
            </p>
          </div>

          <div className="mt-5 border-2 border-black">
            <div className="flex justify-between p-2 border-b-2 border-black">
              <p className="text-[14px]">Delivery Details</p>

              <p className="text-[14px]">(Pawner Copy)</p>
            </div>

            <div className="p-1 flex justify-between border-b-2 border-black ">
              <table>
                <thead className="p-1">
                  <tr className="flex gap-5">
                    <td className="w-[100px]">
                      <p className="text-[14px]">Date:</p>
                    </td>
                    <td className="w-[300px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.received_date}</p>
                    </td>
                  </tr>
                  <tr className="flex gap-5">
                    <td className="w-[100px]">
                      <p className="text-[14px]">Loan No:</p>
                    </td>

                    <td className="w-[300px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.loanno}</p>
                    </td>
                  </tr>
                  <tr className="flex gap-5">
                    <td className="w-[100px]">
                      <p className="text-[14px]">Phone No:</p>
                    </td>
                    <td className="w-[300px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.mobile}</p>
                    </td>
                  </tr>
                </thead>
              </table>

              <table>
                <thead className="p-2">
                  <tr className="flex gap-5">
                    <td className="w-[150px]">
                      <p className="text-[14px]">Name:</p>
                    </td>
                    <td className="w-[500px]">
                      <p className="capitalize text-[14px]">{loandeatils?.delivery_list?.customer_name}</p>
                    </td>
                  </tr>
                  
                  <tr className="flex gap-5">
                    <td className="w-[150px]">
                      <p className="text-[14px]">Address:</p>
                    </td>
                    <td className="w-[500px]">
                      <p className="capitalize text-[14px]">{loandeatils?.delivery_list?.address}</p>
                    </td>
                  </tr>
                  <tr className="flex gap-5">
                    <td className="w-[150px]">
                      <p className="text-[14px]">Village Name:</p>
                    </td>
                    <td className="w-[500px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.village_name}</p>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="p-2">
              <p className="font-bold text-[14px]">Loan Details</p>

              <div className="mt-3 flex justify-center ">
              <div className="h-[280px] w-full border border-black">
                <table className="w-full">
                  <thead className="border border-black text-[15px] uppercase font-[700]">
                    <tr>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px] ">
                        S No
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Type
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Item Name
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Pcs
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Gwt
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Swt
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Nwt
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Remarks
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {loandeatils?.iteam_details?.map((item, index) => (
                      <tr>
                        <>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {index + 1}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2  capitalize text-[14px]">
                            {item.metal__metal_name}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2  capitalize text-[14px]">
                            {item.item__item_print}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.noofpieces}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.gross_weight}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.stone_weight}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.net_weight}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.metal__metal_remarks}
                          </td>
                        </>
                      </tr>
                    ))}

                  </tbody>
                </table>
                </div>
              </div>

              {/* <div className="mt-3 flex justify-between">
                <p className="font-bold text-[15px]">Loan Amount</p>
                <p className="font-bold text-[15px]">
                  Rs.{loandeatils?.loan_list?.loan_amt}
                </p>
              </div> */}
            </div>
          </div>

          <div className="mt-[40px] flex justify-between pb-5">
            <p className="text-[14px]">Pawner Signature</p>
            <p className="text-[14px]">Authorises Signature</p>
          </div>
        </div>

        <div className="border-b-2 border-dashed border-gray-600 mt-[30px]">
          <div className="text-center">
            <p className="font-bold text-[20px]">
              {loandeatils?.loan_list?.firm_name}
            </p>
            <p className="text-[14px]">
              3/1105, Thiruvallur Road Sunguvarchatram,
              <br /> Tamil Nadu, 602106.
              <br />
              Phone:9790986668 <br /> Lic No:02/2020-2021
            </p>
          </div>

          <div className="mt-2 border-2 border-black">
            <div className="flex justify-between p-2 border-b-2 border-black">
              <p className="text-[14px]">Customer Details</p>

              <p className="text-[14px]">(Firm Copy)</p>
            </div>

            <div className="p-2 flex justify-between border-b-2 border-black ">
              <table>
                <thead className="p-2">
                  <tr className="flex gap-5">
                    <td className="w-[100px]">
                      <p className="text-[14px]">Date:</p>
                    </td>
                    <td className="w-[300px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.received_date}</p>
                    </td>
                  </tr>
                  <tr className="flex gap-5">
                    <td className="w-[100px]">
                      <p className="text-[14px]">Loan No:</p>
                    </td>

                    <td className="w-[300px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.loanno}</p>
                    </td>
                  </tr>
                  <tr className="flex gap-5">
                    <td className="w-[100px]">
                      <p className="text-[14px]">Phone No:</p>
                    </td>
                    <td className="w-[300px]">
                      <p className="text-[14px]">{loandeatils?.delivery_list?.mobile}</p>
                    </td>
                  </tr>
                </thead>
              </table>

              <table>
                <thead className="p-2">
                  <tr className="flex gap-5">
                    <td className="w-[150px]">
                      <p className="text-[14px]">Name:</p>
                    </td>
                    <td className="w-[500px]">
                      <p className="capitalize text-[14px]">{loandeatils?.delivery_list?.customer_name}</p>
                    </td>
                  </tr>
                  
                  <tr className="flex gap-5">
                    <td className="w-[150px]">
                      <p className="text-[14px]">Address:</p>
                    </td>
                    <td className="w-[500px]">
                      <p className="capitalize text-[14px]">{loandeatils?.delivery_list?.address}</p>
                    </td>
                  </tr>
                  <tr className="flex gap-5">
                    <td className="w-[150px]">
                      <p className="text-[14px]">Village Name:</p>
                    </td>
                    <td className="w-[500px]">
                      <p className="capitalize text-[14px]">{loandeatils?.delivery_list?.village_name}</p>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>

            <div className="p-2">
              <p className="font-bold text-[14px]">Loan Details</p>

              <div className="mt-2 flex justify-center ">
              <div className="h-[280px] w-full border border-black">
                <table className="w-full">
                  <thead className="border border-black text-[15px] uppercase font-[700]">
                    <tr>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        S No
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Type
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Item Name
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Pcs
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Gwt
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Swt
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Nwt
                      </td>
                      <td className="p-[1px] min-w-[115px] border border-black px-2 text-[14px]">
                        Remarks
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    {loandeatils?.iteam_details?.map((item, index) => (
                      <tr>
                        <>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {index + 1}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]  capitalize">
                            {item.metal__metal_name}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] capitalize">
                            {item.item__item_print}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.noofpieces}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.gross_weight}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.stone_weight}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.net_weight}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                            {item.metal__metal_remarks}
                          </td>
                        </>
                      </tr>
                    ))}
                   
                  </tbody>
                </table>
                </div>

              </div>

              {/* <div className="mt-3 flex justify-between">
                <p className="font-bold text-[15px]">Loan Amount</p>
                <p className="font-bold text-[15px]">
                  Rs.{loandeatils?.loan_list?.loan_amt}
                </p>
              </div> */}
            </div>
          </div>

          <div className="mt-[40px] flex justify-between pb-5">
            <p className="text-[14px]">Pawner Signature</p>
            <p className="text-[14px]">Authorises Signature</p>
          </div>
        </div>

      
      </div>
    </div>
  );
}
