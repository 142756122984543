import React, { useEffect, useRef, useState } from "react";


export default function TransactionPrint({data}) {




  return (
    <div>
      <div className="px-[10px] mt-[50px]">
        <div >
          <table class="min-w-full bg-white border border-gray-300">
            <thead>
              <tr>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                   Sno
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                   Loan Date
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Loan No 
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                 Firm Name 
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                 Phone 
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Name
                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                 Branch 

                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                 Debit Cash 

                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Credit Cash 

                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                 Debit Bank 

                </th>
                <th class="p-[10px] text-[12px] min-w-[40px] border border-gray-300 bg-gray-100 px-2 py-2 text-left">
                Credit Bank 

                </th>
              </tr>
            </thead>
            <tbody>

            {data.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                data?.map((item, index) => (


              <tr class="bg-gray-50">
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">
                {index+1}
                </td>
                <td class="p-[10px] text-[10px] min-w-[80px] border border-gray-300 px-2 py-2">{item.LoanDate}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Loanno}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.FirmName}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.Mobile}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.CustomerName}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.BranchName}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.DebitCash}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.CreditCash}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.DebitBank}</td>
                <td class="p-[10px] text-[10px] min-w-[40px] border border-gray-300 px-2 py-2">{item.CreditBank}</td>
              </tr>
                 ))
                )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
