import { useEffect, useRef, useState } from "react";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
    DayBookReportsListEndPoint,
  daywiseReportsListEndPoint,
 
} from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { object } from "yup";
import { ExportToPDF } from "../../../components/ExportToPdf";
import ReactToPrint from "react-to-print";
import DayBookReportsFilter from "./daybookreportsfilter";
import DayBookReportsPrint from "./daybookreportsprint";
import { convertToTime } from "../../../utilities/time";

export default function DayBookReports() {
  const navigate = useNavigate();
  let componentRef = useRef();

  const searchRef = useRef();
  const fileName = "Day Wise Interest Report";
  const [daybookReportEntryList, setDayBookReportEntryList] = useState([]);
  const [currentPurchase, setCurrentPurchase] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [excelViewData, setExcelViewData] = useState([]);

  const [dayBookReportExcel, setDayBookReportExcel] = useState([]);


  const [transactionReport, setTransactionReport] = useState({});

  console.log("transactionReport",transactionReport)


  const [excelOpen, setExcelOpen] = useState(false);

  function closeExcelModal() {
    setExcelOpen(false);
  }

  const [tableFilters, setTableFilters] = useState({
    search: "",
    paymenttypes: null,
    from_date: null,
    to_date: null,
    firm: null,
    branch: localStorage.getItem("branch") == "true" ? null : undefined,
    items_per_page: JSON.parse(localStorage.getItem("pagination")) ? 10 : null,
    page: 1,
  });

  const { actions, pagination } = useSelector((state) => state.appConfig);

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === "f") {
      event.preventDefault();
      if (
        document.getElementById("filteroptions").className === "my-[25px] block"
      ) {
        document.getElementById("filteroptions").className = "my-[25px] ";
      } else {
        document.getElementById("filteroptions").className = "my-[25px] block";
        searchRef.current?.focus();
      }
    } else if (event.ctrlKey && event.key === "m") {
      event.preventDefault();
      localStorage.setItem("user_purchaseentry_mode", "create");
      navigate("loan-form");
    }
  };

  useKeyPress(["f", "m"], onKeyPress);

  useEffect(() => {
    if (daybookReportEntryList.length > 0) {
      let tempData = [];
      for (var i of daybookReportEntryList) {
        var ExcelValue = {
          LoanNo: i.loanno,
          FirmName: i.firm_name,
          Mobile: i.mobile,
          CustomerName: i.customer_name,
          LoanDate: i.loan_date,
          DebitCash: i.debit_cash,
          CreditCash: i.credit_cash,
          DebitBank: i.debit_bank,
          CreditBank: i.credit_bank,
        };

        tempData.push(ExcelValue);
      }
      setDayBookReportExcel(tempData);
    }
  }, [daybookReportEntryList]);

  useEffect(() => {
    if (dayBookReportExcel.length > 0) {
      let firstData = Object.keys(dayBookReportExcel[0]);
      let row = [];
      for (var i of firstData) {
        row.push({
          value: i,
        });
      }

      setExcelViewData(row);
    }
  }, [dayBookReportExcel]);

  useEffect(() => {
    getDayBookReportListData(tableFilters);
  }, [tableFilters]);

  const getDayBookReportListData = async (body) => {
    var response = await postAxiosWithToken({
      url: DayBookReportsListEndPoint,
      body: body,
    });

    if (response !== null) {
      setDayBookReportEntryList(response.data.list);
      setTransactionReport(response);
      setIsLoading(false);
      setTotalPages(response.data.total_pages);
    }
  };

  const pageHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  return (
    <>
      <div className="mt-[100px]">
        <div className="grid xl-grid-cols-3 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 max-[638px]:grid-cols-2 gap-3 mx-auto">
         <div className="mx-auto">
          <ExcelViewer
            title={fileName}
            data={dayBookReportExcel}
            headers={excelViewData}
            enabled={excelOpen}
            setEnabled={setExcelOpen}
            closeModal={closeExcelModal}
          />
         </div>
         <div className="mx-auto">
          <ExportToExcel apiData={dayBookReportExcel} fileName={fileName} />
         </div>
         <div className="mx-auto">
          <ExportToPDF apiData={dayBookReportExcel} fileName={fileName} />
         </div>

          
          <div className="mx-auto">
            <ReactToPrint
              trigger={() => (
                <button className="flex items-center bg-primary-btn p-2 text-white rounded-md gap-4">
                  Print
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M16 8.616v-3H8v3H7v-4h10v4zm-11.423 1h14.846zm13.038 2.5q.425 0 .713-.288t.287-.712t-.287-.713t-.713-.288t-.712.288t-.288.713t.288.712t.713.288M16 19v-4.538H8V19zm1 1H7v-4H3.577v-5.384q0-.85.577-1.425t1.423-.576h12.846q.85 0 1.425.576t.575 1.424V16H17zm2.423-5v-4.384q0-.425-.287-.713t-.713-.288H5.577q-.425 0-.712.288t-.288.713V15H7v-1.538h10V15z"
                    />
                  </svg>
                </button>
              )}
              content={() => componentRef}
            />
          </div>



          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
            <p>Total Debit Cash</p>
            <p>Rs. {parseFloat(transactionReport.total_cash_debit).toFixed(2)}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
            <p>Total Credit Cash</p>
            <p>Rs. {parseFloat(transactionReport.total_cash_credit).toFixed(2)}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
            <p>Total Debit Online</p>
            <p>Rs. {parseFloat(transactionReport.total_Debit_online).toFixed(2)}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1 mx-auto">
          <p>Total Credit Online</p>
            <p>Rs. {parseFloat(transactionReport.total_Credit_online).toFixed(2)}</p>
          </div>




          
        </div>

        <div className="hidden">
          <div ref={(el) => (componentRef = el)}>
            <DayBookReportsPrint data={dayBookReportExcel} />
          </div>
        </div>

        <div id="filteroptions" className="my-[5px] ">
          <p className="font-[600] text-[18px]">Filters</p>
          <DayBookReportsFilter
            filters={tableFilters}
            searchRef={searchRef}
            setFilters={setTableFilters}
          />
        </div>
        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
          <table className="w-full">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[150px] ">Loan No</td>
                <td className="p-[10px] min-w-[150px]">Firm Name</td>
                <td className="p-[10px] min-w-[180px]">Mobile</td>
                <td className="p-[10px] min-w-[180px]">Customer Name</td>
                <td className="p-[10px] min-w-[180px]">Loan Date</td>
                <td className="p-[10px] min-w-[180px]">Debit Cash</td>
                <td className="p-[10px] min-w-[180px]">Credit Cash</td>
                <td className="p-[10px] min-w-[180px]">Debit Online</td>
                <td className="p-[10px] min-w-[180px]">Credit Online</td>
                <td className="p-[10px] min-w-[150px]">Create Date</td>

              
                
              </tr>
            </thead>
            <tbody className="text-primary-grey bg-[#fff]">
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={11}>
                    Loading...
                  </td>
                </tr>
              ) : daybookReportEntryList.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                daybookReportEntryList?.map((item, index) => (
                  <tr key={item.id} className="border">
                    <td className="p-[10px]">{index + 1}</td>
                    <td className="p-[10px] bg-[#ffebcd]">{item.loanno}</td>
                    <td className="p-[10px] capitalize">{item.firm_name}</td>
                    <td className="p-[10px]">{item.mobile}</td>
                    <td className="p-[10px] capitalize">{item.customer_name}</td>
                    <td className="p-[10px]">{item.loan_date}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.debit_cash).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.credit_cash).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.debit_bank).toFixed(2)}</td>
                    <td className="p-[10px]">Rs. {parseFloat(item.credit_bank).toFixed(2)}</td>
                    <td className="p-[10px] capitalize">
                       <p>{convertToDate(item.created_at)}</p>
                       <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p>
                       </td>

                    
                    
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {pagination === true ? (
          <div className="pb-5">
            <Pagination
              totalPages={totalPages}
              currentPage={tableFilters.page}
              setCurrentPage={pageHandler}
              entries={tableFilters.items_per_page}
              entriesHandler={entriesHandler}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
