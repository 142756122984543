import React, { useEffect, useState } from "react";
import {
  getAxios,
  postAxiosWithToken,
  putAxiosWithToken,
} from "../../../service/apiservice";
import {
  branchListEndPoint,
  ConfigurationListEndPoint,
  deliveryEndPoint,
  paymentModedropDownEndPoint,
  paymentTypeDropDownEndPoint,
  searchLoanNumberEndPoint,
  searchMobileNumberEndPoint,
} from "../../../service/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function LoanDeliveryform() {
  const [loanNumber, setLoanNumber] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [loanDetails, setLoanDetails] = useState({});
  const [interestAmount, setInterestAmount] = useState(0);
  const [interestoccured, setInterestOccured] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalMonth, setTotalMonth] = useState(null);
  const [loanAmount, setLoanAmount] = useState(0);
  // const [advanceAmount, setAdvanceAmount] = useState(0);
  // const [intrestCredit, setIntrestCredit] = useState(0);
  const [netAmount, setNetAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [releaseCharge, setReleaseCharge] = useState(0);
  const [errors, setErrors] = useState({});
  const [formMode, setFormMode] = useState("create");
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setselectedBranch] = useState("");
  const [remarks, setRemarks] = useState("");

  const [paymentAmounts, setPaymentAmounts] = useState({});
  const [Intimationcharge, setIntimationcharge] = useState();

  const [releseproof, setReleseproof] = useState();

  console.log("releseproof",releseproof)

  const [maxMonth, setMaxMonth] = useState(null);
  const [paymentlable, setPaymentLable] = useState();


  const [paidAmount, setPaidAmount] = useState();

  // const [paymentTypeDropdown, setPaymentTypeDropdown] = useState([]);
  const [cash, setCash] = useState(0);
  const [bank, setBank] = useState(0);

  const [paymentModeDropdown, setPaymentModeDropdown] = useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  // const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [paymentValues, setPaymentValues] = useState([]);

  const [selectedImages, setSelectedImages] = useState();


  console.log("paymentValues", paymentValues)

  const [delivery_print, setDeliveryPrint] = useState(0);


  const navigate = useNavigate();

  useEffect(() => {
    // getPaymentTypeDropDown();
    getConfigurationData()
    getPaymentModeDropDown();
    getBranchDropdownData();
    setFormMode(localStorage.getItem("loan_delivery_mode"));

    if (localStorage.getItem("loan_delivery_mode") === "update") {
      getDeliveryDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedPaymentMode == 3) {
      setCash(cash);
      setBank(bank);
    } else if (selectedPaymentMode == 1) {
      setCash(netAmount);
    } else if (selectedPaymentMode == 2) {
      setBank(netAmount);
    }

  }, [selectedPaymentMode, netAmount]);


  useEffect(() => {
    let data = [...paymentValues];
    Object.entries(paymentAmounts).map((item) => {
      if (item[0].includes('payment')) {

        for (var i in data) {
          if (data[i].id.toString() == item[0].slice(7, item[0].length)) {
            data[i]['value'] = item[1];
          }
        }
      }
    })

    setPaymentValues(data)
  }, [paymentAmounts])


  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    if (!selectedBranch && localStorage.getItem("branch") === "true") {
      newErrors.id_branch = "Branch is required";
      isValid = false;
    }

    if (!totalMonth) {
      newErrors.total_month = "Total Months is required";
      isValid = false;
    }


    // if (selectedPaymentMode == 1 || selectedPaymentMode == 3 ) {
    //   if (!cash || cash <= 0) {
    //     newErrors.cash = "Cash amount should be greater than zero";
    //     isValid = false;
    //   }
    // }

    // if (selectedPaymentMode == 2 || selectedPaymentMode == 3) {
    //   if (!bank || bank <= 0) {
    //     newErrors.bank = "Bank amount should be greater than zero";
    //     isValid = false;
    //   }
    // }

    setErrors(newErrors);
    return isValid;
  };






  const handleCashChange = (e) => {
    const value = parseFloat(e.target.value || 0);
    const remainingAmount = netAmount - value;
    setCash(e.target.value);
    setBank(remainingAmount);
    setCash(parseFloat(e.target.value || 0));
  };

  const handleBankChange = (e) => {
    const value = parseFloat(e.target.value || 0);
    const remainingAmount = netAmount - value;
    setBank(e.target.value);
    setCash(remainingAmount);
    setBank(parseFloat(e.target.value || 0));
  };

  // const getPaymentTypeDropDown = async () => {
  //   var respose = await getAxios({
  //     url: paymentTypeDropDownEndPoint,
  //   });

  //   if (respose !== null) {
  //     setPaymentTypeDropdown(respose.data.list);
  //   }
  // };

  // const getPaymentModeDropDown = async () => {
  //   var respose = await getAxios({
  //     url: paymentModedropDownEndPoint,
  //   });

  //   if (respose !== null) {
  //     setPaymentModeDropdown(respose.data.list);
  //     var tempList = [];
  //     for (var i of respose.data.list) {
  //       var dictData = i
  //       dictData['error'] = ""
  //       dictData['value'] = ""
  //       tempList.push(dictData);
  //     }
  //     setPaymentValues(tempList)



  //   }
  // };

  const getPaymentModeDropDown = async () => {
    var respose = await getAxios({
      url: paymentModedropDownEndPoint,
    });

    if (respose !== null) {
      setPaymentModeDropdown(respose.data.list);
      var tempList = [];

      var values = [];
      Object.keys(paymentAmounts).map((item) => {
        if (item.includes("payment")) {
          values.push(item);
        }
      });

      if (values.length > 0) {
        Object.entries(paymentAmounts).map((item) => {
          if (item[0].includes("payment")) {
            for (var i of respose.data.list) {
              if (i.id.toString() == item[0].slice(7, item[0].length)) {
                var dictData = i;
                dictData["error"] = "";
                dictData["value"] = item[1];
                tempList.push(dictData);
              }
            }
          }
        });
      } else {
        for (var i of respose.data.list) {
          var dictData = i;
          dictData["error"] = "";
          dictData["value"] = "";
          tempList.push(dictData);
        }
      }
      setPaymentValues(tempList);
    }
  };


  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchList(response.data.list);
    }
  };

  // const handleSearch = async (mobile) => {
  //   var response = await postAxiosWithToken({
  //     url: searchMobileNumberEndPoint,
  //     body: {
  //       mobile: mobile,
  //     },
  //   });

  //   if (response != null) {
  //     toast.success(response.message);

  //     setLoanNumber(response.data);
  //   }
  // };


  const LoanNumber = async (loanno) => {
    var response = await postAxiosWithToken({
      url: searchLoanNumberEndPoint,
      body: {
        loanno: loanno,
      },
    });

    if (response != null) {
      toast.success(response.message);
      setselectedBranch(response.data.loan_list.id_branch);
      setLoanDetails(response.data);
    }
  };

 
 
 
 
  // const LoanNumber = async (loanno) => {
  //   if (!loanno) {
  //     // Reset all states to zero when no loan number is selected
  //     setTotalMonth(0);
  //     setMaxMonth(0);
  //     setInterestAmount(0);
  //     setTotalAmount(0);
  //     setInterestOccured(0);
  //     setLoanAmount(0);
  //     setNetAmount(0);
  //     setIntimationcharge(0);
  //     setselectedBranch(null);
  //     setLoanDetails(null);
  //     return;
  //   }
  
  //   // Fetch loan details if a loan number is provided
  //   const response = await postAxiosWithToken({
  //     url: searchLoanNumberEndPoint,
  //     body: { loanno },
  //   });
  
  //   if (response != null) {
  //     toast.success(response.message);
  //     setselectedBranch(response.data.loan_list.id_branch);
  //     setLoanDetails(response.data);
  //   }
  // };
  
 
 
 
 
 
 
 
 
 
 
 
 
 
  useEffect(() => {
    if (loanDetails?.loan_list) {
      setTotalMonth(loanDetails.loan_list.total_month || 0);
      setMaxMonth(loanDetails.loan_list.total_month || 0);
      setInterestAmount(loanDetails.loan_list.interest_amount || 0);
      setTotalAmount(loanDetails.loan_list.distribute_amt || 0);
      setInterestOccured(loanDetails.loan_list.interest_occured || 0);
      setLoanAmount(loanDetails.loan_list.loan_amt || 0);
      setNetAmount(loanDetails.loan_list.distribute_amt || 0);
      setIntimationcharge(loanDetails.loan_list.intimation_charge || 0);
    } else {
      // Reset all states to zero if loanDetails is null
      setTotalMonth(0);
      setMaxMonth(0);
      setInterestAmount(0);
      setTotalAmount(0);
      setInterestOccured(0);
      setLoanAmount(0);
      setNetAmount(0);
      setIntimationcharge(0);
    }
  }, [loanDetails]);
  

 

  console.log(Intimationcharge, "Intimationcharge")


  useEffect(() => {
    // Parse and assign default values
    const loan = parseFloat(loanAmount) || 0;
    const interest = parseFloat(interestAmount) || 0;
    const months = parseFloat(totalMonth) || 0;
    const intimationCharge = parseFloat(Intimationcharge) || 0;
    const discount = parseFloat(discountAmount) || 0;
    const release = parseFloat(releaseCharge) || 0;
    const cashPaid = parseFloat(cash) || 0;
    const bankPaid = parseFloat(bank) || 0;
  
    // Calculate interest occurred
    const calculatedInterestOccured = interest * months;
    setInterestOccured(calculatedInterestOccured);
  
    // Calculate total amount based on loan amount, interest, and intimation charge
    const calculatedTotalAmount = (loan - interest) + calculatedInterestOccured + intimationCharge;
    setTotalAmount(calculatedTotalAmount);
  
    // Calculate net amount with discount and release charge adjustments
    const calculatedNetAmount = (calculatedTotalAmount - discount) + release;
    setNetAmount(calculatedNetAmount);
  
    // Calculate paid amount based on cash and bank inputs
    const calculatedPaidAmount = cashPaid + bankPaid;
    setPaidAmount(calculatedPaidAmount);
  
    // For debugging: check calculated values in console
    console.log(calculatedNetAmount, "calculatedNetAmount");
    console.log(calculatedPaidAmount, "calculatedPaidAmount");
  }, [
    totalMonth,
    interestAmount,
    loanAmount,
    discountAmount,
    releaseCharge,
    cash,
    bank,
    Intimationcharge, // Ensure Intimationcharge is included in dependencies
  ]);
  


  const getConfigurationData = async () => {
    var response = await getAxios({
      url: ConfigurationListEndPoint,
    });

    if (response !== null) {
      setDeliveryPrint(response.data.delivery_print);
    }
  };




  const onValueUpdate = (e) => {
    const { name, value } = e.target;

    if (name === "total_month") {
      const intValue = parseInt(value || 0);
      setTotalMonth(intValue);

      if (intValue > parseInt(maxMonth || 0)) {
        setTotalMonth(parseInt(maxMonth || 0));
        setErrors((prevState) => ({
          ...prevState,
          total_month: `Total Months cannot be greater than ${maxMonth}`,
        }));
      } else {
        setTotalMonth(intValue);
        setErrors((prevState) => ({
          ...prevState,
          total_month: prevState.total_month && "",
        }));
      }
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [name]: value.length === 0 ? `${name} field is required` : "",
      }));
    }
  };

  const getDeliveryDetails = async () => {
    var response = await getAxios({
      url: deliveryEndPoint + localStorage.getItem("delivery_form_id") + "/",
    });

    if (response !== null) {


      // setLoanDetails(response?.data)
      setLoanDetails(response?.data);
      setMobileNumber(response?.data?.delivery_list?.mobile);
      setselectedBranch(response?.data?.delivery_list?.id_branch);
      // setSelectedPaymentType(response?.data?.delivery_list?.paymenttype);
      setSelectedPaymentMode(response?.data?.delivery_list?.payment_mode);
      setRemarks(response?.data?.delivery_list?.remarks);
      setCash(response?.data?.delivery_list?.cash);
      setBank(response?.data?.delivery_list?.bank);
      setDiscountAmount(response?.data?.delivery_list?.discount);
      setReleaseCharge(response?.data?.delivery_list?.release_charge);
      setPaymentAmounts(response?.data?.delivery_list?.paymentmode_list)
      setIntimationcharge(response?.data?.loan_list?.intimation_charge)

      setReleseproof(response?.data?.delivery_list?.release_proof)
    }
  };



  // useEffect(() => {
  //   const loginDataString = localStorage.getItem("login");
  //   if (loginDataString) {
  //     const loginData = JSON.parse(loginDataString);

  //     setDeliveryPrint(loginData.delivery_print);
  //   } else {
  //     console.log("No login data found in localStorage");
  //   }
  // }, []); 

  const handleCreateDelivery = (e) => {
    e.preventDefault();


    let data = [...paymentValues];
    for (var i in paymentValues) {
      if (paymentValues[i]['value'].length == 0) {
        data[i]['error'] = paymentValues[i].mode_name + "field is required"
      }
    }
    setPaymentValues(data)

    console.log("data>>>>>>>>>>>>>", data)

    if (!validateForm()) {
      return;
    }



    const formDataObject = {};
    const formData = new FormData(e.target);

    console.log("formData??????", formData)

    formData.forEach((value, key) => {
      if (formDataObject[key]) {
        if (!Array.isArray(formDataObject[key])) {
          formDataObject[key] = [formDataObject[key]];
        }
        formDataObject[key].push(value);
      } else {
        formDataObject[key] = value;
      }
    });

    var formValue =  new FormData(document.getElementById('allDelivery'))
    formValue.append("loanno",loanDetails?.loan_list?.loanno)
    formValue.append("customer",loanDetails?.loan_list?.customer)
    formValue.append("loan",loanDetails?.loan_list?.id)
    formValue.append("distribute_amt",loanDetails?.loan_list?.distribute_amt)
    formValue.append("interest_occured",interestoccured)
    formValue.append("net_amount",netAmount)

    //  formDataObject.paid_amount = paidAmount;
    formDataObject.loanno = loanDetails?.loan_list?.loanno;
    formDataObject.customer = loanDetails?.loan_list?.customer;
    formDataObject.loan = loanDetails?.loan_list?.id;
    formDataObject.distribute_amt = loanDetails?.loan_list?.distribute_amt;
    formDataObject.interest_occured = interestoccured;
    // formDataObject.interest_credit = intrestCredit;
    formDataObject.net_amount = netAmount;

    if (localStorage.getItem("loan_delivery_mode") === "create") {
      createDelivery(formValue);
    } else {
      updateDelivery(formValue);
    }
  };

  const createDelivery = async (body) => {
    var response = await postAxiosWithToken({
      url: deliveryEndPoint,
      body: body,
    });

    if (response != null) {
      // clearData()
      toast.success(response.message);

      navigate("/Loan-Management/Manage-Delivery")

      // if (delivery_print == 1) {
      //   navigate("/Loan-Management/LoanDelivery-Print" + "/" + response.data.id);
      // } else if (delivery_print == 2) {
      //   navigate("/Loan-Management/cart-print" + "/" + response.data.id);
      // }

    }
  };

  const updateDelivery = async (body) => {
    var response = await putAxiosWithToken({
      url: deliveryEndPoint + localStorage.getItem("delivery_form_id") + "/",
      body: body,
    });

    if (response != null) {
      toast.success(response.message);
      navigate("/Loan-Management/Manage-Delivery")
      // if (delivery_print == 1) {
      //   navigate("/Loan-Management/LoanDelivery-Print" + "/" + response.data.id);
      // } else if (delivery_print == 2) {
      //   navigate("/Loan-Management/cart-print" + "/" + response.data.id);
      // }

    }
  };


  useEffect(() => {
    let totalvalue = 0;
    for (var i of paymentValues) {

      const value = parseFloat(i.value);

      if (!isNaN(value)) {
        totalvalue += value;
      }
    }
    setPaymentLable(totalvalue);
  }, [paymentValues]);





  const onPaymentValueUpdate = (e, index) => {
    const { name, value } = e.target;

    let data = [...paymentValues];
    data[index]['value'] = value;
    data[index]['error'] = value.length > 0 ? "" : data[index].mode_name + " field is required"

    setPaymentValues(data);
    setPaymentAmounts((prev) => ({
      ...prev,
      [name]: value, // Update the specific payment field
    }));


  }


  const handleSubmit = (e) => {
    e.preventDefault();

    if (Number(paymentlable) !== Number(netAmount)) {
      toast.error("Payment and Net amount must be equal");
      return;
    }
    // setPaymentError(""); // Clear any previous error
    handleCreateDelivery(e);
  };

  const onImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
        // Add validation and processing logic here
        console.log("Selected image:", file);

        setSelectedImages(file)
    }
};



  return (
    <div className="pb-5">
      <p className="text-menu-head font-[600] text-[18px] capitalize">{formMode} Loan Delivery</p>


      <div >
        <div className="flex justify-between  border shadow-lg rounded-xl  p-[15px]">
          <div className=" flex items-center gap-[40px] mt-5">
            <div className=" w-full">
              <p className="mb-[5px] text-[15px]">Search Loan Number</p>
              <input
                type="text"
                placeholder="Loanno Number"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-ful max-[480px]:w-full p-[10px] text-[17px] outline-none"
                name="loanno"
                id="forminit"
                value={loanNumber}
                onChange={(e) => setLoanNumber(e.target.value)}
              />
            </div>
            <div className="w-1/2 flex gap-[10px] mt-4 max-[365px]:mt-[50px]">
              <button
                onClick={() => LoanNumber(loanNumber)}
                className="bg-[#0176FF] border border-gray-300 rounded-lg text-white p-[10px]"
              >
                Search
              </button>
            </div>
          </div>

          {/* <div className="my-[5px]">
            <p className="mb-[5px] font-bold text-[16px]">
              Loan Number
              <span className="text-red-500">*</span>
            </p>
            <select
              placeholder="Loan Number"
              className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
              name="loanno"
              onChange={(e) => {
                LoanNumber(e.target.value);
              }}
            >
              <option value="">--Select Loan Number--</option>
              {loanNumber.map((option) => (
                <option
                  className="capitalize"
                  key={"loanno" + option.loanno}
                  value={option.loanno}
                >
                  {option.loanno}
                </option>
              ))}
            </select>
            <p className="text-red-500 font-[400] text-[13px]">
              {errors.loanno}
            </p>
          </div> */}

        </div>


      </div>

      <form id="allDelivery" onSubmit={handleSubmit}>
        <div className="">
          <div className="border p-[15px] rounded-xl shadow-lg grid xl:grid-cols-6 sm:grid-cols-3 gap-5 mb-[10px] mt-2">
            <>
              {localStorage.getItem("branch") == "true" && (
                <div className="my-[5px]">
                  <p className="mb-[5px] font-bold text-[16px]">
                    Branch
                    {/* <span className="text-red-500">*</span> */}
                  </p>
                  <select
                    placeholder="branch"
                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                    name="id_branch"
                    value={selectedBranch}
                    onChange={(e) => {
                      setselectedBranch(e.target.value);
                      onValueUpdate(e);
                    }}
                  >
                    <option value="">--Select Branch--</option>
                    {branchList.map((option) => (
                      <option
                        className="capitalize"
                        key={"branch" + option.branch_name}
                        value={option.id}
                      >
                        {option.branch_name}
                      </option>
                    ))}
                  </select>
                  {errors.id_branch && <p className="error text-red-500 font-[400] text-[13px]">{errors.id_branch}</p>}
                </div>
              )}

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[16px]">Customer Name</p>
                <p className="capitalize">{loanDetails?.loan_list?.customer_name}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[16px]">Loan No</p>
                <p>{loanDetails?.loan_list?.loanno}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Loan Date</p>
                <p>{loanDetails?.loan_list?.loan_date}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Interst percentage
                </p>
                <p>{loanDetails?.loan_list?.interest_percentage}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Interst / Month
                </p>
                <p>{loanDetails?.loan_list?.interest_amount}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Weight </p>
                <p>{loanDetails?.loan_list?.gross_weight}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Loan Amount </p>
                <p>{loanDetails?.loan_list?.loan_amt}</p>
              </div>
            </>
          </div>

          <div className=" border p-[10px] rounded-xl shadow-lg grid xl:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-5">
            <div className="w-full overflow-hidden">
              <div>
                <p className="font-bold text-[18px] mb-5 mt-5">Item Details</p>
              </div>
<div className="w-full overflow-x-scroll">
              <table className="w-full">
                <thead className="border border-black text-[15px] uppercase font-[700]">
                  <tr>
                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Type
                    </td>
                    <td className="p-[10px] min-w-[250px] border border-black px-4 py-2">
                      Item Name
                    </td>

                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Gwt
                    </td>
                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Swt
                    </td>
                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                      Nwt
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {loanDetails?.iteam_details?.length === 0 ? (
                    <tr>
                      <td className="text-center border" colSpan={2}>
                        No Data
                      </td>
                    </tr>
                  ) : (
                    loanDetails?.iteam_details?.map((item) => (
                      <tr>
                        <>
                          <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                            {item.metal__metal_name}
                          </td>
                          <td className="p-[10px] min-w-[250px] border border-black px-4 py-2">
                            {item.item__item_print}
                          </td>

                          <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                            {item.gross_weight}
                          </td>
                          <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                            {item.stone_weight}
                          </td>
                          <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                            {item.net_weight}
                          </td>
                        </>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
</div>
            </div>

            {/* <div>
              <div>
                <p className="font-bold text-[18px] mb-5 mt-5">
                  Interst Credit Details
                </p>
              </div>
              <table>
                <thead className="border border-black text-[15px] uppercase font-[700]">
                  <tr>
                    <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                      SNO
                    </td>
                    <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                      Date
                    </td>

                    <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                      Amount
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {loanDetails?.interest_details?.length === 0 ? (
                    <tr>
                      <td className="text-center border" colSpan={4}>
                        No Data
                      </td>
                    </tr>
                  ) : (
                    loanDetails?.interest_details?.map((item, index) => (
                      <tr>
                        <>
                          <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                            {index + 1}
                          </td>
                          <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                            {item.received_date}
                          </td>

                          <td className="p-[10px] min-w-[200px] border border-black px-4 py-2">
                            {item.received_amount}
                          </td>
                        </>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div> */}
          </div>

          <div className="grid xl:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-5">
            <div className="border p-[15px] rounded-xl shadow-lg grid xl:grid-cols-2 sm:grid-cols-2 gap-5 mb-[10px] mt-2">
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px] ">
                  Year Months And Days
                </p>
                <p className="text-green-600">
                  {loanDetails?.loan_list?.year_month_date}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Delivery Date</p>
                <p className="text-green-600">
                  {new Date().toLocaleDateString("en-CA")}
                </p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Total Months <span className="text-red-500">*</span>{" "}
                </p>
                <input
                  type="text"
                  placeholder="Total Months"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                  name="total_month"
                  value={totalMonth}
                  onChange={
                    // setTotalMonth(e.target.value);
                    onValueUpdate
                  }
                />
                {errors.total_month && <p className="error text-red-500 font-[400] text-[13px]">{errors.total_month}</p>}
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Loan Amount</p>
                <p className="text-green-600">₹{loanAmount}</p>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">
                  Intrest Occured{" "}
                </p>
                <p className="text-green-600">{interestoccured}</p>
              </div>
              {/* <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Advance Amt </p>
                <p className="text-green-600">₹{advanceAmount}</p>
              </div> */}
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Intimationcharge</p>
                <p className="text-green-600">{Intimationcharge}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Total Amount</p>
                <p className="text-green-600">₹{totalAmount}</p>
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Discount</p>
                <input
                  type="text"
                  placeholder="Discount"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                  name="discount"
                  value={discountAmount}
                  onChange={(e) => {
                    setDiscountAmount(e.target.value);
                    onValueUpdate(e);
                  }}
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">
                  {errors.loan_date}
                </p> */}
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Release Charge</p>
                <input
                  type="text"
                  placeholder="Discount"
                  className="sm:mb-[5px] border border-gray-300 rounded-lg w-[200px] p-[7px] text-[17px] outline-none"
                  name="release_charge"
                  value={releaseCharge}
                  onChange={(e) => {
                    setReleaseCharge(e.target.value);
                    onValueUpdate(e);
                  }}
                />
                {/* <p className="text-red-500 font-[400] text-[13px]">
                  {errors.loan_date}
                </p> */}
              </div>

              <div className="my-[5px]">
                <p className="mb-[5px] font-bold text-[15px]">Net Amount</p>
                <p className="text-green-600">₹{parseFloat(netAmount).toFixed(2)}</p>
              </div>
            </div>

            <div className="border p-[15px] rounded-xl shadow-lg  mb-[10px] mt-2">
              <div className=" h-fit flex  gap-[30px] flex-wrap">
                {/* <div className="my-[5px] ">
                  <p className="mb-[5px] font-bold text-[15px]">
                    Payment For <span className="text-red-500">*</span>{" "}
                  </p>
                  <select
                    placeholder="Payment For"
                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                    name="paymenttype"
                    value={selectedPaymentType}
                    onChange={(e) => {
                      setSelectedPaymentType(e.target.value);
                      onValueUpdate(e);
                    }}
                  >
                    <option value={""}>--select Payment For--</option>
                    {paymentTypeDropdown?.map((item, index) => (
                      <option key={item.typename} value={item.id}>
                        {item.typename}
                      </option>
                    ))}
                  </select>

                  <p className="text-red-500 font-[400] text-[13px]">
                    {errors.entry_date}
                  </p>
                </div> */}

                <div className="my-[5px]">
                  <p className="mb-[5px] font-bold text-[15px]">
                    Payment Type <span className="text-red-500">*</span>
                  </p>
                  <select
                    placeholder="metal name"
                    className="sm:mb-[5px] bg-white border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                    name="payment_mode"
                    value={selectedPaymentMode}
                    onChange={(event) => {
                      const newPaymentMode = event.target.value;

                      // Reset the payment fields if a new payment mode is selected
                      const updatedPayment = { ...paymentAmounts };
                      paymentValues.forEach((item) => {
                        if (newPaymentMode != item.id) {
                          updatedPayment["payment" + item.id] = 0; // Reset irrelevant fields
                        }
                      });

                      setPaymentAmounts(updatedPayment);
                      setSelectedPaymentMode(newPaymentMode);
                      onValueUpdate(event); // You can keep this to handle other updates
                    }}
                  >
                    <option value={""}>--Select Payment Mode--</option>
                    {paymentModeDropdown.map((item, index) => (
                      <option key={item.mode_name} value={item.id}>
                        {item.mode_name}
                      </option>
                    ))}
                  </select>

                  {errors.payment_mode && <p className="error text-red-500 font-[400] text-[13px]">{errors.payment_mode}</p>}
                </div>

                <div className="my-[5px]  h-fit flex  gap-[30px] flex-wrap">
                  {paymentValues.map((item, index) => (

                    ((selectedPaymentMode == item.id || selectedPaymentMode.toString() == "1") && item.id.toString() != "1") &&
                    <div key={index} >
                      <div>
                        <p className="mb-[5px] font-bold text-[15px]">{item.mode_name}</p>
                        <input
                          type="text"
                          // placeholder={item.mode_name}
                          className="sm:mb-[5px] border border-gray-300 rounded-lg w-[280px] p-[5px] text-[17px] outline-none"
                          name={"payment" + item.id}
                          value={paymentAmounts["payment" + item.id]}
                          onChange={(event) => onPaymentValueUpdate(event, index)}
                        />
                        {item.error.length > 0 && (
                          <p className="text-red-500 font-[400] text-[13px]">{item.error}</p>
                        )}
                      </div>
                    </div>
                  ))}

                  <div className="my-[5px]">
                    <p className="mb-[5px] font-bold text-[15px]">Remarks</p>
                    <input
                      type="text"
                      placeholder="Remarks"
                      className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[17px] outline-none"
                      name="remarks"
                      value={remarks}
                      onChange={(e) => {
                        setRemarks(e.target.value);
                        onValueUpdate(e);
                      }}
                    />
                    {/* <p className="text-red-500 font-[400] text-[13px]">
                  {errors.loan_date}
                </p> */}
                  </div>
                </div>

                {/* <div>
                  {(selectedPaymentMode == 1 || selectedPaymentMode == 3) && (
                    <div className="my-[5px]">
                      <p className="mb-[5px] font-bold text-[15px]">Cash </p>
                      <div className="">
                        <input
                          type="text"
                          placeholder="Cash"
                          className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                          name="cash"
                          value={cash}
                          onChange={(e) => {
                            {
                              handleCashChange(e);
                              onValueUpdate(e);
                            }
                          }}
                        />

                          {errors.cash && <p className="error text-red-500 font-[400] text-[13px]">{errors.cash}</p>}
                      </div>
                    </div>
                  )}
                  {(selectedPaymentMode == 2 || selectedPaymentMode == 3) && (
                    <div className="my-[5px]">
                      <p className="mb-[5px] font-bold text-[15px]">Bank </p>
                      <div className="">
                        <input
                          type="text"
                          placeholder="Bank"
                          className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[7px] text-[14px] outline-none"
                          name="bank"
                          value={bank}
                          onChange={(e) => {
                            {
                              handleBankChange(e);
                              onValueUpdate(e);
                            }
                          }}
                          // onChange={handleBankChange}
                        />

                          {errors.bank && <p className="error text-red-500 font-[400] text-[13px]">{errors.bank}</p>}
                      </div>
                    </div>
                  )}
                </div> */}


              </div>

              {(releseproof == undefined || releseproof == null) ? (

              <div className="my-[5px]">
            <p className="mb-[5px] text-[16px]">Upload Profile Image</p>

            <input
                type="file"
                accept="image/*"
                className="sm:mb-[5px] border border-gray-300 rounded-lg w-[300px] max-[480px]:w-full p-[7px] text-[17px] outline-none"
                name="release_proof"
                // value={currentCustomer.release_proof}
                onChange={onImageUpload}
            />

            <p className="text-red-500 font-[400] text-[13px]">{errors.release_proof}</p>
        </div> ) : ( <div><label htmlFor="customerimg"><img width={"200px"} src={releseproof} alt="" /></label>
        <input
                id="customerimg"
                type="file"
                accept="image/*"
                className="sm:mb-[5px] mt-2 border border-gray-300 rounded-lg w-[300px] max-[480px]:w-full p-[10px] text-[17px] outline-none"
                name="release_proof"
                // value={currentCustomer.cus_image}
                onChange={onImageUpload}
            />
 </div>)}
  
     


            </div>
          </div>
        </div>

        <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[10px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
          <button
            id="backnav"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="capitalize mb-[20px] bg-menu-head-bg border text-black w-[200px] sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none max-[480px]:mx-auto "
          >
            close
          </button>

          {/* {selectedPaymentType == 2 ? ( */}
          <button
            type="submit"
            id="formsubmit"
            className="capitalize mb-[20px] bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto"
          >
            Delivery
          </button>
          {/* ) : selectedPaymentType == 3 ? ( */}
          {/* <button
              type="submit"
              id="formsubmit"
              className="capitalize mb-[20px] bg-primary-btn text-white w-[200px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto"
            >
              Save Intrest Credit
            </button> */}
          {/* ) : (
            ""
          )} */}
        </div>
      </form>
    </div>
  );
}
