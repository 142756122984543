import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { loanEndPoint, loanTableListEndPoint, manageIntrestEndPoint, manageIntrestListEndPoint, } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import ManageInterestFilters from "./manageinterestfillter";
import { convertToTime } from "../../../utilities/time";

export default function ManageInterest() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [ManageIntrestList, setManageInterestList] = useState([]);
    const [currentPurchase, setCurrentPurchase] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        firm: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const { actions, pagination } = useSelector((state) => state.appConfig);


    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] '
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            localStorage.setItem('user_purchaseentry_mode', 'create');
            navigate('loan-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    useEffect(() => {
        getManageInterestListData(tableFilters)
    }, [tableFilters])

    const getManageInterestListData = async (body) => {
        var response = await postAxiosWithToken({
            url: manageIntrestListEndPoint,
            body: body
        })

        if (response !== null) {
            setManageInterestList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const deleteInterest = async () => {
        var response = await deleteAxiosWithToken({
            url: manageIntrestEndPoint + currentPurchase.id + '/'
        })

        if (response !== null) {
            getManageInterestListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }



    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };


    return (
        <>
            <div className="mt-[100px]">

            


                <div id="filteroptions" className='my-[5px] '>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <ManageInterestFilters filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Loan No</td>
                                <td className="p-[10px] min-w-[200px]">Customer Name</td>
                                <td className="p-[10px] min-w-[150px]">Firm Name</td>
                                <td className="p-[10px] min-w-[150px]">Branch Name</td>
                                <td className="p-[10px] min-w-[150px]">Received Date</td>
                                <td className="p-[10px] min-w-[150px]">Mobile</td>
                                <td className="p-[10px] min-w-[150px]">Address</td>
                                <td className="p-[10px] min-w-[150px]">Cash</td>
                                <td className="p-[10px] min-w-[150px]">Bank</td>
                                <td className="p-[10px] min-w-[150px]">Total Interest</td>
                                <td className="p-[10px] min-w-[150px]">Payment Type</td>
                                <td className="p-[10px] min-w-[150px]">Payment Mode</td>
                                <td className="p-[10px] min-w-[150px]">Create Date</td>

                                <td className="p-[10px] min-w-[150px] sticky right-0 bg-table-head">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-[#fff]">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={9}>Loading...</td>
                                </tr> :
                                    ManageIntrestList.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                        ManageIntrestList?.map((item, index) => (

                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.loanno}</td>
                                                <td className="p-[10px]">{item.customer_name}</td>
                                                <td className="p-[10px]">{item.firm_name}</td>
                                                <td className="p-[10px]">{item.branch_name}</td>
                                                <td className="p-[10px]">{item.received_date}</td>
                                                <td className="p-[10px]">{item.mobile}</td>
                                                <td className="p-[10px]">{item.address}</td>
                                                <td className="p-[10px]">{item.cash}</td>
                                                <td className="p-[10px]">{item.bank}</td>
                                                <td className="p-[10px]">{item.total_interest}</td>
                                                <td className="p-[10px]">{item.paymenttype_name}</td>
                                                <td className="p-[10px]">{item.payment_mode_name}</td>
                                                <td className="p-[10px] capitalize">
                                                    <p>{convertToDate(item.created_at)}</p>
                                                    <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p></td>
                                                <td className="p-[10px] sticky right-0 bg-white">
                                                    <div className="flex gap-3">
                                                        
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentPurchase(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>




                {pagination === true ?
                    <div className="pb-5" >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete User'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentPurchase?.customer_name} interest details?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteInterest() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}