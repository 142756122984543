import { useEffect, useRef, useState } from "react";
import {
  deleteAxiosWithToken,
  getAxios,
  postAxiosWithToken,
} from "../../../service/apiservice";
import {
  customerReportsListEndPoint,
  deliveryEndPoint,
  deliveryLoanListEndPoint,
  loanTableListEndPoint,
  PrincipleSummaryReportsEndPoint,
  transactionListEndPoint,
} from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import TransactionReportsFilter from "./transactionreprtsfillter";
import ExcelViewer from "../../../components/ExcelViewer";
import { ExportToExcel } from "../../../components/ExportToExcel";
import { object } from "yup";
import { ExportToPDF } from "../../../components/ExportToPdf";
import ReactToPrint from "react-to-print";
import TransactionPrint from "./transactionprint";
import { convertToTime } from "../../../utilities/time";

export default function PrincipleSummaryReports() {
  const navigate = useNavigate();
  let componentRef = useRef();

  const searchRef = useRef();
  const fileName = "Transaction Report";
  const [principleSummaryReportsList, setPrincipleSummaryReportsList] = useState([]);
  const [principleReports, setPrinciplReports] = useState({});


  const [currentPurchase, setCurrentPurchase] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [excelViewData, setExcelViewData] = useState([]);

  const [transactionReportExcel, setTransactionReportExcel] = useState([]);


  const [excelOpen, setExcelOpen] = useState(false);

  function closeExcelModal() {
    setExcelOpen(false);
  }

  const getCurrentMonthFirstDate = () => {
    const date = new Date();
    return [date.getFullYear(), date.getMonth() + 1, 1].join('-');
  };
  
  const getCurrentMonthLastDate = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split('T')[0];
  };

  const [tableFilters, setTableFilters] = useState({
    search: "",
    from_date: getCurrentMonthFirstDate(),
    to_date: getCurrentMonthLastDate(),
    firm: null,
    branch: localStorage.getItem("branch") == "true" ? null : undefined,
    items_per_page: JSON.parse(localStorage.getItem("pagination")) ? 100 : null,
    page: 1,
  });

  const { actions, pagination } = useSelector((state) => state.appConfig);

  const onKeyPress = (event) => {
    if (event.ctrlKey && event.key === "f") {
      event.preventDefault();
      if (
        document.getElementById("filteroptions").className === "my-[25px] block"
      ) {
        document.getElementById("filteroptions").className = "my-[25px] ";
      } else {
        document.getElementById("filteroptions").className = "my-[25px] block";
        searchRef.current?.focus();
      }
    } else if (event.ctrlKey && event.key === "m") {
      event.preventDefault();
      localStorage.setItem("user_purchaseentry_mode", "create");
      navigate("loan-form");
    }
  };

  useKeyPress(["f", "m"], onKeyPress);

  useEffect(() => {
    if (principleSummaryReportsList.length > 0) {
      let tempData = [];
      for (var i of principleSummaryReportsList) {
        var ExcelValue = {
          EntryDate: i.entry_date,
          Release: i.delivery_amount,
          DeliveryIntrest: i.delinterest_amount,
          Loan: i.distribute_amt,
          LoanIntrest: i.loan_interest_amount,
        };

        tempData.push(ExcelValue);
      }
      setTransactionReportExcel(tempData);
    }
  }, [principleSummaryReportsList]);

  useEffect(() => {
    if (transactionReportExcel.length > 0) {
      let firstData = Object.keys(transactionReportExcel[0]);
      let row = [];
      for (var i of firstData) {
        row.push({
          value: i,
        });
      }

      setExcelViewData(row);
    }
  }, [transactionReportExcel]);

  useEffect(() => {
    PrincipleSummaryReportsListData(tableFilters);
  }, [tableFilters]);

  const PrincipleSummaryReportsListData = async (body) => {
    var response = await postAxiosWithToken({
      url: PrincipleSummaryReportsEndPoint,
      body: body,
    });

    if (response !== null) {
      setPrincipleSummaryReportsList(response.data.list);
      setPrinciplReports(response);
      setIsLoading(false);
      setTotalPages(response.data.total_pages);
    }
  };

  const pageHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const entriesHandler = (value) => {
    setTableFilters((prevState) => ({
      ...prevState,
      items_per_page: value,
      page: 1,
    }));
  };

  return (
    <>
      <div className="mt-[50px]">

      





        <div className="flex gap-5 items-center max-[480px]:flex-col ">
          <ExcelViewer
            title={fileName}
            data={transactionReportExcel}
            headers={excelViewData}
            enabled={excelOpen}
            setEnabled={setExcelOpen}
            closeModal={closeExcelModal}
          />
          <ExportToExcel apiData={transactionReportExcel} fileName={fileName} />

          <ExportToPDF apiData={transactionReportExcel} fileName={fileName} />

     
          <div>
            <ReactToPrint
              trigger={() => (
                <button className="flex items-center bg-primary-btn p-2 text-white rounded-md gap-4">
                  Print
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M16 8.616v-3H8v3H7v-4h10v4zm-11.423 1h14.846zm13.038 2.5q.425 0 .713-.288t.287-.712t-.287-.713t-.713-.288t-.712.288t-.288.713t.288.712t.713.288M16 19v-4.538H8V19zm1 1H7v-4H3.577v-5.384q0-.85.577-1.425t1.423-.576h12.846q.85 0 1.425.576t.575 1.424V16H17zm2.423-5v-4.384q0-.425-.287-.713t-.713-.288H5.577q-.425 0-.712.288t-.288.713V15H7v-1.538h10V15z"
                    />
                  </svg>
                </button>
              )}
              content={() => componentRef}
            />
          </div>
          {/* <div className="flex gap-5 items-center ">
            <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
              <p>Total Loan</p>
              <p>{principleReports.total_loan}</p>
            </div>
            <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
              <p>Open</p>
              <p>{principleReports.total_open}</p>
            </div>
            <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
              <p>Close</p>
              <p>{principleReports.total_close}</p>
            </div>
          </div> */}
        </div>

        
        


        {/* <div className="flex gap-5 items-center p-3">
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Release</p>
            <p>{principleReports.total_release}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Interest</p>
            <p>{principleReports.total_relinterest}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Loan</p>
            <p>{principleReports.total_loan}</p>
          </div>
          <div className="w-[150px] drop-shadow-lg bg-white rounded-md p-1">
            <p>Interest</p>
            <p>{principleReports.total_loaninterest}</p>
          </div>
        </div> */}


        <div className="hidden">
          <div ref={(el) => (componentRef = el)}>
            <TransactionPrint data={transactionReportExcel} />
          </div>
        </div>

{/* 
        <div className="flex justify-between mt-2">
          <div className="mt-1 flex justify-center overflow-auto">
            <table className="min-w-[200px] table-auto">
              <thead className="border border-black text-[15px] uppercase font-[700]">
                <tr>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Total Loan</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Open</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">closed</td>
                </tr>
              </thead>
              <tbody >
                
                  <tr >
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.total_loans}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports.total_open}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports.total_close}</td>
                    
                  </tr>
                
              </tbody>
            </table>
          </div>
          <div className="mt-1 flex justify-center overflow-auto">
            <table className="min-w-[200px] table-auto">
              <thead className="border border-black text-[15px] uppercase font-[700]">
                <tr>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Loan Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Interest Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Release Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Interest Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Balance Loan Amount</td>
                  <td className="p-[1px] min-w-[115px] border border-black px-2 py-2 text-[12px]">Interest Amount</td>
                </tr>
              </thead>
              <tbody >
                
                  <tr >
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.total_loan}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.total_loaninterest}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.total_release}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.total_relinterest}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.balance_amount}</td>
                    <td className="p-[1px] border border-black min-w-[115px] px-1 text-[12px] ">{principleReports?.balance_interest_amount}</td>
                  </tr>
                
              </tbody>
            </table>
            
          </div>
        </div> */}





        <div id="filteroptions" className="my-[5px] mt-3 ">
          <p className="font-[600] text-[18px]">Filters</p>
          <TransactionReportsFilter
            filters={tableFilters}
            searchRef={searchRef}
            setFilters={setTableFilters}
          />
        </div>
        <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
          <table className="w-full">
            <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
              <tr>
                <td className="p-[10px]">S.No</td>
                <td className="p-[10px] min-w-[150px]">Entry Date</td>
                <td className="p-[10px] min-w-[150px]">Loan Amount</td>
                <td className="p-[10px] min-w-[180px]">Interest Amount</td>
                <td className="p-[10px] min-w-[150px] ">Release Amount</td>

                <td className="p-[10px] min-w-[150px]">Interest Amount</td>


              </tr>
            </thead>
            <tbody className="text-primary-grey bg-[#fff]">
              {isLoading ? (
                <tr className="text-center w-full">
                  <td className="text-center border" colSpan={11}>
                    Loading...
                  </td>
                </tr>
              ) : principleSummaryReportsList.length === 0 ? (
                <tr>
                  <td className="text-center border" colSpan={11}>
                    No Data
                  </td>
                </tr>
              ) : (
                principleSummaryReportsList?.map((item, index) => (
                  <tr key={item.id} className="border">
                    <td className="p-[10px]">{index + 1}</td>
                    <td className="p-[10px]">{item.entry_date}</td>
                    <td className="p-[10px]">Rs.{parseFloat(item.loan_amt).toFixed(2)}</td>

                    <td className="p-[10px] capitalize">Rs.{parseFloat(item.loan_interest_amount).toFixed(2)}</td>

                    <td className="p-[10px]">Rs.{parseFloat(item.delivery_amount).toFixed(2)}</td>
                    <td className="p-[10px] capitalize">Rs.{parseFloat(item.delinterest_amount).toFixed(2)}</td>

                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {pagination === true ? (
          <div className="pb-5">
            <Pagination
              totalPages={totalPages}
              currentPage={tableFilters.page}
              setCurrentPage={pageHandler}
              entries={tableFilters.items_per_page}
              entriesHandler={entriesHandler}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
