import { useEffect, useState } from "react";
import { getAxios } from "../../../service/apiservice";
import { intimationgetdetailEndPoint } from "../../../service/api";
import { useParams } from "react-router-dom";


export default function LoanManageDueView() {


    const { id } = useParams();


    const [getviewdetail, setGetviewdetail] = useState({})
    const [Itemlist, setItemlist] = useState([])
    const [Itemintimationlist, setItemintimationlist] = useState([])



    useEffect(() => {
        GetItemdetauls(id);
    }, [id]);

    const GetItemdetauls = async (id) => {
        var response = await getAxios({
            url: intimationgetdetailEndPoint + id + '/'
        })

        if (response !== null) {
            var tempDetails = {

                customer_name: response.data.loan_list.customer_name,
                loanno: response.data.loan_list.loanno,
                loan_date: response.data.loan_list.loan_date,
                interest_percentage: response.data.loan_list.interest_percentage,
                interest_amount: response.data.loan_list.interest_amount,
                net_weight: response.data.loan_list.net_weight,
                loan_amt: response.data.loan_list.loan_amt,

                year_month_date: response.data.loan_list.year_month_date,
                total_month: response.data.loan_list.total_month,
                interest_occured: response.data.loan_list.interest_occured,
                total_loanamount: response.data.loan_list.total_loanamount,
                release_charge: response.data.loan_list.release_charge,
                total_netamount: response.data.loan_list.total_netamount,

            }

            setGetviewdetail(tempDetails)
            setItemlist(response.data.iteam_details)
            setItemintimationlist(response.data.intimation_charges)


        }
    }



    return (
        <div className="pb-5">




            <form >
                <div className="">
                    <div className="border p-[15px] rounded-xl shadow-lg grid xl:grid-cols-6 sm:grid-cols-3 gap-5 mb-[10px] mt-2">
                        <>


                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[16px]">Customer Name</p>
                                <p className="capitalize">{getviewdetail.customer_name}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[16px]">Loan No</p>
                                <p>{getviewdetail.loanno}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Loan Date</p>
                                <p>{getviewdetail?.loan_date}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">
                                    Interst percentage
                                </p>
                                <p>{getviewdetail.interest_percentage}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">
                                    Interst / Month
                                </p>
                                <p>{getviewdetail.interest_amount}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Weight </p>
                                <p>{getviewdetail?.net_weight}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Loan Amount </p>
                                <p>{getviewdetail.loan_amt}</p>
                            </div>
                        </>
                    </div>

                    <div className=" border p-[10px] rounded-xl shadow-lg grid xl:grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 gap-5">
                        <div>
                            <div>
                                <p className="font-bold text-[18px] mb-5 mt-5">Item Details</p>
                            </div>

                            <table className="w-full">
                                <thead className="border border-black text-[15px] uppercase font-[700]">
                                    <tr>
                                        <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                                            Type
                                        </td>
                                        <td className="p-[10px] min-w-[250px] border border-black px-4 py-2">
                                            Item Name
                                        </td>

                                        <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                                            Gwt
                                        </td>
                                        <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                                            Swt
                                        </td>
                                        <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                                            Nwt
                                        </td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        Itemlist.map((item) => (
                                            <tr>
                                                <>
                                                    <td className="p-[10px] min-w-[115px] border border-black px-4 py-2">
                                                        {item.metal__metal_name}
                                                    </td>
                                                    <td className="p-[10px] min-w-[250px] border border-black px-4 py-2">
                                                        {item.item__item_print}
                                                    </td>

                                                    <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                                                        {item.gross_weight}
                                                    </td>
                                                    <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                                                        {item.stone_weight}
                                                    </td>
                                                    <td className="p-[10px] min-w-[80px] border border-black px-4 py-2">
                                                        {item.net_weight}
                                                    </td>
                                                </>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div className="grid xl:grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 gap-5">
                        <div className="border p-[15px] rounded-xl shadow-lg grid xl:grid-cols-2 sm:grid-cols-2 gap-5 mb-[10px] mt-2">
                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px] ">
                                    Year Months And Days
                                </p>
                                <p className="text-green-600">
                                    {getviewdetail.year_month_date}
                                </p>
                            </div>



                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">
                                    Total Months <span className="text-red-500">*</span>{" "}
                                </p>
                                <p className="text-green-600">₹{getviewdetail.total_month}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Loan Amount</p>
                                <p className="text-green-600">₹{getviewdetail.loan_amt}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">
                                    Intrest Occured{" "}
                                </p>
                                <p className="text-green-600">{getviewdetail.interest_occured}</p>
                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Total Amount</p>
                                <p className="text-green-600">₹{getviewdetail.total_loanamount}</p>
                            </div>


                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Release Charge</p>
                                <p className="text-green-600">₹{getviewdetail.release_charge}</p>

                            </div>

                            <div className="my-[5px]">
                                <p className="mb-[5px] font-bold text-[15px]">Net Amount</p>
                                <p className="text-green-600">₹{getviewdetail.total_netamount}</p>
                            </div>
                        </div>

                        <div className="border p-[15px] rounded-xl shadow-lg  mb-[10px] mt-2">
                            <div className=" h-fit flex  gap-[30px] flex-wrap">

                                <div className=" my-[10px] border rounded-md mt-5 max-h-[500px] overflow-y-scroll">

                                    <table className="w-full ">
                                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                                            <tr>
                                                <td className="p-[10px]">S.No</td>
                                                <td className="p-[10px] min-w-[150px] ">Id</td>
                                                <td className="p-[10px] min-w-[150px] ">Loan No</td>
                                                <td className="p-[10px] min-w-[180px]">Intimation Charge</td>
                                                <td className="p-[10px] min-w-[150px]">Remarks</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                Itemintimationlist.map((item, index) => (
                                                    <tr key={item.id} className="border">

                                                        <td className="p-[10px]">{index + 1}</td>
                                                        <td className="p-[10px] min-w-[150px] ">{item.id}</td>
                                                        <td className="p-[10px] min-w-[150px] ">{item.loan_no}</td>
                                                        <td className="p-[10px] min-w-[180px]">{item.intimation_charge}</td>
                                                        <td className="p-[10px] min-w-[150px]">{item.remarks}</td>




                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                    </table>





                                </div>

                               



                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[10px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                    <button
                        id="backnav"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   navigate(-1);
                        // }}
                        className="capitalize mb-[20px] bg-secondary border text-black w-[200px]  rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "
                    >
                        close
                    </button>




                </div>
            </form>


        </div>


    );
}
