import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { ConfigurationListEndPoint, deliveryEndPoint, deliveryLoanListEndPoint, loanTableListEndPoint, } from "../../../service/api";
import SwitchButton from "../../../components/Switch";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import LoanDeliveryFilters from "./loandeliveryfillter";
import { convertToTime } from "../../../utilities/time";

export default function LoanDelivery() {

    const navigate = useNavigate();

    const searchRef = useRef();

    const [deliveryEntryList, setLoanDeliveryEntryList] = useState([]);
    const [currentPurchase, setCurrentPurchase] = useState({});
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [delivery_print, setDeliveryPrint] = useState(0);

    console.log("delivery_print",delivery_print)

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        firm: null,
        branch: localStorage.getItem('branch') == "true" ? null : undefined,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    const { actions, pagination } = useSelector((state) => state.appConfig);


    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px]'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        } else if (event.ctrlKey && event.key === 'm') {
            event.preventDefault();
            localStorage.setItem('user_purchaseentry_mode', 'create');
            navigate('loan-form');
        }
    };

    useKeyPress(['f', 'm'], onKeyPress);

    useEffect(() => {
        getdeliveryListData(tableFilters)
    }, [tableFilters])
    useEffect(() => {
        getConfigurationData();
    }, [])

    const getdeliveryListData = async (body) => {
        var response = await postAxiosWithToken({
            url: deliveryLoanListEndPoint,
            body: body
        })

        if (response !== null) {
            setLoanDeliveryEntryList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }


    const getConfigurationData = async () => {
        var response = await getAxios({
          url: ConfigurationListEndPoint,
        });
    
        if (response !== null) {
            setDeliveryPrint(response.data.delivery_print);
        }
      };

    const deletePurchase = async () => {
        var response = await deleteAxiosWithToken({
            url: deliveryEndPoint + currentPurchase.id + '/'
        })

        if (response !== null) {
            getdeliveryListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }



    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    


    return (
        <>
            <div>

                {/* <button  onClick={() => {navigate('/Loan-Management/cart-print')}}>
                    print
                </button> */}

            
      

                <div className="flex justify-end">


                    <button
                        onClick={() => {
                            if (actions.addPermit) {
                                localStorage.setItem('loan_delivery_mode', 'create'); navigate('loandelivery-form')
                            } else {
                                toast.error("You don't have permission for add")
                            }
                        }}
                        className="w-[300px] mt-[20px] capitalize bg-primary-btn text-white rounded-lg p-[10px] h-fit font-[500] text-[17px] outline-none border-none"
                    >Add Loan Delivery <span className="lowercase">(ctrl+m)</span></button>
                </div>
                <div id="filteroptions" className='my-[5px] '>
                    <p className="font-[600] text-[18px]">Filters</p>
                    <LoanDeliveryFilters filters={tableFilters} searchRef={searchRef} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll border rounded-md mt-5">
                    <table className="w-full">
                        <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px] min-w-[150px]">Loan No</td>
                                <td className="p-[10px] min-w-[200px] ">Customer Name</td>
                                <td className="p-[10px] min-w-[200px]">Accounter Name</td>
                                <td className="p-[10px] min-w-[150px] ">Firm Name</td>
                                <td className="p-[10px] min-w-[150px]">Loan Date</td>
                                <td className="p-[10px] min-w-[150px]">Mobile</td>
                                <td className="p-[10px] min-w-[150px] ">Address</td>
                                <td className="p-[10px] min-w-[150px] ">Branch Name</td>
                                <td className="p-[10px] min-w-[150px]">Loan Amt</td>
                                <td className="p-[10px] min-w-[150px]">Distribute Amt</td>
                                <td className="p-[10px] min-w-[150px]">Interest %</td>
                                <td className="p-[10px] min-w-[150px]">Monthly Interest</td>
                                <td className="p-[10px] min-w-[150px]">Document Charge</td>
                                <td className="p-[10px] min-w-[150px]">Till Interest</td>
                                <td className="p-[10px] min-w-[180px]">Total Amount</td>
                                <td className="p-[10px] min-w-[180px]">Discount</td>                   
                                <td className="p-[10px] min-w-[180px]">Delivery Amount</td>     
                                <td className="p-[10px] min-w-[180px]">Delivery Interest</td>    
                                <td className="p-[10px] min-w-[150px]">Delivery Date</td>
                                <td className="p-[10px] min-w-[150px]">Status Name</td>
                                <td className="p-[10px] min-w-[150px]">Create Date</td>

                                <td className="p-[10px] min-w-[150px] sticky right-0 bg-table-head">Actions</td>
                            </tr>
                        </thead>
                        <tbody className="text-primary-grey bg-[#fff]">
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={9}>Loading...</td>
                                </tr> :
                                    deliveryEntryList.length === 0 ? <tr><td className="text-center border" colSpan={9}>No Data</td></tr> :
                                        deliveryEntryList?.map((item, index) => (

                                            <tr key={item.id} className="border">
                                                <td className="p-[10px]">{index + 1}</td>
                                                <td className="p-[10px]">{item.loanno}</td>
                                                <td className="p-[10px] capitalize">{item.customer_name}</td>
                                                <td className="p-[10px] capitalize">{item.accounter_name}</td>
                                                <td className="p-[10px] capitalize">{item.firm_name}</td>
                                                <td className="p-[10px]">{item.loan_date}</td>
                                                <td className="p-[10px]">{item.mobile}</td>
                                                <td className="p-[10px] capitalize">{item.address}</td>
                                                <td className="p-[10px] capitalize">{item.branch_name}</td>
                                                <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.loan_amt).toFixed(2)}</td>
                                                <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.distribute_amt).toFixed(2)}</td>
                                               
                                                <td className="p-[10px]">{item.interest_percentage}%</td>
                                                <td className="p-[10px]">Rs. {parseFloat(item.monthly_interest_amount).toFixed(2)}</td>
                                                <td className="p-[10px] bg-[#ffffe0]">Rs. {parseFloat(item.document_charge).toFixed(2)}</td>
                                                <td className="p-[10px]">Rs. {parseFloat(item.interest_occured).toFixed(2)}</td>
                                                <td className="p-[10px] bg-[#e0ffff]">Rs. {parseFloat(item.total_pay_amount).toFixed(2)}</td>
                                                <td className="p-[10px]">Rs. {parseFloat(item.discount).toFixed(2)}</td>
                                                <td className="p-[10px]">Rs. {parseFloat(item.delivery_amount).toFixed(2)}</td>
                                                <td className="p-[10px]">Rs. {parseFloat(item.delivery_interest_amount).toFixed(2)}</td>
                                                <td className="p-[10px]">{item.received_date}</td>
                                                <td className="p-[10px]" style={{color:`${item.statuscolor}`}}>{item.statusname}</td>
                                                
                                                <td className="p-[10px] capitalize">
                                                    <p>{convertToDate(item.created_at)}</p>
                                                    <p className="text-[#BCBDBD]">{convertToTime(item.created_at)}</p></td>
                                                    
                                              
                                                <td className="p-[10px] sticky right-0 bg-white">
                                                    <div className="flex gap-3">
                                                        {/* <div onClick={() => {
                                                            if (actions.editPermit) {
                                                                setCurrentPurchase(item);
                                                                localStorage.setItem('loan_delivery_mode', 'update'); localStorage.setItem('delivery_form_id', item.id?.toString()); navigate('loandelivery-form');
                                                            } else {
                                                                toast.error("You don't have permission for edit")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
                                                        </div> */}
                                                        <div onClick={() => {
                                                            if (actions.deletePermit) {
                                                                setCurrentPurchase(item);
                                                                setIsDeleteOpen(true);
                                                            } else {
                                                                toast.error("You don't have permission for delete")
                                                            }
                                                        }} className="cursor-pointer hover:scale-125">
                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
                                                        </div>

                                                        {/* {

                                                    if (delivery_print == 1){
                                                        navigate("/Loan-Management/LoanDelivery-Print" + "/" + response.data.id);
                                                    } else if (delivery_print == 2){
                                                        navigate("/Loan-Management/cart-print" + "/" + response.data.id);
                                                    }
                                                        } */}

                                                                    {delivery_print ==1 ?

                                                                        <div onClick={() => {navigate("/Loan-Management/LoanDelivery-Print" + "/" + item.deliveryloanid)}}  className="cursor-pointer hover:scale-125">
                                                                            <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16 8V5H8v3H6V3h12v5zM4 10h16zm14 2.5q.425 0 .713-.288T19 11.5t-.288-.712T18 10.5t-.712.288T17 11.5t.288.713t.712.287M16 19v-4H8v4zm2 2H6v-4H2v-6q0-1.275.875-2.137T5 8h14q1.275 0 2.138.863T22 11v6h-4zm2-6v-4q0-.425-.288-.712T19 10H5q-.425 0-.712.288T4 11v4h2v-2h12v2z"/></svg>
                                                                        </div> : delivery_print == 2 ?
                                                                        <div onClick={() => {navigate("/Loan-Management/cart-print" + "/" + item.deliveryloanid)}}  className="cursor-pointer hover:scale-125">
                                                                                <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16 8V5H8v3H6V3h12v5zM4 10h16zm14 2.5q.425 0 .713-.288T19 11.5t-.288-.712T18 10.5t-.712.288T17 11.5t.288.713t.712.287M16 19v-4H8v4zm2 2H6v-4H2v-6q0-1.275.875-2.137T5 8h14q1.275 0 2.138.863T22 11v6h-4zm2-6v-4q0-.425-.288-.712T19 10H5q-.425 0-.712.288T4 11v4h2v-2h12v2z"/></svg>
                                                                        </div> :""
                                                                    }


                                                        
                                                        

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                            }

                        </tbody>
                    </table>
                </div>




                {pagination === true ?
                    <div className="pb-5" >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}
            </div>
            <div>
                <Popup title={'Delete User'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the {currentPurchase?.customer_name} loan delivery?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deletePurchase() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}