import { useEffect, useState } from "react";
import LogoImage from "../../assets/images/logo.svg";
import { GoldAndSilverEndPoint, logoutEndPoint, todayMetalRatesEndPoint } from "../../service/api";
import { getAxios } from "../../service/apiservice";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPagination, setTodayGoldRate, setTodaySilverRate } from "../../redux/appconfigslice";

export default function Header({ logoVisible = false }) {

    const [loginDetails, setLoginDetails] = useState({});

    const { todayGoldRate, todaySilverRate, now } = useSelector((state) => state.appConfig)

    const [showRates, setShowRates] = useState({
        gold: false,
        silver: false
    });

    const dispatch = useDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('authenticated')) {
            setLoginDetails(JSON.parse(localStorage.getItem('login')))
            getTodayRates()
            // dispatch(setPagination(JSON.parse(localStorage.getItem('pagination'))))
        }
    }, [])


    const logout = async () => {
        var response = await getAxios({
            url: logoutEndPoint
        })

        if (response !== null) {
            if (response.status === 200) {
                toast.success(response.message)
                localStorage.removeItem('login')
                localStorage.setItem('authenticated', JSON.parse(false))
                navigate('/')
                window.location.reload()
            } else {
                toast.error(response.message)
            }
        }
    }


    const getTodayRates = async () => {
        var response = await getAxios({
            url: todayMetalRatesEndPoint
        })

        if (response !== null) {
            if (response.data?.gold && response.data?.gold > 0) {
                dispatch(setTodayGoldRate(response.data?.gold))
                setShowRates((prevState) => ({
                    ...prevState,
                    gold: true
                }))
            } else {
                setShowRates((prevState) => ({
                    ...prevState,
                    gold: false
                }))
            }
            if (response.data?.silver && response.data?.silver > 0) {
                setShowRates((prevState) => ({
                    ...prevState,
                    silver: true
                }))
                dispatch(setTodaySilverRate(response.data?.silver))
            } else {
                setShowRates((prevState) => ({
                    ...prevState,
                    silver: false
                }))
            }
        }
    }


    return (
        <>
            <div className="flex justify-between px-[15px] py-[5px] border-b h-[65px] shadow-lg">
                <div className={`flex gap-2 items-center `}>
                    <div onClick={() => {
                        document.getElementById('menubar').className = 'block md:block'
                    }}
                        className={`${logoVisible ? "md:hidden" : "lg:hidden"}`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 512 512">
                            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="48" d="M88 152h336M88 256h336M88 360h336" />
                        </svg>
                    </div>


                    <div onClick={() => navigate('/')} className={`max-[768px]:hidden w-[200px] cursor-pointer ${logoVisible ? 'block' : 'hidden'}`}>
                        <img src={LogoImage} alt="no-logo" />
                    </div>
                </div>
                <div className="max-[640px]:block max-[640px]:my-auto flex gap-8 items-center">

                    {showRates.gold && <div className="max-[640px]:text-[12px]">
                        Gold{now ? ':' : ""} <span className="text-secondary-text">₹{todayGoldRate} /Gram(22ct)</span>
                    </div>}
                    {showRates.silver && <div className="max-[640px]:text-[12px]">
                        Silver: <span className="text-secondary-text">₹{todaySilverRate} /Gram </span>
                    </div>}
                </div>

                <div className="flex gap-4 items-center">
                    <div className="flex gap-4 items-center ">
                        <div onClick={() => navigate('/settings/return-policy')} className="w-[24px] h-[24px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="1.5"><circle cx="12" cy="12" r="3" /><path d="M13.765 2.152C13.398 2 12.932 2 12 2c-.932 0-1.398 0-1.765.152a2 2 0 0 0-1.083 1.083c-.092.223-.129.484-.143.863a1.617 1.617 0 0 1-.79 1.353a1.617 1.617 0 0 1-1.567.008c-.336-.178-.579-.276-.82-.308a2 2 0 0 0-1.478.396C4.04 5.79 3.806 6.193 3.34 7c-.466.807-.7 1.21-.751 1.605a2 2 0 0 0 .396 1.479c.148.192.355.353.676.555c.473.297.777.803.777 1.361c0 .558-.304 1.064-.777 1.36c-.321.203-.529.364-.676.556a2 2 0 0 0-.396 1.479c.052.394.285.798.75 1.605c.467.807.7 1.21 1.015 1.453a2 2 0 0 0 1.479.396c.24-.032.483-.13.819-.308a1.617 1.617 0 0 1 1.567.008c.483.28.77.795.79 1.353c.014.38.05.64.143.863a2 2 0 0 0 1.083 1.083C10.602 22 11.068 22 12 22c.932 0 1.398 0 1.765-.152a2 2 0 0 0 1.083-1.083c.092-.223.129-.483.143-.863c.02-.558.307-1.074.79-1.353a1.617 1.617 0 0 1 1.567-.008c.336.178.579.276.819.308a2 2 0 0 0 1.479-.396c.315-.242.548-.646 1.014-1.453c.466-.807.7-1.21.751-1.605a2 2 0 0 0-.396-1.479c-.148-.192-.355-.353-.676-.555A1.617 1.617 0 0 1 19.562 12c0-.558.304-1.064.777-1.36c.321-.203.529-.364.676-.556a2 2 0 0 0 .396-1.479c-.052-.394-.285-.798-.75-1.605c-.467-.807-.7-1.21-1.015-1.453a2 2 0 0 0-1.479-.396c-.24.032-.483.13-.82.308a1.617 1.617 0 0 1-1.566-.008a1.617 1.617 0 0 1-.79-1.353c-.014-.38-.05-.64-.143-.863a2 2 0 0 0-1.083-1.083Z" /></g></svg>
                        </div>
                        <div className="block text-[12px] md:text-[18px]">
                            <p className="capitalize">{loginDetails.name}</p>
                            <p className="capitalize">{loginDetails.branch_shortname}</p>
                        </div>
                    </div>
                    <div onClick={() => logout()} className="border rounded p-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2"><path stroke-dasharray="32" stroke-dashoffset="32" d="M12 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H12"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.4s" values="32;0" /></path><path stroke-dasharray="12" stroke-dashoffset="12" d="M9 12h11.5" opacity="0"><set attributeName="opacity" begin="0.5s" to="1" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.5s" dur="0.2s" values="12;0" /></path><path stroke-dasharray="6" stroke-dashoffset="6" d="M20.5 12l-3.5 -3.5M20.5 12l-3.5 3.5" opacity="0"><set attributeName="opacity" begin="0.7s" to="1" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.7s" dur="0.2s" values="6;0" /></path></g></svg>
                    </div>
                </div>
            </div>
        </>
    );
}