import React from 'react'

export default function LoadingPage({ height="min-h-screen", width="min-w-screen", opacity="" }) {
  return (
    <div>
        <div className={`flex items-center justify-center bg-gray-100 ${opacity} ${width} ${height}`}>
                <div className="flex items-center justify-center h-screen">
                    <div className="relative">
                        <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-100"></div>

                        <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-primary-btn animate-spin">
                        </div>



                    </div>
                </div>
            </div>
      
    </div>
  )
}


