import AccountGroup from "../pages/books/accountgroup";
import AccountHead from "../pages/books/accounthead";
import AccountHeadForm from "../pages/books/accounthead/accountheadform";
import Company from "../pages/books/company";
import CompanyForm from "../pages/books/company/companyform";
import CompanyDetails from "../pages/books/company/companyview";
import Counter from "../pages/infrastructure/counter";
import Floor from "../pages/infrastructure/floor";
import Caratrate from "../pages/masters/Caratrate";
import CaratrateForm from "../pages/masters/Caratrate/Caratrateform";
import Metal from "../pages/masters/metal";
import MetalRates from "../pages/masters/metalrates";
import Purity from "../pages/masters/purity";
import Rangestock from "../pages/masters/rangestock";
import Branch from "../pages/organization/branches";
import Department from "../pages/organization/department";
import Designation from "../pages/organization/designation";
import Locations from "../pages/organization/locations";
import Staff from "../pages/organization/staff";
import StaffForm from "../pages/organization/staff/staffform";
import User from "../pages/organization/user";
import UserForm from "../pages/organization/user/userform";
import Menu from "../pages/settings/menu";
import MenuGroup from "../pages/settings/menugroup";
import MenuPermission from "../pages/settings/menupermission";
import UserRole from "../pages/settings/userrole";
import AccountHeadDetails from "../pages/books/accounthead/accountheadview";
import { Dashboard } from "../pages/dashboard";
import AccountpaymentForm from "../pages/books/advancepayment/advancepaymentform";
import Advancepayment from "../pages/books/advancepayment";
import AdvancepaymentForm from "../pages/books/advancepayment/advancepaymentform";
import Approvaltype from "../pages/approval/approvaltype";
import Approvalrule from "../pages/approval/approvelrule";
import AppConfiguration from "../pages/appconfig/settings";
import ViewRangeStock from "../pages/masters/rangestock/viewrangestock";
import VendorPayments from "../pages/paymentss/vendorpayments";
import VendorPaymentsForm from "../pages/paymentss/vendorpayments/vendorpaymentform";
import CustomerForm from "../pages/management/customer/customerform";
import Customer from "../pages/management/customer";
import NewFirm from "../pages/management/newfirm";
import NewFirmForm from "../pages/management/newfirm/newfirmform";
import Item from "../pages/management/item";
import ViewCustomer from "../pages/management/customer/viewcustomer";
import NewLoan from "../pages/loanmanagement/newloan";
import NewLoanForm from "../pages/loanmanagement/newloan/newloanform";
// import NewLoanPrint from "../pages/loanmanagement/newloan/newloanprint";
import LoanDelivery from "../pages/loanmanagement/loandelivery";
import LoanDeliveryform from "../pages/loanmanagement/loandelivery/loandeliveryform";
import ManageInterest from "../pages/loanmanagement/manageinterest";
import CustomerReports from "../pages/reports/customerreports";
import TransactionReports from "../pages/reports/transactionreports";
import CustomerReportsView from "../pages/reports/customerreports/customerreportsview/customerreportsview";
import CustomerLoanView from "../pages/reports/customerreports/customerreportsview/customerloanview";
import TransactionPrint from "../pages/reports/transactionreports/transactionprint";
import PrincipleReports from "../pages/reports/principlereports";
import DeliveryReports from "../pages/reports/deliveryreports";
import DayWiseInterestReports from "../pages/reports/daywiseinterestreports";
import InterestReports from "../pages/reports/iterestreports";
import OverdueReports from "../pages/reports/overduereports";
import LoassItemReports from "../pages/reports/loassitemreports";
import DayBookReports from "../pages/reports/daybookreports";
import RateRange from "../pages/masters/raterange";
import MetaRemarks from "../pages/masters/remarks";
// import CartPrint from "../pages/loanmanagement/loandelivery/print/printtwo/cartprint";
import PrincipleSummaryReports from "../pages/reports/principlesummaryreports";
import ItemslistReports from "../pages/reports/itemslistreports";
import MonthlyReports from "../pages/reports/monthlyreports";
import PaymentModeTransactionReports from "../pages/reports/paymentmodetransactionreport";

import ManageOverDue from "../pages/loanmanagement/manageoverdue";
import AuctionPrint from "../pages/loanmanagement/manageoverdue/auctionprint";
import PaymentMode from "../pages/organization/paymentmode";

import LoanDeliveryPrint from "../pages/loanmanagement/loandelivery/print/printone/loandeliveryprint";
import CartPrint from "../pages/loanmanagement/loandelivery/print/printtwo/cartprint";
import NewLoanPrint from "../pages/loanmanagement/newloan/print/printone/newloanprint";
import NewLoanPrintAmount from "../pages/loanmanagement/newloan/print/printtwo/newloanprint";
import LoanSummaryReports from "../pages/reports/loansummaryreport";
import LoanManageDue from "../pages/loanmanagement/manageoverdue/loanmanagedueform";
import LoanManageDueView from "../pages/loanmanagement/manageoverdue/itiimationview";
import IntrestPercentage from "../pages/masters/intrestpercentage";






export const AddedAuthenticatedRoutes = [
    {
        path: '/',
        component: <Dashboard />
    },
    {
        path: '/settings/return-policy',
        component: <AppConfiguration />
    },
    {
        path: "/organization/staff/staff-form",
        component: <StaffForm />
    },
    {
        path: "/organization/user/user-form",
        component: <UserForm />
    },
    {
        path: "/books/company/company-form",
        component: <CompanyForm />
    },
    {
        path: "/books/company/company-details",
        component: <CompanyDetails />
    },
    {
        path: "/books/account-head/account-head-form",
        component: <AccountHeadForm />
    },
    {
        path: "/books/account-head/accounthead-details",
        component: <AccountHeadDetails />
    },
    
    {
        path: "/masters/caratrate/caratrate-form",
        component: <CaratrateForm />
    },
    {
        path: "/management/Customer/customer-form",
        component: <CustomerForm />
    },
    {
        path: "/books/customer/view-customer/:id",
        component: <ViewCustomer />
    },
   
    {
        path: "/books/advance-payment/advancepayment-form",
        component: <AdvancepaymentForm />
    },
    {
        path: "/masters/rangestock/view-range-stock/:id",
        component: <ViewRangeStock />
    },
    {
        path: "/wholesale/vendor-payment/vendor-payment-create",
        component: <VendorPaymentsForm />
    },
    {
        path: "/payment/vendor-payment/vendor-payment-update/:id",
        component: <VendorPaymentsForm />
    },
    {
        path: "/management/New-Firm/customer-form",
        component: <NewFirmForm />
    },
    {
        path: "/Loan-Management/Manage-Loan/loan-form",
        component: <NewLoanForm />
    },


    {
        path: "/Loan-Management/New-Loan/newloan-print",
        component: <NewLoanPrint />
    },


    {
        path: "/Loan-Management/New-Loan/newloan-print/:id/",
        component: <NewLoanPrint />
    },

    {
        path: "/Loan-Management/Manage-Delivery/loandelivery-form",
        component: <LoanDeliveryform />
    },
    {
        path: "/Overdue-Management/Manage-Overdue/manageoverdue-form",
        component: <LoanManageDue />
    },
    {
        path: "/Loan-Management/LoanDelivery-Print/:id/",
        component: <LoanDeliveryPrint />
    },
    {
        path: "/Loan-Management/LoanDelivery-Print",
        component: <LoanDeliveryPrint />
    },
    {
        path: "/Reports/Customer-Reports/view-customer-reports",
        component: <CustomerReportsView />
    },
    {
        path: "/Reports/Customer-Reports/view-customer-reports/view-customer-reports",
        component: <CustomerLoanView />
    },
    {
        path: "/Reports/transaction-print",
        component: <TransactionPrint />
    },

    {
        path: "/Reports/Item-List-Reports",
        component: <ItemslistReports/>
    },


    {
        path: "/Reports/Monthly-Reports",
        component: <MonthlyReports/>
    },
    {
        path: "/Reports/PaymentModeTransction-Reports",
        component: <PaymentModeTransactionReports/>
    },

    {
        path: "/Overdue-Management/Manage-Overdue/Auction-Print/:id/",
        component: <AuctionPrint />
    },


    {
        path: "/Overdue-Management/Manage-Overdue/Auction-view/:id/",
        component: <LoanManageDueView />
    },


   
   
    {
        path: "/Loan-Management/cart-print",
        component: <CartPrint />
    },
    {
        path: "/Loan-Management/cart-print/:id/",
        component: <CartPrint />
    },
    {
        path: "/Loan-Management/New-Loan/newloan-print-amount/:id/",
        component: <NewLoanPrintAmount />
    },
    {
        path: "/Loan-Management/New-Loan/newloan-print-amount",
        component: <NewLoanPrintAmount />
    },

  
   
    
]


export const AuthenticatedComponents =  [
    {
        path: "menu-permission",
        component: <MenuPermission />
    },
    {
        path: "user-role",
        component: <UserRole />
    },
    {
        path: "menu",
        component: <Menu />
    },
    {
        path: "menu-group",
        component: <MenuGroup />
    },
    {
        path: "locations",
        component: <Locations />
    },
    {
        path: "branch",
        component: <Branch />
    },
    {
        path: "department",
        component: <Department />
    },
    {
        path: "designation",
        component: <Designation />
    },
    {
        path: "staff",
        component: <Staff />
    },
    {
        path: "staff/staff-form",
        component: <StaffForm />
    },
    {
        path: "user",
        component: <User />
    },
    {
        path: "user/user-form",
        component: <UserForm />
    },
    {
        path: "metal",
        component: <Metal />
    },
    {
        path: "purity",
        component: <Purity />
    },
    {
        path: "rangestock",
        component: <Rangestock />
    },
    {
        path: "metal-rates",
        component: <MetalRates />
    },
    {
        path: "floor",
        component: <Floor />
    },
    {
        path: "counter",
        component: <Counter />
    },
    {
        path: "company",
        component: <Company />
    },
    {
        path: "account-head",
        component: <AccountHead />
    },
    {
        path: "account-group",
        component: <AccountGroup />
    },
    
    
    {
        path: "caratrate",
        component: <Caratrate />
    },
    
    {
        path: "customer",
        component: <Customer />
    },
    
    {
        path: "advance-payment",
        component: <Advancepayment />
    },
    {
        path: "approval-type",
        component: <Approvaltype />
    },
    {
        path: "approval-rule",
        component: <Approvalrule />
    },
   
    {
        path: "vendor-payment",
        component: <VendorPayments />
    },
    {
        path: "new-firm",
        component: <NewFirm />
    },
    {
        path: "items",
        component: <Item />
    },
    
    {
        path: "manage-loan",
        component: <NewLoan />
    },
    {
        path: "Manage-Delivery",
        component: <LoanDelivery />
    },
    {
        path: "Manage-Interest",
        component: <ManageInterest />
    },
    {
        path: "Customer-Reports",
        component: <CustomerReports />
    },
    {
        path: "Transaction-Reports",
        component: <TransactionReports />
    },
    {
        path: "Principle-Reports",
        component: <PrincipleReports />
    },
    {
        path: "Delivery-Reports",
        component: <DeliveryReports />
    },
    {
        path: "Daywiseinterest-Reports",
        component: <DayWiseInterestReports />
    },
    {
        path: "Interest-Reports",
        component: <InterestReports />
    },
    {
        path: "Overdue-Reports",
        component: <OverdueReports />
    },
    {
        path: "LossItems-Reports",
        component: <LoassItemReports />
    },
    {
        path: "Day-Book-Reports",
        component: <DayBookReports />
    },


    {
        path: "Loan-Summary-Reports",
        component: <LoanSummaryReports/>
    },
    {
        path: "Rate-Range",
        component: <RateRange />
    },
    {
        path: "remarks",
        component: <MetaRemarks />
    },
    {
        path: "Principle-Summary-Reports",
        component: <PrincipleSummaryReports />
    },

    {
        path: "Monthly-Reports",
        component: <MonthlyReports />
    },
    {
        path: "Manage-Overdue",
        component: <ManageOverDue />
    },
    {
        path: "Payement-Mode",
        component: <PaymentMode />
    },
    {
        path: "Interestpercentage",
        component: <IntrestPercentage />
    },


   
]    