import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { deliveryEndPoint, loanEndPoint } from "../../../../../service/api";
import { getAxios } from "../../../../../service/apiservice";
import { convertToDate } from "../../../../../utilities/datetime";
import { convertToTime } from "../../../../../utilities/time";

export default function NewLoanPrintAmount() {
  const navigate = useNavigate();
  let componentRef = useRef();


  const [loandeatils, setLoandeatils] = useState({});
  console.log("loandeatils",loandeatils)
  const [emptyRows, setEmptyRows] = useState([1,2,3,4,5,6,7,8,9,10]);
  const [itemsList, setItemsList] = useState([])

  const { id } = useParams();

  useEffect(() => {
    getLoanDeatilsPrint(id);
  }, [id]);

  const getLoanDeatilsPrint = async (id) => {
    var respose = await getAxios({
      url: loanEndPoint + id + "/",
    });

    if (respose !== null) {
      setItemsList(respose.data.iteam_details)
      setLoandeatils(respose.data);
    }
  };


  useEffect(() => {
    createArray(itemsList?.length > 0 ? itemsList?.length : 0)
  },[itemsList])

  function createArray(N) {
    var len = 10 - N;
    setEmptyRows([...Array(len).keys()].map(i => i + 1));
}
  

  return (
    <div className="pb-5">
      <div className="flex justify-end gap-5">
        <button
          onClick={() => {
            navigate("/Loan-Management/Manage-Loan");
          }}
          className="capitalize bg-menu-head-bg border text-black w-[100px] sm:w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "
        >
          Back
        </button>

        <div>
          <ReactToPrint
            trigger={() => (
              <button className="capitalize mb-[50px] bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto">
                Print
              </button>
            )}
            content={() => componentRef}
          />
        </div>
      </div>

      {/* <div>
         
         <ReactToPrint
           trigger={() => (
             <button className="capitalize mb-[50px] bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto">
               Print
             </button>
           )}
           content={() => componentRef}
         />
       </div> */}

      <div ref={(el) => (componentRef = el)}>
        <div class="p-2 bg-gray-100">
          <div class="flex space-x-4 print:space-x-0 gap-4">
            <div className=" flex-1 w-[90%]">
              <div class="text-start text-red-500 font-bold mb-2">
                {/* PAWN TICKET PBL No. */}
              </div>
              <div class="border border-[#EBF1F9] ">
                <div class="flex justify-between p-1">
                  <div class="w-1/4 border border-[#EBF1F9] bg-white h-[150px] "></div>
                </div>

                <div class="border-b border-[#EBF1F9] mb-2 mt-[-60px]"></div>
                <div class="w-2/9 pl-2 flex justify-center">
                  <div class="w-2/5 pl-2 text-left">
                    <div className="flex gap-3 text-[12px]">
                      {/* Date: */}
                      <div className="flex items-center gap-2">
                        <p className="text-[12px]">
                          {convertToDate(loandeatils?.loan_list?.created_at)}
                        </p>
                        {/* <p className="text-[#BCBDBD]">{convertToTime(loandeatils?.loan_list?.created_at)}</p> */}
                      </div>
                    </div>
                    <p className="text-[12px]">
                      {/* Pledge No:  */}
                      {loandeatils?.loan_list?.loanno}
                    </p>
                  </div>
                </div>
                <div class="mb-2 pl-2">
                  <div className="mt-5 text-[12px]">
                    {/* Name & Address: */}
                     {loandeatils?.loan_list?.address}
                  </div>
                  <div class="mt-[40px] text-[12px]">
                    {/* Loan Amount Rs:  */}
                    {loandeatils?.loan_list?.loan_amt}
                  </div>
                  {/* <div>Rs. in words:</div> */}
                </div>
                <div className="h-[280px] w-full border-b border-[#EBF1F9] ">
                  <table className="w-full">
                    <thead className="text-[15px] border border-[#EBF1F9] uppercase font-[700]">
                      <tr>
                        <td className="p-[1px] min-w-[55px] border-r border-[#EBF1F9] px-2 text-[12px] ">
                          {/* S No */}
                        </td>
                        <td className="p-[1px] min-w-[95px] border-r border-[#EBF1F9] px-2 text-[12px]">
                          {/* Particulars of Pledge */}
                        </td>
                        <td className="p-[1px] min-w-[55px] border-r border-[#EBF1F9] px-2 text-[12px] ">
                          {/* Nos */}
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {loandeatils?.iteam_details?.map((item, index) => (
                        <tr>
                          <>
                            <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                              {index + 1}
                            </td>
                            <td className="p-[1px] min-w-[115px] px-2  capitalize text-[14px] border-r border-[#EBF1F9]">
                              {item.metal__metal_name}
                            </td>

                            <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r ">
                              {item.noofpieces}
                            </td>
                          </>
                        </tr>
                      ))}

{
                      emptyRows.map((item, index) => (
                        <tr>
                          <td className="p-[2px] min-w-[20px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            <div className='invisible'>{index + 1 + loandeatils?.iteam_details?.length}</div>
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r ">
                            
                          </td>
                         
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>

                <div class="mt-2 text-center">
                  {/* The above Articles are pawned with us */}
                </div>
                <div className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-3 mt-2 px-4 py-4 border-b border-r border-[#EBF1F9]">
                  <div className="flex gap-4">
                    {/* <p className="text-[12px]">Gross Wt.:</p> */}
                    <p>{loandeatils?.loan_list?.gross_weight}</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px]">Nett. Wt.:</p> */}
                    <p>{loandeatils?.loan_list?.net_weight}</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px]">Value:</p> */}
                    <p>{loandeatils?.loan_list?.value}</p>
                  </div>
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-[25px]">
                  
                  
                </div>
                <div class="mt-2 flex justify-between border-t border-[#EBF1F9] pt-2">
                  <div class="w-1/2 text-center">
                    {/* <p className="text-[14px]">Signature of the</p> */}
                    {/* <p className="text-[14px]">Pawn broker or his Agent</p> */}
                  </div>
                  <div class="w-1/2 text-center">
                    {/* <p className="text-[14px]">Signature or thumb</p> */}
                    {/* <p className="text-[14px]">Impression of the Pawner</p> */}
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex-1 w-[90%]">
              <div class="text-start text-red-500 font-bold mb-2">
                {/* PAWN TICKET PBL No. */}
              </div>
              <div class="border border-[#EBF1F9] ">
                <div class="flex justify-between p-1">
                  <div class="w-1/4 border border-[#EBF1F9] bg-white h-[150px] "></div>
                </div>

                <div class="border-b border-[#EBF1F9] mb-2 mt-[-60px]"></div>
                <div class="w-2/9 pl-2 flex justify-center">
                  <div class="w-2/5 pl-2 text-left">
                    <div className="flex gap-3 text-[12px]">
                      {/* Date: */}
                      <div className="flex items-center gap-2">
                        <p className="text-[12px]">
                          {convertToDate(loandeatils?.loan_list?.created_at)}
                        </p>
                        {/* <p className="text-[#BCBDBD]">{convertToTime(loandeatils?.loan_list?.created_at)}</p> */}
                      </div>
                    </div>
                    <p className="text-[12px]">
                      {/* Pledge No: */}
                       {loandeatils?.loan_list?.loanno}
                    </p>
                  </div>
                </div>
                <div class="mb-2 pl-2">
                  <div className="mt-5 text-[12px]">
                    {/* Name & Address:  */}
                    {loandeatils?.loan_list?.address}
                  </div>
                  <div class="mt-[40px] text-[12px]">
                    {/* Loan Amount Rs:  */}
                    {loandeatils?.loan_list?.loan_amt}
                  </div>
                  {/* <div>Rs. in words:</div> */}
                </div>
                <div className="h-[280px] w-full border-b border-[#EBF1F9] ">
                  <table className="w-full">
                    <thead className="text-[15px] border border-[#EBF1F9] uppercase font-[700]">
                      <tr>
                        <td className="p-[1px] min-w-[55px] border-r border-[#EBF1F9] px-2 text-[12px] ">
                          {/* S No */}
                        </td>
                        <td className="p-[1px] min-w-[95px] border-r border-[#EBF1F9] px-2 text-[12px]">
                          {/* Particulars of Pledge */}
                        </td>
                        <td className="p-[1px] min-w-[55px] border-r border-[#EBF1F9] px-2 text-[12px] ">
                          {/* Nos */}
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {loandeatils?.iteam_details?.map((item, index) => (
                        <tr>
                          <>
                            <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                              {index + 1}
                            </td>
                            <td className="p-[1px] min-w-[115px] px-2  capitalize text-[14px] border-r border-[#EBF1F9]">
                              {item.metal__metal_name}
                            </td>

                            <td className="p-[1px] min-w-[115px] px-2 text-[14px]">
                              {item.noofpieces}
                            </td>
                          </>
                        </tr>
                      ))}

{
                      emptyRows.map((item, index) => (
                        <tr>
                          <td className="p-[2px] min-w-[20px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            <div className='invisible'>{index + 1 + loandeatils?.iteam_details?.length}</div>
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r ">
                            
                          </td>
                         
                        </tr>
                      ))
                    }
                    </tbody>
                  </table>
                </div>

                <div class="mt-2 text-center">
                  {/* The above Articles are pawned with us */}
                </div>
                <div className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-3 mt-2 px-4 py-4 border-b border-t border-[#EBF1F9]">
                <div className="flex gap-4">
                    {/* <p className="text-[12px]">Gross Wt.:</p> */}
                    <p>{loandeatils?.loan_list?.gross_weight}</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px]">Nett. Wt.:</p> */}
                    <p>{loandeatils?.loan_list?.net_weight}</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px]">Value:</p> */}
                    <p>{loandeatils?.loan_list?.value}</p>
                  </div>
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-[25px]">
                  
                  
                </div>
                <div class="mt-2 flex justify-between border-t border-[#EBF1F9] pt-2">
                  {/* <div class="w-1/2 text-center">
                    <p className="text-[14px]">Signature of the</p>
                    <p className="text-[14px]">Pawn broker or his Agent</p>
                  </div> */}
                  {/* <div class="w-1/2 text-center">
                    <p className="text-[14px]">Signature or thumb</p>
                    <p className="text-[14px]">Impression of the Pawner</p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="w-[250px] bg-white">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
