import { useDispatch, useSelector } from "react-redux";
import { setPagination } from "../../../redux/appconfigslice";
import { Switch } from "@headlessui/react";
import { getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { ConfigurationListEndPoint,UpdateConfiguration,printtypeDropdownListEndPoint } from "../../../service/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { parse } from "date-fns/esm";

export default function AppConfiguration() {

    const { pagination } = useSelector((state) => state.appConfig)
    const { isDark } = useSelector((state) => state.themeConfig)
    const [printTypeList, setPrintTypeList] = useState([]);
    const [loan_print, setLoanPrint] = useState(0);
    const [delivery_print, setDeliveryPrint] = useState(0);
    const dispatch = useDispatch();


    
    useEffect(() => {
        getPrintTypeDropdownData();
    },[])
    const getPrintTypeDropdownData = async () => {
        var response = await getAxios({
          url: printtypeDropdownListEndPoint,
        });
    
        if (response !== null) {
            setPrintTypeList(response.data.list);
        }
      };


    useEffect(() => {   
     updateconfiguration(); 
    },[delivery_print,loan_print]);


    const updateconfiguration = async () => {
        var response = await postAxiosWithToken({
            url: UpdateConfiguration,
            body: {
                loan_print: parseInt(loan_print) || 0,
                delivery_print: parseInt(delivery_print) || 0,
            }
        })
        if (response != null) {
            toast.success(response.message)
        }
    }


    const handlePagination = (value) => {
      localStorage.setItem('pagination', JSON.stringify(value))
      dispatch(setPagination(value));
  }


    useEffect(() => {
      const loginDataString = localStorage.getItem("login");
      if (loginDataString) {
        const loginData = JSON.parse(loginDataString);
        setLoanPrint(loginData.loan_print);
        setDeliveryPrint(loginData.delivery_print);
      } else {
        console.log("No login data found in localStorage");
      }
    }, []); 
 

    return (
        <div className="my-[50px] bg-body-secondary-bg rounded-lg p-[25px]">
            <p class="text-[18px] font-[500] text-[menu-head] p-[10px]">Settings</p>
            <div className="grid xl:grid-cols-4 sm:grid-cols-2 gap-5">
            <>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">
                Loan Management Print Type <span className="text-red-500">*</span>
                </p>
                <select
                    placeholder="metal name"
                    className="sm:mb-[5px] bg-white border capitalize border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                    name="loan_print"
                    value={loan_print}
                    onChange={(e) => {setLoanPrint(e.target.value);}}
                 s
                    
                  >
                    <option value={""}>--Select Print Type--</option>
                    {printTypeList.map((item, index) => (
                      <option
                        className="capitalize"
                        key={item.printtype_name}
                        value={item.id_printtype}
                      >
                        {item.printtype_name}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">
                Delivery Management Print Type <span className="text-red-500">*</span>
                </p>
                <select
                    placeholder="metal name"
                    className="sm:mb-[5px] bg-white border capitalize border-gray-300 rounded-lg w-full p-[9px] text-[14px] outline-none"
                    name="delivery_print"
                    value={delivery_print}
                    onChange={(e) => {setDeliveryPrint(e.target.value); }}
                    
                  >
                    <option value={""}>--Select Print Type--</option>
                    {printTypeList.map((item, index) => (
                      <option
                        className="capitalize"
                        key={item.printtype_name}
                        value={item.id_printtype}
                      >
                        {item.printtype_name}
                      </option>
                    ))}
                  </select>
              </div>
              <div className="my-[5px]">
                <p className="mb-[5px] text-[16px]">
                Pagination ON | OFF <span className="text-red-500">*</span>
                </p>
                <Switch
                        checked={Boolean(pagination)}
                        onChange={handlePagination}
                        className={`${Boolean(pagination) ? 'bg-primary-btn' : isDark ? 'bg-primary-btn' : 'bg-table-head '}
                                                        relative inline-flex h-[18px] w-[34px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                    >
                        <span
                            aria-hidden="true"
                            className={`${Boolean(pagination) ? 'translate-x-4' : 'translate-x-0'} ${isDark ? 'bg-secondary' : 'bg-white'}
                                                            pointer-events-none inline-block h-[15px] w-[15px] transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                    </Switch>
              </div>
            </>
          </div>                 

        </div>
    );
}