import LogoImage from "../../assets/images/logo.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { postAxios } from "../../service/apiservice";
import { loginEndPoint } from "../../service/api";
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserRole from "../../pages/settings/userrole";

export default function Login() {

    const [authenticated, setauthenticated] = useState(false)

    const navigate = useNavigate();

    const isDark = useSelector((state) => state.themeConfig.isDark)

    const schema = yup
        .object({
            username: yup.string().required(),
            password: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const onSubmit = (data) => {
        loginUser(data)
    }

    const loginUser = async (body) => {
        let response = await postAxios({
            url: loginEndPoint,
            body: body
        })

        if (response?.status === 200) {
            localStorage.setItem('login', JSON.stringify(response.data))
            localStorage.setItem('authenticated', JSON.stringify(true))
            localStorage.setItem('branch', response.data.branch)
            window.location.reload()
        } else {
            toast.error(response?.message)
        }
    }  


    return (
        <>
            <div className={`${isDark ? "bg-dark-bg" : "bg-app-bg"}  w-[100vw] h-[100vh] flex items-center justify-center`}>
                <div>
                    <div className="md:w-[370px] w-fit mx-auto mb-[10px] max-[766px]:w-[200px]">
                        <img width={"100%"} src={LogoImage} alt="no-logo" />
                    </div>
                    <div className={`${isDark ? "bg-dark-bg" : "bg-white"} p-[40px] border border-gray-300 rounded-lg w-fit h-fit shadow-login-card`}>
                        <div className="mb-[40px]">
                            <p className="font-[600] text-[25px] mb-[10px]">Sign In</p>
                            <p className="font-[300] text-gray-400 text-[16px]">Enter your email & password to login</p>
                        </div>

                        <form className="md:w-[370px]" onSubmit={handleSubmit(onSubmit)}>
                            <div className="my-[5px]">
                                <p className="mb-[10px] text-[15px]">Email / Phone</p>
                                <input
                                    type="text"
                                    placeholder="Email or Phone"
                                    className="mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="username" {...register('username')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.username?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[10px] text-[15px]">Password</p>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    className="mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="password"
                                    {...register('password')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.password?.message}</p>
                            </div>
                            <button
                                type="submit"
                                className={`${isDark ? "bg-secondary" : "bg-primary-btn"} mt-[25px]  text-white w-full rounded-lg py-[10px] font-[500] text-[17px] outline-none border-none`}
                            >Sign In</button>
                        </form>
                    </div>
                </div>

                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

            </div>
        </>
    );
}