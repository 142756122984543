// import { useEffect, useRef, useState } from "react";
// import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
// import { metalRatesEndPoint, metalRatesListEndPoint, purityEndPoint, purityListEndPoint, purityStatusEndPoint } from "../../../service/api";
// import { convertToDate, datetimetodate } from "../../../utilities/datetime";
// import { useKeyPress } from "../../../hooks/useKeyPress";
// import { toast } from "react-hot-toast";
// import { Switch } from "@headlessui/react";
// import Popup from "../../../components/Popup";
// import MetalRatesForm from "./metalrateform";
// import MetalRatesFilters from "./metalratesfilter";
// import { useSelector } from 'react-redux';

// export default function MetalRates() {

//     const isDark = useSelector((state) => state.themeConfig.isDark)

//     const { actions } = useSelector((state) => state.appConfig);

//     const searchRef = useRef();

//     const [todayDate, setTodayDate] = useState(new Date());

//     const [metalRatesList, setMetalRatesList] = useState([]);
//     const [filteredMetalRatesList, setFilteredMetalRatesList] = useState([]);
//     const [currentMetalRates, setCurrentMetalRates] = useState({});
//     const [formMode, setFormMode] = useState('create');
//     const [isDeleteOpen, setIsDeleteOpen] = useState(false);
//     const [isLoading, setIsLoading] = useState(true);

//     const [tableHeader, setTableHeader] = useState([]);
//     const [tableData, setTableData] = useState([]);

//     function closeDeleteModal() {
//         setIsDeleteOpen(false)
//     }

//     useEffect(() => {
//         getMetalRatesListData({})
//         getPurityListData()
//     }, [])

//     const onKeyPress = (event) => {
//         if (event.ctrlKey && event.key === 'f') {
//             event.preventDefault();
//             if (document.getElementById('filteroptions').className === 'my-[25px] block') {
//                 document.getElementById('filteroptions').className = 'my-[25px] hidden'
//             } else {
//                 document.getElementById('filteroptions').className = 'my-[25px] block'
//                 searchRef.current?.focus()
//             }
//         }
//     };

//     useKeyPress(['f'], onKeyPress);

//     // useEffect(() => {
//     //     let data = []
//     //     if (filteredMetalRatesList.length !== 0 && tableHeader.length !== 0) {
//     //         for (var i of filteredMetalRatesList) {
//     //             var dataDict = {}
//     //             for (var j of tableHeader) {
//     //                 if (i.rate[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')] !== undefined){
//     //                     dataDict[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')] = i.rate[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')]
//     //                 } else {
//     //                     dataDict[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')] = "-"
//     //                 }
//     //             }
//     //             dataDict['date'] = i.created_at
//     //             data.push(dataDict)
//     //         }
//     //     }
//     //     setTableData(data)
//     // },[filteredMetalRatesList, tableHeader])

//     const getPurityListData = async () => {
//         var response = await getAxios({
//             url: purityListEndPoint
//         })

//         if (response !== null) {
//             setTableHeader(response.data.list)
//         }
//     }

//     const getMetalRatesListData = async (body) => {
//         var response = await postAxiosWithToken({
//             url: metalRatesListEndPoint,
//             body: body
//         })

//         if (response !== null) {
//             setMetalRatesList(response.data.list)
//             setIsLoading(false)
//         }
//     }

//     const deleteMetalRate = async () => {
//         var response = await deleteAxiosWithToken({
//             url: metalRatesEndPoint + currentMetalRates.id + "/"
//         })

//         if (response !== null) {
//             getMetalRatesListData()
//             closeDeleteModal()
//             toast.success(response.message)
//         }
//     }

//     return (
//         <>
//             <div>
//                 <div className={`${isDark ? 'bg-primary-btn' : 'bg-table-head'} top-[-25px] grid grid-cols-2 gap-5 lg:grid-cols-4 p-[10px] rounded-md`}>
//                     <div className="text-[11px] sm:text-[17px] lg:text-center w-full">Date: {convertToDate(todayDate)}</div>
//                     <div className="text-[11px] sm:text-[17px] lg:text-center w-full">1 Gm Gold 22Kt: 5285.00</div>
//                     <div className="text-[11px] sm:text-[17px] lg:text-center w-full">1 Gm Gold 18Kt: 5285.00</div>
//                     <div className="text-[11px] sm:text-[17px] lg:text-center w-full">1 Gm Silver: 5285.00</div>
//                 </div>
//                 <div>
//                     <MetalRatesForm getData={getMetalRatesListData} formMode={formMode} setFormMode={setFormMode} currentMetalRates={currentMetalRates} setCurrentMetalRates={setCurrentMetalRates} />
//                 </div>
//                 <div id="filteroptions" className='my-[25px] hidden'>
//                     <p className="font-[600] text-[18px] lg:float-right lg:w-[400px] 2xl:w-[25%]">Filters</p>
//                     <MetalRatesFilters searchRef={searchRef} getData={getMetalRatesListData} dataList={metalRatesList} setFilteredData={setFilteredMetalRatesList} />
//                 </div>
//                 <div className="w-full my-[10px] mb-[50px] overflow-x-scroll  border rounded-md mt-5">
//                     <table className="w-full">
//                          <thead className="border bg-table-head text-table-head-font text-[15px] uppercase font-[700]">
//                             <tr>
//                                 <td className="p-[10px]">S.No</td>
//                                 <td className="p-[10px] min-w-[150px]">Date</td>
//                                 <td className="p-[10px] min-w-[150px]">Gold (24kt) Rate</td>
//                                 <td className="p-[10px] min-w-[150px]">Gold (22kt) Rate</td>
//                                 <td className="p-[10px] min-w-[150px]">Gold (18kt) Rate</td>
//                                 <td className="p-[10px] min-w-[150px]">Silver 1 Gram</td>
//                                 <td className="p-[10px] min-w-[150px]">Actions</td>
//                                 {/* {
//                                     tableHeader.map((item) => (
//                                         <td key={item} className="p-[10px] text-table-head-font text-[16px] font-[700] min-w-[150px]">{item.metal__metal_name} {item.purity_name}</td>
//                                     ))
//                                 } */}
//                             </tr>
//                         </thead>
//                         <tbody className="text-primary-grey bg-menu-head-bg">
//                             {
//                                 isLoading ? <tr className="text-center w-full">
//                                     <td className="text-center border" colSpan={7}>Loading...</td>
//                                 </tr> :
//                                     metalRatesList.map((item, index) => (
//                                         <tr className="border" key={item + index}>
//                                             <td className="p-[10px]">{index + 1}</td>
//                                             <td className="p-[10px]">{convertToDate(item.created_at)}</td>
//                                             <td className="p-[10px]">{item.gold_24kt_rate}</td>
//                                             <td className="p-[10px]">{item.gold_22kt_rate}</td>
//                                             <td className="p-[10px]">{item.gold_18kt_rate}</td>
//                                             <td className="p-[10px]">{item.silver_1gm_rate}</td>
//                                             <td className="p-[10px]">
//                                                 <div className="flex gap-3">

//                                                     <div onClick={() => {
//                                                         if (actions.editPermit) {
//                                                             setCurrentMetalRates(item);
//                                                             setFormMode('update');
//                                                         } else {
//                                                             toast.error("You don't have permission for edit")
//                                                         }
//                                                     }} className="cursor-pointer hover:scale-125">
//                                                         <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621Z" /><path d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" /></g></svg>
//                                                     </div>

//                                                     <div onClick={() => {
//                                                         if (actions.deletePermit) {
//                                                             setCurrentMetalRates(item);
//                                                             setIsDeleteOpen(true);
//                                                         } else {
//                                                             toast.error("You don't have permission for delete")
//                                                         }
//                                                     }} className="cursor-pointer hover:scale-125">
//                                                         <svg className="!text-[#94A3B8]" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 256"><path fill="currentColor" d="M216 48h-36V36a28 28 0 0 0-28-28h-48a28 28 0 0 0-28 28v12H40a12 12 0 0 0 0 24h4v136a20 20 0 0 0 20 20h128a20 20 0 0 0 20-20V72h4a12 12 0 0 0 0-24ZM100 36a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v12h-56Zm88 168H68V72h120Zm-72-100v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Zm48 0v64a12 12 0 0 1-24 0v-64a12 12 0 0 1 24 0Z" /></svg>
//                                                     </div>
//                                                 </div>
//                                             </td>
//                                             {/* {
//                                                 tableHeader.map((key) => (
//                                                     <td key={item+key} className="p-[10px]">{item[key.metal__metal_name + "_" + key.purity_name?.split(' ')?.join('_')]}{console.log(item)}</td>
//                                                 ))
//                                             } */}
//                                         </tr>
//                                     ))
//                             }
//                         </tbody>
//                     </table>
//                 </div>
//             </div>
//             <div>
//                 <Popup title={'Delete Metal Rate'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
//                     <div className="mt-[10px]">
//                         <p className="font-400 text-[15px]">Do you want to delete the metal rate?</p>
//                         <div className="mt-[10px] flex gap-3 justify-end items-center">
//                             <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
//                             <button type="submit" onClick={() => { deleteMetalRate() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
//                         </div>
//                     </div>
//                 </Popup>
//             </div>
//         </>
//     );
// }

import { useEffect, useRef, useState } from "react";
import { deleteAxiosWithToken, getAxios, postAxiosWithToken } from "../../../service/apiservice";
import { metalRatesListEndPoint, purityEndPoint, purityListEndPoint, purityStatusEndPoint } from "../../../service/api";
import { convertToDate, datetimetodate } from "../../../utilities/datetime";
import { useKeyPress } from "../../../hooks/useKeyPress";
import { toast } from "react-hot-toast";
import { Switch } from "@headlessui/react";
import Popup from "../../../components/Popup";
import MetalRatesForm from "./metalrateform";
import MetalRatesFilters from "./metalratesfilter";
import { useSelector } from 'react-redux';
import Pagination from "../../../components/Pagination";

export default function MetalRates() {

    const isDark = useSelector((state) => state.themeConfig.isDark)

    const { actions, pagination } = useSelector((state) => state.appConfig);

    const searchRef = useRef();

    const [todayDate, setTodayDate] = useState(new Date());

    const [metalRatesList, setMetalRatesList] = useState([]);
    const [currentMetalRates, setCurrentMetalRates] = useState({});
    const [formMode, setFormMode] = useState('create');
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [tableHeader, setTableHeader] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [totalPages, setTotalPages] = useState(1);

    const [tableFilters, setTableFilters] = useState({
        search: '',
        from_date: null,
        to_date: null,
        active_status: null,
        items_per_page: JSON.parse(localStorage.getItem('pagination')) ? 10 : null,
        page: 1
    });

    function closeDeleteModal() {
        setIsDeleteOpen(false)
    }

    useEffect(() => {
        getMetalRatesListData(tableFilters)
        getPurityListData()
    }, [tableFilters])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault();
            if (document.getElementById('filteroptions').className === 'my-[25px] block') {
                document.getElementById('filteroptions').className = 'my-[25px] hidden'
            } else {
                document.getElementById('filteroptions').className = 'my-[25px] block'
                searchRef.current?.focus()
            }
        }
    };

    useKeyPress(['f'], onKeyPress);

    useEffect(() => {
        let data = []
        if (metalRatesList.length !== 0 && tableHeader.length !== 0) {
            for (var i of metalRatesList) {
                var dataDict = {}
                for (var j of tableHeader) {
                    if (i.rate[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')] !== undefined){
                        dataDict[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')] = i.rate[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')]
                    } else {
                        dataDict[j.metal__metal_name + "_" + j.purity_name?.split(' ')?.join('_')] = "-"
                    }
                }
                dataDict['date'] = i.created_at
                data.push(dataDict)
            }
        }
        setTableData(data)
    },[metalRatesList, tableHeader])

    const getPurityListData = async () => {
        var response = await getAxios({
            url: purityListEndPoint
        })

        if (response !== null) {
            setTableHeader(response.data.list)
        }
    }

    const getMetalRatesListData = async (body) => {
        var response = await postAxiosWithToken({
            url: metalRatesListEndPoint,
            body: body
        })

        if (response !== null) {
            setMetalRatesList(response.data.list)
            setIsLoading(false)
            setTotalPages(response.data.total_pages)
        }
    }

    const onStatusChange = async (id) => {
        var response = await getAxios({
            url: purityStatusEndPoint + id + '/'
        })

        if (response !== null) {
            getMetalRatesListData()
            toast.success(response.message)
        }
    }

    const deleteMetal = async () => {
        var response = await deleteAxiosWithToken({
            url: purityEndPoint + currentMetalRates.id + "/"
        })

        if (response !== null) {
            getMetalRatesListData()
            closeDeleteModal()
            toast.success(response.message)
        }
    }

    const pageHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            page: value,
        }));
    };

    const entriesHandler = (value) => {
        setTableFilters((prevState) => ({
            ...prevState,
            items_per_page: value,
            page: 1,
        }));
    };

    return (
        <>
            <div>
                <div className={`bg-table-head text-table-head-font top-[-25px] grid grid-cols-2 gap-5 lg:grid-cols-4 p-[10px]`}>
                    <div className="text-[13px] sm:text-[17px] lg:text-center w-full">Date: {convertToDate(todayDate)}</div>
                    <div className="text-[13px] sm:text-[17px] lg:text-center w-full">1 Gm Gold 22Kt: 5285.00</div>
                    <div className="text-[13px] sm:text-[17px] lg:text-center w-full">1 Gm Gold 18Kt: 5285.00</div>
                    <div className="text-[13px] sm:text-[17px] lg:text-center w-full">1 Gm Silver: 5285.00</div>
                </div>
                <div>
                    <MetalRatesForm getData={getMetalRatesListData} formMode={formMode} setFormMode={setFormMode} currentMetalRates={currentMetalRates} setCurrentMetalRates={setCurrentMetalRates} />
                </div>
                <div id="filteroptions" className='my-[25px] hidden'>
                    <p className="font-[600] text-[18px] lg:float-right lg:w-[400px] 2xl:w-[25%]">Filters</p>
                    <MetalRatesFilters filters={tableFilters} searchRef={searchRef} getData={getMetalRatesListData} dataList={metalRatesList} setFilters={setTableFilters} />
                </div>
                <div className="w-full my-[10px] mb-[50px] overflow-x-scroll">
                    <table className="w-full">

                        <thead className="border bg-table-head text-table-head-font">
                            <tr>
                                <td className="p-[10px]">S.No</td>
                                <td className="p-[10px]">Date</td>
                                {
                                    tableHeader.map((item) => (
                                        <td key={item} className="p-[10px]">{item.metal__metal_name} {item.purity_name}</td>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading ? <tr className="text-center w-full">
                                    <td className="text-center border" colSpan={7}>Loading...</td>
                                </tr> :
                                    tableData.map((item, index) => (
                                        <tr className="border hover:bg-primary-btn cursor-pointer hover:text-white" key={item + index}>
                                            <td className="p-[10px]">{index + 1}</td>
                                            <td>{convertToDate(item.date)}</td>
                                            {
                                                tableHeader.map((key) => (
                                                    <td key={item+key} className="p-[10px]">{item[key.metal__metal_name + "_" + key.purity_name?.split(' ')?.join('_')]}</td>
                                                ))
                                            }
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
                {pagination === true ?
                    <div >
                        <Pagination
                            totalPages={totalPages}
                            currentPage={tableFilters.page}
                            setCurrentPage={pageHandler}
                            entries={tableFilters.items_per_page}
                            entriesHandler={entriesHandler}
                        />
                    </div> : ""}

            </div>
            <div>
                <Popup title={'Delete Metal Rate'} setIsOpen={setIsDeleteOpen} isOpen={isDeleteOpen} closeModal={closeDeleteModal}>
                    <div className="mt-[10px]">
                        <p className="font-400 text-[15px]">Do you want to delete the metal rate?</p>
                        <div className="mt-[10px] flex gap-3 justify-end items-center">
                            <button onClick={() => closeDeleteModal()} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-primary-btn">Close</button>
                            <button type="submit" onClick={() => { deleteMetal() }} className="border-none outline-none px-[10px] py-[5px] w-fit rounded-lg text-white bg-red-600">Delete</button>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    );
}