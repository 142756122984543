import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { deliveryEndPoint } from "../../../../../service/api";
import { getAxios } from "../../../../../service/apiservice";
import { convertToDate } from "../../../../../utilities/datetime";
import { convertToTime } from "../../../../../utilities/time";
import numWords from "num-words";

export default function CartPrint() {

  const navigate = useNavigate();
  let componentRef = useRef();

  const [loandeatils, setLoandeatils] = useState({});
  const [emptyRows, setEmptyRows] = useState([1,2,3,4,5,6,7,8,9,10]);
  const [itemsList, setItemsList] = useState([])


  const receivedAmount = loandeatils?.delivery_list?.loan_amt;
  const amountInWords = receivedAmount ? numWords(receivedAmount) : '';


  const { id } = useParams();

  useEffect(() => {
    getLoanDeatilsPrint(id);
  }, [id]);

  const getLoanDeatilsPrint = async (id) => {
    var respose = await getAxios({
      url: deliveryEndPoint + id + "/",
    });

    if (respose !== null) {
      setItemsList(respose.data.iteam_details)
      setLoandeatils(respose.data);
    }
  };


  useEffect(() => {
    createArray(itemsList?.length > 0 ? itemsList?.length : 0)
  },[itemsList])

  function createArray(N) {
    var len = 10 - N;
    setEmptyRows([...Array(len).keys()].map(i => i + 1));
}
  

  return (
    <div className="pb-5">


      <div className="flex justify-end gap-5">
        <button
          onClick={() => {
            navigate("/Loan-Management/Manage-Delivery");
          }}
          className="capitalize bg-menu-head-bg border text-black w-[100px] sm:w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "

        >
          Back
        </button>

        <div>
         
          <ReactToPrint
            trigger={() => (
              <button className="capitalize mb-[50px] bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto">
                Print
              </button>
            )}
            content={() => componentRef}
          />
        </div>
      </div>

{/* <div>
         
         <ReactToPrint
           trigger={() => (
             <button className="capitalize mb-[50px] bg-primary-btn text-white w-[100px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none max-[480px]:mx-auto">
               Print
             </button>
           )}
           content={() => componentRef}
         />
       </div> */}

    <div ref={(el) => (componentRef = el)}>
      <div className="w-full flex justify-center mt-[130px]">
        <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 w-[90%]" >
          <div className="border-solid border border-white  ">
            <p className="text-white  text-[15px] p-2 text-center">
              {/* இந்த ரசீதை கொண்டு வந்தால் நகை கொடுக்கப்படும்{" "} */}
            </p>

            <p className="text-black font-semibold text-[20px] text-center mt-3">
              {/* வியாபார நேரம்{" "}. */}
            </p>

            <p className="mt-2 text-black text-[15px] text-center">
              {/* காலை 10-00 மணி முதல் இரவு 7-00 மணி வரை{" "} */}
            </p>
            <p className=" mt-2 text-black font-semibold text-[20px] text-center border-b border-solid border-white pb-[40px]">
              {/* பிரதி செவ்வாய் கிழமை விடுமுறை{" "} */}
            </p>

            <div className="flex justify-center mt-[-20px]">
              <p className="bg-white p-2 rounded-full w-[150px] text-center text-white">
              {/* CONDITIONS */}
              </p>
            </div>

            <div className="p-5">
              <p className="mt-4 text-[12px] text-black capitalize">
                {/* 1. the rate of interest on any pledged article shall not axceed
                six teen percent per annum. simple interest that is to say one
                rupee thirty three paise permensum simple interest */}
              </p>
              <p className=" text-[12px] text-black capitalize">
              {/* 2. Every pledge shall be redeemable within a period of one year or such longer period as may be provided in the contract between the parties from the day of pawning (exclusive of that day) and shall continue to be redeemable during seven days of grace following the said period. A pledge shall futher continue to be redeemable until it is disposed of as provided in the Act although the period of redemption and days of grace have expired. */}
              </p>
              <p className="text-[12px] text-black capitalize">
              {/* 3. A pawn broker may in addition the cost of revenue stamps de mand and take from the pawner sum not exceeding one rupee for any loan, for incidental expense connected with the advance of such loan. */}
                </p>
                <p className="text-[12px] text-black capitalize">
                {/* 4. Interest sixteen percent per annum. */}
                </p>

                <p className="mt-4 text-black text-[12px] text-center ">
                  {/* வீடு மாற்றினாலும் ரசீது தவறினாலும் எங்களுக்கு உடனே தெரிவிக்கவும். தெரிவிக்காவிட்டால் நாங்கள் பொறுப்பு அல்ல. */}
                  </p>

              <div className="mt-[50px] border-b border-solid border-white pb-[40px]">

                            <p className=" text-black text-[12px] text-end ">
                              {/* Signature of Pawner */}
                              </p>
              </div>
            </div>
          </div>

          <div className="border-solid border border-white  ">

            <div className="flex justify-center mt-2"> 
              <p className=" p-2 rounded-full w-[150px] text-center text-white">
                {/* DELIVERY MEMO */}
                </p>
            </div>


            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 mt-2 px-4 py-4">
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* R No: */}

              </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.loanno}</p>
              </div>
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Loan Amount Rs: */}
                </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.loan_amt}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Date: */}
                </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.received_date}</p>
              </div>
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Interest Rs: */}

              </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.interest_amount}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-4">
              <div className="flex gap-4">
              <p></p>
              <p></p>
              </div>
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Total Amount Rs: */}
                </p>
              <p className="text-[12px]">{parseFloat(loandeatils?.loan_list?.net_amount) + parseFloat(loandeatils?.delivery_list?.interest_amount)}</p>
              </div>
            </div>



            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-4 mt-[50px] border-b border-solid border-white pb-[30px] ">
              <div className="text-center mt-5" >
              <p className="text-[12px]">
                {/* Signature of the <br/> Pawn broker or his Agent */}
                </p>
              </div>
              <div className="text-center ">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M428.8 137.6h-86.177a115.52 115.52 0 0 0 2.176-22.4c0-47.914-35.072-83.2-92-83.2c-45.314 0-57.002 48.537-75.707 78.784c-7.735 12.413-16.994 23.317-25.851 33.253l-.131.146l-.129.148C135.662 161.807 127.764 168 120.8 168h-2.679c-5.747-4.952-13.536-8-22.12-8H32c-17.673 0-32 12.894-32 28.8v230.4C0 435.106 14.327 448 32 448h64c8.584 0 16.373-3.048 22.12-8h2.679c28.688 0 67.137 40 127.2 40h21.299c62.542 0 98.8-38.658 99.94-91.145c12.482-17.813 18.491-40.785 15.985-62.791A93.148 93.148 0 0 0 393.152 304H428.8c45.435 0 83.2-37.584 83.2-83.2c0-45.099-38.101-83.2-83.2-83.2m0 118.4h-91.026c12.837 14.669 14.415 42.825-4.95 61.05c11.227 19.646 1.687 45.624-12.925 53.625c6.524 39.128-10.076 61.325-50.6 61.325H248c-45.491 0-77.21-35.913-120-39.676V215.571c25.239-2.964 42.966-21.222 59.075-39.596c11.275-12.65 21.725-25.3 30.799-39.875C232.355 112.712 244.006 80 252.8 80c23.375 0 44 8.8 44 35.2c0 35.2-26.4 53.075-26.4 70.4h158.4c18.425 0 35.2 16.5 35.2 35.2c0 18.975-16.225 35.2-35.2 35.2M88 384c0 13.255-10.745 24-24 24s-24-10.745-24-24s10.745-24 24-24s24 10.745 24 24"/></svg> */}
              <p className="text-[12px]">
                {/* Signature or thumb <br/>  Impressior of the Pawner */}
                </p>
              </div>
            </div>



            <div className="mt-3 text-[15px] text-center p-3 font-semibold">
              <p>
                {/* அடகு வைத்த ரசீது தவறிவிட்டது. V.A.O. சான்று சமர்ப்பித்து நகையை மீட்டுச் செல்கிறேன். தவறிய ரசீது கிடைத்ததும், தங்களிடம் ஒப்படைக்கின்றேன். */}
                </p>
            </div>

            <div className="flex justify-center mt-2"> 
              <p className=" p-2 rounded-full w-[150px] text-center text-white">
                {/* DELIVERY MEMO */}
                </p>
            </div>

            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 mt-2 px-4 py-4">
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* R No: */}

              </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.loanno}</p>
              </div>
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Loan Amount Rs: */}
                </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.distribute_amt}</p>

              </div>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Date: */}
                </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.received_date}</p>

              </div>
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Interest Rs: */}
                </p>
              <p className="text-[12px]">{loandeatils?.delivery_list?.interest_amount}</p>
              </div>
            </div>
            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-4">
              <div className="flex gap-4">
              <p></p>
              <p></p>
              </div>
              <div className="flex gap-4">
              <p className="text-[12px] font-semibold">
                {/* Total Amount Rs: */}
                </p>
              <p className="text-[12px]">{parseFloat(loandeatils?.loan_list?.net_amount) + parseFloat(loandeatils?.delivery_list?.interest_amount)}</p>

              </div>
            </div>



            <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-4 mt-[50px] ">
              <div className="text-center mt-5" >
              <p className="text-[12px]">
                
                {/* Signature of the <br/> Pawn broker or his Agent */}
                </p>
              </div>
              <div className="text-center">
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M428.8 137.6h-86.177a115.52 115.52 0 0 0 2.176-22.4c0-47.914-35.072-83.2-92-83.2c-45.314 0-57.002 48.537-75.707 78.784c-7.735 12.413-16.994 23.317-25.851 33.253l-.131.146l-.129.148C135.662 161.807 127.764 168 120.8 168h-2.679c-5.747-4.952-13.536-8-22.12-8H32c-17.673 0-32 12.894-32 28.8v230.4C0 435.106 14.327 448 32 448h64c8.584 0 16.373-3.048 22.12-8h2.679c28.688 0 67.137 40 127.2 40h21.299c62.542 0 98.8-38.658 99.94-91.145c12.482-17.813 18.491-40.785 15.985-62.791A93.148 93.148 0 0 0 393.152 304H428.8c45.435 0 83.2-37.584 83.2-83.2c0-45.099-38.101-83.2-83.2-83.2m0 118.4h-91.026c12.837 14.669 14.415 42.825-4.95 61.05c11.227 19.646 1.687 45.624-12.925 53.625c6.524 39.128-10.076 61.325-50.6 61.325H248c-45.491 0-77.21-35.913-120-39.676V215.571c25.239-2.964 42.966-21.222 59.075-39.596c11.275-12.65 21.725-25.3 30.799-39.875C232.355 112.712 244.006 80 252.8 80c23.375 0 44 8.8 44 35.2c0 35.2-26.4 53.075-26.4 70.4h158.4c18.425 0 35.2 16.5 35.2 35.2c0 18.975-16.225 35.2-35.2 35.2M88 384c0 13.255-10.745 24-24 24s-24-10.745-24-24s10.745-24 24-24s24 10.745 24 24"/></svg> */}
              <p className="text-[12px]">
                {/* Signature or thumb <br/>  Impressior of the Pawner */}
                </p>
              </div>
            </div>


          </div>
        </div>

      </div>








      <div className="w-full flex justify-center mt-[350px]">
        <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 w-[90%]">
        <div>
                <p className="text-green-600 text-[15px] p-2 text-left">
                  PAWN TICKET PBL NO
                </p>
              <div className="border-solid border border-green-600">
              <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-[45px] border-green-600 border-b bg-green-200">
                  
                  
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Pledge No: */}

                  </p>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.loanno}</p>
                  </div>
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Date: */}

                  </p>
                
                 <div className="flex items-center gap-2">
                 <p className="text-[12px]">{convertToDate(loandeatils?.delivery_list?.created_at)}</p>
                 {/* <p className="text-[#BCBDBD]">{convertToTime(loandeatils?.delivery_list?.created_at)}</p> */}
                 </div>
                       
                  
                  </div>
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Name Address: */}

                  </p>
                  <div>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.customer_name}</p>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.address}</p>
                  </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 gap-3 px-4 mt-5 py-2">
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Loan Amount Rs: */}

                  </p>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.distribute_amt}</p>
                  </div>
                  <div className="flex items-center  gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Rs.in words: */}
                    </p>
                  <p className="capitalize text-[12px]">{amountInWords}</p>
                  </div>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 gap-3 px-4 mt-5 py-2">
                  <div className="flex gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* The Following Articles are pawned with us */}
                    </p>
                  <p></p>
                  </div>
                
                </div>


                    <div className="h-[280px] w-full border text-green-700 ">
                      <table className="w-full bg-[#EBF1F9]">
                        <thead className="border border-[#EBF1F9] text-[15px] uppercase font-[700]">
                          <tr>
                            <td className="p-[1px] min-w-[55px] border border-[#EBF1F9] px-2 text-[14px] ">
                              {/* S No */}
                            </td>
                            <td className="p-[1px] min-w-[95px] border border-[#EBF1F9] px-2 text-[14px]">
                              {/* Particulars of Pledge */}
                            </td>
                            <td className="p-[1px] min-w-[55px] border border-[#EBF1F9] px-2 text-[14px] ">
                              {/* Nos */}
                            </td>
                            
                          </tr>
                        </thead>

                        <tbody>
                        {loandeatils?.iteam_details?.map((item, index) => (
                      <tr>
                        <>
                         
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            {index + 1}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2  capitalize text-[14px] border-r border-[#EBF1F9]">
                            {item.metal__metal_name}
                          </td>
                          
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            {item.noofpieces}
                          </td>
                          
                        </>
                      </tr>
                    ))}

{
                      emptyRows.map((item, index) => (
                        <tr>
                          <td className="p-[2px] min-w-[20px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            <div className='invisible'>{index + 1 + loandeatils?.iteam_details?.length}</div>
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            
                            </td>
                         
                        </tr>
                      ))
                    }
                      

                        </tbody>
                      </table>

                    </div>

                <div className="grid grid-cols-3 xl:grid-cols-3 border border-[#EBF1F9] lg:grid-cols-3 gap-3 px-4 py-2">
                  <div className="flex gap-4">
                    {/* <p className="text-[12px] text-green-600">Gross Wt :</p> */}
                    <p className="text-[12px] text-green-600">{loandeatils?.delivery_list?.gross_weight}</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px] text-green-600">Nett Wt :</p> */}
                    <p className="text-[12px] text-green-600">{loandeatils?.delivery_list?.net_weight}</p>

                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px] text-green-600">Value :</p> */}
                    <p className="text-[12px] text-green-600">{loandeatils?.delivery_list?.value}</p>

                  </div>
                </div>
                <div className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-3 px-4 py-5">
                
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
                  <div className="flex gap-4 justify-center">
                    {/* <p className="text-[12px] text-center text-green-600">Signature of the <br/>Pawn broker or his Agent </p> */}
                    <p></p>
                  </div>
                  <div className="flex gap-4 justify-center">
                    {/* <p className="text-[12px] text-center text-green-600">Signature or thumb impression <br/>of the pawner </p> */}
                    <p></p>
                  </div>
                  
                </div>


              
              </div>
            </div>



            <div>
                <p className="text-green-600 text-[15px] p-2 text-left ">
                  PAWN TICKET PBL NO
                </p>
              <div className="border-solid border border-green-600">
              <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-[45px] border-green-600 border-b bg-green-200">
                  
                  
                </div>
                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Pledge No: */}
                  </p>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.loanno}</p>
                  </div>
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Date: */}
                    </p>
                
                 <div className="flex items-center gap-2">
                 <p className="text-[12px]">{convertToDate(loandeatils?.delivery_list?.created_at)}</p>
                 {/* <p className="text-[#BCBDBD]">{convertToTime(loandeatils?.delivery_list?.created_at)}</p> */}
                 </div>
                       
                  
                  </div>
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Name Address: */}
                    </p>
                  <div>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.customer_name}</p>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.address}</p>
                  </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 gap-3 px-4 mt-5 py-2">
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Loan Amount Rs: */}

                  </p>
                  <p className="text-[12px]">{loandeatils?.delivery_list?.distribute_amt}</p>
                  </div>
                  <div className="flex items-center gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* Rs.in words: */}
                    </p>
                  <p className="capitalize text-[12px]">{amountInWords}</p>
                  </div>
                </div>

                <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 gap-3 px-4 mt-5 py-2">
                  <div className="flex gap-4">
                  <p className="text-[12px] text-green-600">
                    {/* The Following Articles are pawned with us */}
                    </p>
                  <p></p>
                  </div>
                
                </div>


                    <div className="h-[280px] w-full border ">
                      <table className="w-full bg-[#EBF1F9] text-green-700">
                        <thead className="border border-green-600 text-[15px] uppercase font-[700]">
                          <tr>
                            <td className="p-[1px] min-w-[55px] border border-[#EBF1F9] px-2 text-[14px] ">
                              {/* S No */}
                            </td>
                            <td className="p-[1px] min-w-[95px] border border-[#EBF1F9] px-2 text-[14px]">
                              {/* Particulars of Pledge */}
                            </td>
                            <td className="p-[1px] min-w-[55px] border border-[#EBF1F9] px-2 text-[14px] ">
                              {/* Nos */}
                            </td>
                            
                          </tr>
                        </thead>

                        <tbody>
                        {loandeatils?.iteam_details?.map((item, index) => (
                      <tr>
                        <>

                          {console.log("item", item)}
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            {index + 1}
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2  capitalize text-[14px] border-r border-[#EBF1F9]">
                            {item.metal__metal_name}
                          </td>
                          
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            {item.noofpieces}
                          </td>
                          
                        </>
                      </tr>
                    ))}

{
                      emptyRows.map((item, index) => (
                        <tr>
                          <td className="p-[2px] min-w-[20px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            <div className='invisible'>{index + 1 + loandeatils?.iteam_details?.length}</div>
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            
                          </td>
                          <td className="p-[1px] min-w-[115px] px-2 text-[14px] border-r border-[#EBF1F9]">
                            
                          </td>
                         
                        </tr>
                      ))
                    }

                    
                      

                        </tbody>
                      </table>

                    </div>

                <div className="grid grid-cols-3 xl:grid-cols-3 border border-[#EBF1F9] lg:grid-cols-3 gap-3 px-4 py-2">
                  <div className="flex gap-4">
                    {/* <p className="text-[12px] text-green-600">Gross Wt :</p> */}
                    <p className="text-[12px] text-green-600">{loandeatils?.delivery_list?.gross_weight}</p>

                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px] text-green-600">Nett Wt :</p> */}
                    <p className="text-[12px] text-green-600">{loandeatils?.delivery_list?.net_weight}</p>
                  </div>
                  <div className="flex gap-4">
                    {/* <p className="text-[12px] text-green-600">Value :</p> */}
                    <p className="text-[12px] text-green-600">{loandeatils?.delivery_list?.value}</p>
                  </div>
                </div>
                <div className="grid grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 gap-3 px-4 py-5">
                
                </div>

                <div className="grid grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 gap-3 px-4 py-2">
                  <div className="flex gap-4 justify-center">
                    {/* <p className="text-[12px] text-center text-green-600">Signature of the <br/>Pawn broker or his Agent </p> */}
                    <p></p>
                  </div>
                  <div className="flex gap-4 justify-center">
                    {/* <p className="text-[12px] text-center text-green-600">Signature or thumb impression <br/>of the pawner </p> */}
                    <p></p>
                  </div>
                  
                </div>


              
              </div>
            </div>
        </div>
      </div>
    </div>
    </div>
  );
}
