export const sessionLogoutTime = 900000;

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const fixedCalcId = 1;
export const weightCalId = 2;
export const pergramCalId = 3;

export const cararWeightType = 2;
export const caratRateType = 2;

export const grossWeightType = 1;
export const netWeightType = 2;

export const tagStockType = 1;
export const nonTagStockType = 2;
export const packetStockType = 3;

export const allBranchTranferType = 1;
export const approveBranchTranferType = 2;
export const receivedBranchTranferType = 3;
export const returnBranchTranferType = 4;
export const reqReceivedBranchTransferType = 4;
export const receivedBranchTransferType = 4;
export const returnRequestType = 6;

export const cashAdvanceType = 2;
export const goldAdvanceType = 1;

export const defaultCountry = JSON.stringify({"name":"India","isoCode":"IN"})
export const defaultState = JSON.stringify({"name":"Tamil Nadu","isoCode":"TN"})


export const approveStatusType = 6;
export const deniedStatusType = 7;

export const goldMetal = 1;
export const silverMetal = 2;

export const orderForShop = 1;
export const orderForCustomer = 2;

export const defaultCustomerType = 1;

export const pendingStatusType = 1;
export const orderIssuedStatusType = 11;
export const orderRecievedStatusType = 5;
export const meltingIssuedStatusType = 12;

export const goldMetalType = 1;

export const cash = 1;
export const upi = 2;
export const card = 3;
export const bank = 4;
export const gift_voucher = 5;
export const scheme = 6;
export const cheque = 7;

export const cashMethod = 1;
export const upiMethod = 2;
export const debitCardMethod = 3;
export const creditCardMethod = 4;
export const bankMethod = 5;
export const giftVoucherMethod = 6;
export const schemeMethod = 7;
export const chequeMethod = 8;

export const bagTransferType = 1;
