import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { branchListEndPoint, departmentListEndPoint, designationListEndPoint, locationListEndPoint, userEndPoint, userRoleListEndPoint } from "../../../service/api";
import { getAxios, postAxiosWithToken, putAxiosWithToken } from "../../../service/apiservice";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from 'country-state-city';
import { useKeyPress } from "../../../hooks/useKeyPress";
import { defaultCountry, defaultState, phoneRegExp } from "../../../constant";
import { toast } from "react-hot-toast";

export default function UserForm() {

    const navigate = useNavigate();

    const [formMode, setFormMode] = useState('create');
    const [currentUser, setCurrentUser] = useState({
        state: defaultState,
        country: defaultCountry,
    });

    const [userRoleDropdown, setUserRoleDropdown] = useState([]);
    const [locationDropdown, setLocationDropdown] = useState([]);
    const [branchDropdown, setBranchDropdown] = useState([]);
    const [departmentDropdown, setDepartmentDropdown] = useState([]);
    const [designationDropdown, setDesignationDropdown] = useState([]);
    const [countryDropdown, setCountryDropdown] = useState([]);
    const [stateDropdown, setStateDropdown] = useState([]);
    const [cityDropdown, setCityDropdown] = useState([]);

    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(JSON.parse(defaultCountry));
    const [selectedState, setSelectedState] = useState(JSON.parse(defaultState));
    const [selectedCity, setSelectedCity] = useState('');

    const [selectedUserRole, setSelectedUserRole] = useState('');

    const [encryptPasswordValue, setEncryptPasswordValue] = useState(true);

    const schema = yup
        .object({
            first_name: yup.string().required(),
            last_name: yup.string().required(),
            email: yup.string().required().email('Email is not valid'),
            phone: yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
            role: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
            country: yup.string().required(),
            password: formMode === 'update' ? yup.string() : yup.string().required().min(8),
            address: yup.string().required(),
            pincode: yup.string().required(),
            aadhar_card: yup.string(),
            pan_card: yup.string(),
            location: yup.string().required(),
            branch: yup.string().required(),
            department: yup.string().required(),
            designation: yup.string().required(),
        })
        .required()

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(schema),
        values: formMode === 'update' ? {
            first_name: currentUser?.first_name,
            last_name: currentUser?.last_name,
            email: currentUser?.email,
            phone: currentUser?.phone,
            role: currentUser?.role,
            city: currentUser?.city,
            state: currentUser?.state,
            country: currentUser?.country,
            password: currentUser?.password,
            address: currentUser?.address,
            pincode: currentUser?.pincode,
            aadhar_card: currentUser?.aadhar_card,
            pan_card: currentUser?.pan_card,
            location: currentUser?.location,
            branch: currentUser?.branch,
            department: currentUser?.department,
            designation: currentUser?.designation,
        } : {
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            role: "",
            city: "",
            state: defaultState,
            country: defaultCountry,
            password: "",
            address: "",
            pincode: "",
            aadhar_card: "",
            pan_card: "",
            location: "",
            branch: "",
            department: "",
            designation: "",
        }
    })

    useEffect(() => {
        getStateDropdown(defaultCountry)
    },[defaultCountry])

    const onKeyPress = (event) => {
        if (event.ctrlKey && event.key === 's') {
            event.preventDefault();
            document.getElementById('formsubmit').click()
            document.getElementById('forminit').blur()
        } else if (event.key === 'Escape') {
            event.preventDefault();
            reset()
            setFormMode('create')
            navigate(-1)
        }
    };


    useKeyPress(['s', 'Escape'], onKeyPress);

    useEffect(() => {
        getUserRoleDropdownData();
        getLocationDropdownData();
        getDepartmentDropdownData();
        getDesignationDropdownData();

        var countryTempList = []
        Country.getAllCountries().map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            countryTempList.push(dictData)
        })

        setCountryDropdown(countryTempList)

        setFormMode(localStorage.getItem('user_form_mode'))

        if (localStorage.getItem('user_form_mode') === 'update') {
            getUserDetails()
        }
    }, [])

    const getUserDetails = async () => {
        var response = await getAxios({
            url: userEndPoint + localStorage.getItem('user_form_id') + "/"
        })

        if (response !== null) {
            var tempDetails = {
                first_name: response.data?.staff_details?.first_name,
                last_name: response.data?.staff_details?.last_name,
                email: response.data?.user_details?.email,
                phone: response.data?.user_details?.phone,
                role: response.data?.user_details?.role,
                city: response.data?.staff_details?.city,
                state: response.data?.staff_details?.state,
                country: response.data?.staff_details?.country,
                address: response.data?.staff_details?.address,
                pincode: response.data?.staff_details?.pincode,
                aadhar_card: response.data?.staff_details?.aadhar_card,
                pan_card: response.data?.staff_details?.pan_card,
                location: response.data?.staff_details?.location,
                branch: response.data?.staff_details?.branch,
                department: response.data?.staff_details?.department,
                designation: response.data?.staff_details?.designation,
            }
            getBranchDropdownData(response.data?.staff_details?.location)
            setSelectedBranch(response.data?.staff_details?.branch)

            setSelectedCountry(JSON.parse(response.data?.staff_details?.country))

            getStateDropdown(response.data?.staff_details?.country)
            setSelectedState(JSON.parse(response.data?.staff_details?.state))

            setSelectedCity(response.data?.staff_details?.city)

            setSelectedUserRole(response.data?.user_details?.role)

            setCurrentUser(tempDetails)
        }
    }

    useEffect(() => {
        getCityDropdown(JSON.stringify(selectedState))
    },[selectedState, defaultState])

    const getUserRoleDropdownData = async () => {
        var response = await getAxios({
            url: userRoleListEndPoint
        })

        if (response !== null) {
            setUserRoleDropdown(response.data.list)
        }
    }

    const getLocationDropdownData = async () => {
        var response = await getAxios({
            url: locationListEndPoint
        })

        if (response !== null) {
            setLocationDropdown(response.data.list)
        }
    }

    const getBranchDropdownData = async (id) => {
        var response = await getAxios({
            url: branchListEndPoint + id + "/"
        })

        if (response !== null) {
            setBranchDropdown(response.data.list)
        }
    }

    const getDepartmentDropdownData = async () => {
        var response = await getAxios({
            url: departmentListEndPoint
        })

        if (response !== null) {
            setDepartmentDropdown(response.data.list)
        }
    }

    const getDesignationDropdownData = async () => {
        var response = await getAxios({
            url: designationListEndPoint
        })

        if (response !== null) {
            setDesignationDropdown(response.data.list)
        }
    }

    const getStateDropdown = (value) => {        
        var stateTempList = []
        State.getStatesOfCountry(JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name,
                isoCode: item.isoCode
            }
            stateTempList.push(dictData)
        })
        setStateDropdown(stateTempList)
    }

    const getCityDropdown = (value) => {
        var cityTempList = []
        City.getCitiesOfState(selectedCountry.isoCode, JSON.parse(value).isoCode).map((item) => {
            var dictData = {
                name: item.name
            }
            cityTempList.push(dictData)
        })
        setCityDropdown(cityTempList)
      }

    const onSubmit = async (data) => {
        if (localStorage.getItem('user_form_mode') === 'create') {
            data['country'] = JSON.stringify({
                "name": JSON.parse(data.country).name,
                "isoCode": JSON.parse(data.country).isoCode
            })
            data['state'] = JSON.stringify({
                "name": JSON.parse(data.state).name,
                "isoCode": JSON.parse(data.state).isoCode
            })

            createUser(data)

        } else {
            data['country'] = JSON.stringify({
                "name": JSON.parse(data.country).name,
                "isoCode": JSON.parse(data.country).isoCode
            })
            data['state'] = JSON.stringify({
                "name": JSON.parse(data.state).name,
                "isoCode": JSON.parse(data.state).isoCode
            })
            
            delete data.password;

            updateUser(data)
        }
    }

    const createUser = async (body) => {
        var response = await postAxiosWithToken({
            url: userEndPoint,
            body: body
        })

        if (response != null) {
            clearData()
            toast.success(response.message)
            navigate('/organization/user')
        }
    }

    const updateUser = async (body) => {
        var response = await putAxiosWithToken({
            url: userEndPoint + localStorage.getItem('user_form_id') + "/",
            body: body
        })

        if (response != null) {
            clearData()
            localStorage.removeItem('user_form_id')
            localStorage.setItem('user_form_mode', 'create')
            toast.success(response.message)
            navigate('/organization/user')
        }
    }

    const clearData = () => {
        reset()
        setCurrentUser({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            role: "",
            city: "",
            state: "",
            country: "",
            password: "",
            address: "",
            pincode: "",
            aadhar_card: "",
            pan_card: "",
            location: "",
            branch: "",
            department: "",
            designation: "",
        })
    }

    return (
        <>
            <div className="mb-[50px]">
                <p className="font-[600] text-[21px] text-primary-btn mb-4 capitalize">{formMode} User</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="border p-[15px] rounded-xl shadow-lg bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2 uppercase">Basic Details</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">First Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="first name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="first_name"
                                    id="forminit"
                                    {...register('first_name')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.first_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Last Name <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="last name"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="last_name"
                                    {...register('last_name')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.last_name?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Email <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="email"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="email"
                                    {...register('email')}
                                />
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Phone no <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="phone"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="phone"
                                    {...register('phone')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.phone?.message}</p>
                            </div>
                            <div className={`${formMode === 'update' ? 'hidden' : 'block'} my-[5px]`}>
                                <p className="mb-[5px] text-[16px]">Password <span className="text-red-500">*</span></p>
                                <div className="flex border border-gray-300 rounded-lg items-center py-[7px] px-[10px]">
                                    <input
                                        type={ encryptPasswordValue ? "password" : "text"}
                                        placeholder="password"
                                        className="sm:mb-[5px] w-full text-[17px] outline-none"
                                        name="password"
                                        {...register('password')}
                                    />
                                    <div onClick={() => setEncryptPasswordValue(true)} className={`${!encryptPasswordValue ? 'block' : 'hidden'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5Z"/></svg>
                                    </div>
                                    <div onClick={() => setEncryptPasswordValue(false)} className={`${encryptPasswordValue ? 'block' : 'hidden'}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="currentColor"><path d="m10.79 12.912l-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/><path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6l-12-12l.708-.708l12 12l-.708.708z"/></g></svg>
                                    </div>
                                </div>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.password?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">User Role <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="user role"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="role"
                                    {...register('role')}
                                    onChange={(e) => {setSelectedUserRole(e.target.value)}}
                                >
                                    <option value={""}>--select user role--</option>
                                    {
                                        userRoleDropdown.map((option) => (
                                            <option className="capitalize" key={"user_role" + option.role_name} value={option.id}>{option.role_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.role?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Location <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="location"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="location"
                                    {...register('location')}
                                    onChange={(e) => {
                                        if (e.target.value !== "") {
                                            getBranchDropdownData(e.target.value)
                                        } else {
                                            setBranchDropdown([])
                                        }
                                    }}
                                >
                                    <option value={""}>--select location--</option>
                                    {
                                        locationDropdown.map((option) => (
                                            <option className="capitalize" key={"location" + option.location_name} value={option.id}>{option.location_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.location?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Branch <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="branch"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="branch"
                                    {...register('branch')}
                                    value={selectedBranch}
                                    onChange={(e) => setSelectedBranch(e.target.value)}
                                >
                                    <option value={""}>--select branch--</option>
                                    {
                                        branchDropdown.map((option) => (
                                            <option className="capitalize" key={"branch" + option.branch_name} value={option.id}>{option.branch_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.branch?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Department <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="department"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="department"
                                    {...register('department')}
                                >
                                    <option value={""}>--select department--</option>
                                    {
                                        departmentDropdown.map((option) => (
                                            <option className="capitalize" key={"department" + option.department_name} value={option.id}>{option.department_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.department?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Designation <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="designation"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="designation"
                                    {...register('designation')}
                                >
                                    <option value={""}>--select designation--</option>
                                    {
                                        designationDropdown.map((option) => (
                                            <option className="capitalize" key={"designation" + option.designation_name} value={option.id}>{option.designation_name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.designation?.message}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2 uppercase">ADDRESS</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Address <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="address"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="address"
                                    {...register('address')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.address?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">Country <span className="text-red-500">*</span> </p>
                                <select
                                    placeholder="country"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="country"
                                    {...register('country')}
                                    onChange={(e) => {setSelectedCountry(JSON.parse(e.target.value)); getStateDropdown(e.target.value)}}

                                >
                                    <option value={""}>--select country--</option>
                                    {
                                        countryDropdown.map((option) => (
                                            <option className="capitalize" key={"country" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.country?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">State <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="state"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="state"
                                    {...register('state')}
                                    onChange={(e) => {setSelectedState(JSON.parse(e.target.value));getCityDropdown(e.target.value)}}
                                >
                                    <option value={""}>--select state--</option>
                                    {
                                        stateDropdown.map((option) => (
                                            <option className="capitalize" key={"state" + option.name} value={JSON.stringify(option)}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.state?.message}</p>
                            </div>
                            <div className="sm:my-[5px] ">
                                <p className="mb-[5px] text-[16px]">city <span className="text-red-500">*</span></p>
                                <select
                                    placeholder="city"
                                    className="sm:mb-[5px] capitalize bg-white border border-gray-300 rounded-lg w-full p-[11px] text-[17px] outline-none"
                                    name="city"
                                    {...register('city')}
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                >
                                    <option value={""}>--select city--</option>
                                    {
                                        cityDropdown.map((option) => (
                                            <option className="capitalize" key={"city" + option.name} value={option.name}>{option.name}</option>
                                        ))
                                    }
                                </select>
                                <p className="text-red-500 font-[400] text-[13px]">{errors.city?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pincode <span className="text-red-500">*</span></p>
                                <input
                                    type="text"
                                    placeholder="pincode"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pincode"
                                    {...register('pincode')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.pincode?.message}</p>
                            </div>
                        </div>
                    </div>

                    <div className="border p-[15px] rounded-xl shadow-lg my-[25px] bg-menu-head-bg">
                        <p className="font-[600] text-[17px] text-menu-head border-b mb-4 pb-2 uppercase">ATTACHEMENTS</p>
                        <div className="grid 2xl:grid-cols-5 xl:grid-cols-3 sm:grid-cols-2 gap-5">
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Pan no</p>
                                <input
                                    type="text"
                                    placeholder="pan card no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="pan_card"
                                    {...register('pan_card')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.pan_card?.message}</p>
                            </div>
                            <div className="my-[5px]">
                                <p className="mb-[5px] text-[16px]">Aadhar no</p>
                                <input
                                    type="text"
                                    placeholder="aadhar card no"
                                    className="sm:mb-[5px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                                    name="aadhar_card"
                                    {...register('aadhar_card')}
                                />
                                <p className="text-red-500 font-[400] text-[13px]">{errors.aadhar_card?.message}</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex min-[423px]:flex-row flex-col-reverse justify-end gap-5 sticky bottom-[35px] mt-5 bg-[#fff] p-[20px] border rounded-md w-full">
                        <button
                            id="backnav"
                            onClick={(e) => { e.preventDefault(); setFormMode('create'); reset(); navigate(-1) }}
                            className="capitalize bg-menu-head-bg border text-black w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none "
                        >close<span className="lowercase">(esc)</span></button>
                        <button
                            type="submit"
                            id="formsubmit"
                            className="capitalize bg-primary-btn text-white w-full sm:w-[150px] rounded-lg p-[10px] h-fit font-[500] text-[15px] outline-none border-none"
                        >save<span className="lowercase">(ctrl+s)</span></button>
                    </div>
                </form>
            </div>
        </>
    );
}